import React, { useState, useEffect, useContext, useRef, useMemo } from 'react';
import { Source, Layer, CircleLayer, Popup } from 'react-map-gl';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import {
  Map,
  FullscreenControl,
  NavigationControl,
  GeolocateControl,
  Marker
} from 'react-map-gl';
import { PointContext } from "../../../PointContext";
import { RefContext } from '../../../RefContext';
import { Row, Col, Container, Image } from 'react-bootstrap';
import { putViewportIntoStorage } from '../../utils';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import settings from '../../../settings.json';
import MapAddition from  '../shared/MapAddition';
import ReportHeader from '../shared/ReportHeader';
import ReportFooter from '../shared/ReportFooter';
import LineChart from './LineChart';
import 'chartjs-plugin-zoom';
import './fib-report.scss';

const FIBReport = ({ global, setDates, startDate, endDate, dates }: any) => {

  const FullScreenComp = FullScreen as any;
  const fullscreenhandle = useFullScreenHandle() as any;
  
  const ref = useRef<any>();
  const reportBounds = ref.current ? ref.current.getMap().getBounds().toArray().flat() : null;
  const { setCurrentRef } = useContext<any>(RefContext);
  
  const [formattedData, setFormattedData] = useState<any>(null);
  const [features, setFeatures] = useState<any>(null);
  const [raw, setRaw] = useState()
  const [exportData, setExportData] = useState()
  const [popup, setPopup] = useState<any>(null)
  const [LngLat, setLngLat] = useState<any>(null);
  const [point0, setPoint0] = useState(true);
  const [point1, setPoint1] = useState(true);
  const [point2, setPoint2] = useState(true);
  const [point3, setPoint3] = useState(true);
  const [point4, setPoint4] = useState(true);
  const [point5, setPoint5] = useState(true);
  const [point6, setPoint6] = useState(true);

  useEffect(() => { setCurrentRef?.(ref) }, [ref]);
  useEffect(() => global.setUserLogged(true), []);

  useEffect(() => {
      setTimeout(() => {
        if (ref.current) {
            ref.current.flyTo({
              center: [-81.87359039129257, 26.625973473627894],
              essential: true,
              zoom: 13.5,
            });
        }
      },300)
  }, []);

  useEffect(() => {
    if (ref.current) {
      const fetchData = () => {
        const map = ref.current.getMap();
        let layerData: any[] = [];

        const getFeatures = async () => {
          const features = await map.queryRenderedFeatures(undefined, {
            layers: ['MBFIBDT-report-layer']
          });

          setRaw(features.map(({ properties }:any) => properties))

          features.forEach(({ properties }: any) => {
            const formatted = formatData(properties);
            if (!layerData.some(item => item.title === formatted.title)) {
              layerData.push(formatted);
            }
          });

          if (layerData.length > 0) setFormattedData(layerData);
        };

        if (map.loaded()) {
          getFeatures();
        } else {
          map.on('load', getFeatures);
        }
      };

      fetchData();
    }
  }, [ref.current]);

  const processData = (data: any) => {
    const dateEntries = Object.entries(data).filter(([key]) => /\d{1,2}\/\d{1,2}\/\d{4}/.test(key));
    const sortedEntries = dateEntries.sort(([date1], [date2]) => {
      const [day1, month1, year1] = date1.split('/').map(Number);
      const [day2, month2, year2] = date2.split('/').map(Number);
      // @ts-ignore
      return new Date(year1, month1 - 1, day1) - new Date(year2, month2 - 1, day2);
    });

    const processedEntries: any = sortedEntries.map(([date, value]:any) => [date, value === "" ? 0 : parseFloat(value)]);
    const processedData = Object.fromEntries(processedEntries);

    return processedData;
  };

  const formatData = (data: any) => {
    const excludedKeys = ['index', 'lat', 'lng', 'sourceLayer', 'tileSet'];
    const filteredData = Object.keys(data)
      .filter(key => !excludedKeys.includes(key))
      .reduce((obj: any, key: any) => {
        obj[key] = data[key];
        return obj;
      }, {});

    const d = processData(filteredData);

    const sortDates = (dates: any) => {
      return dates.sort((a: any, b: any) => {
        const dateA: any = new Date(a);
        const dateB: any = new Date(b);
        return dateA - dateB;
      });
    };

    let keys = Object.keys(d);
    let orderedKeys: any[] = sortDates(keys);
    let orderedValues: any[] = [];

    orderedKeys.forEach((key:any) => {
      orderedValues.push(d[key]);
    });

    return { title: data['index'], labels: orderedKeys, data: orderedValues };
  };

  useEffect(() => {
    global.setMBFIBDT(false);
    global.setFIBReportMounted(true)
    return () => {
      global.setLoading(false);
      global.setFIBReportMounted(false)
    };
  }, []);

  const mapInstance = ref.current
  
  useMemo(
    () =>
      mapInstance &&
      mapInstance.on("click", (event: any) => {
        const layerObject = mapInstance.queryRenderedFeatures(event.point);
        if (layerObject !== undefined) {
          setFeatures(layerObject?.[0].properties);
        }
      }),
    [mapInstance]
  );

  useEffect(() => {
    if(LngLat && features) {
      const { index } = features
      setPopup(
        <Popup
          longitude={LngLat.lng}
          latitude={LngLat.lat}
          onClose={() => setPopup(null)}
          className={'circledata-popup'}
        >
          <FontAwesomeIcon
            className="close-btn"
            icon={faXmark}
            onClick={() => {
              setPopup(null)
              setFeatures(null)
            }}
          />
         <strong>Name: {index}</strong>
        </Popup>
      );
    }
  },[LngLat, features])

  const CircleDataLayer: CircleLayer = {
    id: 'MBFIBDT-report-layer',
    type: "circle",
    "source-layer": `${"waterkeepers2-4cg4h0"}`,
    paint: {
      "circle-stroke-width": 2,
      "circle-radius": 9,
      "circle-opacity": 1,
      "circle-stroke-opacity": 1,
      "circle-color": [
        "match",
        ["get", "index"],
        "MB - Tidal", `${point0 ? '#FF5733' : 'transparent'}`,
        "MB4 - US41", `${point1 ? '#33FF57' : 'transparent'}`,
        "MB1 - E. Trailer Park", `${point2 ? "#3357FF" : 'transparent'}`,
        "MB2 - C. Trailer Park", `${point3 ? "#FF33A6" : 'transparent'}`,
        "MB3 - W. Trailer Park", `${point4 ? "#33FFF5" : 'transparent'}`,
        "MB - Cortez", `${point5 ? "#FF8C33" : 'transparent'}`,
        "MB - Fowler", `${point6 ? "#B833FF" : 'transparent'}`,
        'transparent'
      ],
      "circle-stroke-color": [
        "match",
        ["get", "index"],
        "MB - Tidal", `${point0 ? 'white' : 'transparent'}`,
        "MB4 - US41", `${point1 ? 'white' : 'transparent'}`,
        "MB1 - E. Trailer Park", `${point2 ? 'white' : 'transparent'}`,
        "MB2 - C. Trailer Park", `${point3 ? 'white' : 'transparent'}`,
        "MB3 - W. Trailer Park", `${point4 ? 'white' : 'transparent'}`,
        "MB - Cortez", `${point5 ? 'white' : 'transparent'}`,
        "MB - Fowler", `${point6 ? 'white' : 'transparent'}`,
        'transparent'
      ],
    }
  };

  const format = ({ labels }: any, data:any) => {
    return data.map((i:any) => {
      const newObject: any = {};
  
      Object.keys(i).forEach(key => {
        if (labels.includes(key)) {
          newObject[key] = i[key];
        }
      });
  
      if (i.index) {
        newObject["Point Name"] = i.index;
      }
    
      return newObject;
    });
  }

  return (
    <FullScreenComp handle={fullscreenhandle}>
      <Container id={"FIBReport"} className='fib-report-container'>
        <ReportHeader
          global={global}
          data={raw}
          reportID={"FIBReport"}
          fullScreenClickHandle={fullscreenhandle.enter}
        />
        <Row>
          <Col className='col-12 top d-flex justify-content-center align-items-center'>
            <Col className="fib-map-container col-6">
              <Map
                mapboxAccessToken={settings.maboxKey}
                mapStyle={global.mapStyle}
                ref={ref}
                onClick={({ lngLat }:any) => setLngLat(lngLat)}
                preserveDrawingBuffer={true}
                onMove={(e) => {
                  putViewportIntoStorage({
                    longitude: e.viewState.longitude,
                    latitude: e.viewState.latitude,
                    zoom: e.viewState.zoom,
                  });
                  global.setViewport({
                    longitude: e.viewState.longitude,
                    latitude: e.viewState.latitude,
                    zoom: e.viewState.zoom,
                  });
                }}
              >
                <Source id={'MBFIBDT-report-layer'} type="vector" url={settings.tileSetURLs.MBFIBDT}>
                  <Layer {...CircleDataLayer} />
                </Source>
                {popup && popup}
                <MapAddition 
                  global={global} 
                  mapRef={ref}
                  position={'low'}
                  zipOff={true}
                  MapSliderAdd={true}
                  MapLegendWidth={280}
                />
              </Map>
            </Col>
          </Col>
        </Row>
        <Row>
          <Col className='col-12 bottom'>
            <Col className={`line-chart`}>
              {formattedData && (
                <LineChart
                  data={formattedData}
                  title={'Manuels Branch FIB Trends'} 
                  point0={point0}
                  setPoint0={setPoint0}
                  point1={point1}
                  setPoint1={setPoint1}
                  point2={point2}
                  setPoint2={setPoint2}
                  point3={point3}
                  setPoint3={setPoint3}
                  point4={point4}
                  setPoint4={setPoint4}
                  point5={point5}
                  setPoint5={setPoint5}
                  point6={point6}
                  setPoint6={setPoint6}
                  dates={dates}
                  setDates={setDates} 
                  startDate={startDate}
                  endDate={endDate}
                  setExportData={setExportData}
                />
              )}
            </Col>
          </Col>
        </Row>
        <ReportFooter />
      </Container>
 
    </FullScreenComp>
  );
}

export default FIBReport;
