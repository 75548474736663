import React, { useState } from "react";
import Visible from "../components/visible";
import "./sidebar-right.scss";
import {
  Button,
  Dropdown,
  Accordion,
  Card,
  Form,
  Tabs,
  Tab,
} from "react-bootstrap";
import gout from "../assets/gout.svg";
import wastewaterIcon from "../assets/scores/wastewater-icon.png";
import industry from "../assets/scores/industry.png";
import TwitterLogo from "../assets/twitter_logo.png";
import trueQ from "../assets/trueQ.svg";
import plusIcon from "../assets/plus-icon.svg";
import h303DIcon from "../assets/scores/h303d.png";
import swIcon from "../assets/scores/swIcon.png";
import stwIcon from "../assets/scores/sw-icon.png";
import agwIcon from "../assets/scores/agwIcon.png";
import RightHeader from "../components/RightHeader";
import LayerTransparency from "./LayerTransparency/LayerTransparency";
import { DragDropContext } from 'react-beautiful-dnd';
export default function SideNavBarRight(props) {
  const { global, location } = props;

  const [twitterToggle, setTwitterToggle] = useState(false);
  const [waterQualityToggle, setWaterQualityToggle] = useState(false);
  const [wastewaterToggle, setWastewaterToggle] = useState(false);
  const [industryToggle, setIndustryToggle] = useState(false);
  const [stormWater, setStormWater] = useState(false);
  const [agWater, setAgWater] = useState(false);
  const [sw, setSW] = useState(false);
  const [h303DToggle, setH303DToggle] = useState(false);

  const params = new URLSearchParams(window.location.search)
  let defaultActive = 'true elements scores and network';
  if(params.has('usrt')){
     if(params.get('usrt')==1){
       defaultActive = 'layer opacity';
     }  
  }
  

  const handleTwitterChange = () => {
    setTwitterToggle(!twitterToggle);
    global.setTwitterSelected(!global.twitterSelected);
  };

  const handleWaterQualityChange = () => {
    setWaterQualityToggle(!waterQualityToggle);
    global.setDrinkingWaterQualitySelected(
      !global.drinkingWaterQualitySelected
    );
  };

  const handleWastewaterChange = () => {
    setWastewaterToggle(!wastewaterToggle);
    global.setWastewaterSelected(!global.wastewaterSelected);
  };

  const handleIndustryChange = () => {
    setIndustryToggle(!industryToggle);
    global.setIndustrySelected(!global.industrySelected);
  };

  const handleSWChange = () => {
    setSW(!sw);
    global.setSurfacewaterTrueQI(!global.surfacewaterTrueQI);
  };

  const handleStormWaterChange = () => {
    setStormWater(!stormWater);
    global.setStormWaterTrueQI(!global.stormWaterTrueQI);
  };

  const handleAGWaterChange = () => {
    setAgWater(!agWater);
    global.setAgWaterTrueQI(!global.agWaterTrueQI);
  };

  const handleH303DChange = () => {
    setH303DToggle(!h303DToggle);
    global.setH303DTrueQI(!global.h303DTrueQI);
  };


  return (
    <Visible global={global} condition={global.MenuRightOpen}>
      <div className="sidebar-right">
        {location && location !== "/" && (
          <RightHeader global={global} button={true} />
        )}
        <hr />
        <Tabs
          defaultActiveKey={defaultActive}
          id="rightSidebarTabs"
          className="mb-3"
          style={{maxHeight: 'calc(100vh - 270px)'}}
        >
          <Tab
            className="d-flex flex-column"
            eventKey="true elements scores and network"
            title="True Elements Scores & Network"
            style={{maxHeight: 'calc(100vh - 270px)'}}
          >
            <div className="sidebar-right-header">
              <h5>True Elements Scores {"&"} Network</h5>
            </div>

            <div
              id="tableScores"
              className="table-container"
              style={{ marginTop: 20 }}
            >
              <table>
                <tbody>
                  <tr>
                    <td className="sidebar-right-button">
                      <Button className="thumbnail">
                        <img
                          id="sidebar-image-icon-drinkingWaterQuality"
                          src={gout}
                          onClick={() => handleWaterQualityChange()}
                        />
                      </Button>
                    </td>
                    <td className="description">
                      <div className="description">
                        Drinking Water Quality
                        <br />
                        <img src={trueQ} />
                        Scores
                      </div>
                    </td>
                    <td className="check">
                      <Form.Check
                        type="switch"
                        checked={global.drinkingWaterQualitySelected}
                        onChange={() => handleWaterQualityChange()}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Button className="thumbnail">
                        <img src={swIcon} onClick={() => handleSWChange()} />
                      </Button>
                    </td>
                    <td className="description">
                      <div className="description">
                        Surface Water
                        <br />
                        <img src={trueQ} />
                        Scores
                      </div>
                    </td>
                    <td className="check">
                      <Form.Check
                        type="switch"
                        checked={global.surfacewaterTrueQI}
                        onChange={() => handleSWChange()}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Button className="thumbnail">
                        <img
                          src={wastewaterIcon}
                          onClick={() => handleWastewaterChange()}
                          style={{ marginTop: 2 }}
                        />
                      </Button>
                    </td>
                    <td className="description">
                      <div className="description">
                        Wastewater
                        <br />
                        <img src={trueQ} />
                        Scores
                      </div>
                    </td>
                    <td className="check">
                      <Form.Check
                        checked={global.wastewaterSelected}
                        onChange={() => handleWastewaterChange()}
                        type="switch"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Button className="thumbnail">
                        <img
                          src={industry}
                          onClick={() => handleIndustryChange()}
                        />
                      </Button>
                    </td>
                    <td className="description">
                      <div className="description">
                        Industry
                        <br />
                        <img src={trueQ} />
                        Scores
                      </div>
                    </td>
                    <td className="check">
                      <Form.Check
                        checked={global.industrySelected}
                        onChange={() => handleIndustryChange()}
                        type="switch"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Button className="thumbnail">
                        <img
                          id="sidebar-image-icon-agWaterTrueQI"
                          onClick={() => handleAGWaterChange()}
                          className="image-icon"
                          src={agwIcon}
                        />
                      </Button>
                    </td>
                    <td className="description">
                      <div className="description">
                        Agriculture Water
                        <br />
                        <img src={trueQ} />
                        Scores
                      </div>
                    </td>
                    <td className="check">
                      <Form.Check
                        checked={global.agWaterTrueQI}
                        onChange={() => handleAGWaterChange()}
                        type="switch"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Button className="thumbnail">
                        <img
                          onClick={() => handleStormWaterChange()}
                          className="image-icon"
                          src={stwIcon}
                        />
                      </Button>
                    </td>
                    <td className="description">
                      <div className="description">
                        Storm Water
                        <br />
                        <img src={trueQ} />
                        Scores
                      </div>
                    </td>
                    <td className="check">
                      <Form.Check
                        checked={global.stormWaterTrueQI}
                        onChange={() => handleStormWaterChange()}
                        type="switch"
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <Button className="thumbnail">
                        <img
                          onClick={() => handleH303DChange()}
                          className="image-icon"
                          id="sidebar-image-icon-h303D"
                          src={h303DIcon} //#228c51
                        />
                      </Button>
                    </td>
                    <td className="description">
                      <div className="description">
                        303(d) Watershed Health
                        <br />
                        <img src={trueQ} />
                        Scores
                      </div>
                    </td>
                    <td className="check">
                      <Form.Check
                        checked={global.h303DTrueQI}
                        onChange={() => handleH303DChange()}
                        type="switch"
                      />
                    </td>
                  </tr>

                 <tr>
                  <td>
                    <Button className='thumbnail'>
                      <img src={TwitterLogo} onClick={() => handleTwitterChange()} />
                    </Button>
                  </td>
                  <td className='description'>
                    <div className='description'>
                      X Live Water Quality
                      <br />
                      Social Network Map
                    </div>
                  </td>
                  <td className='check'>
                    <Form.Check
                      type='switch'
                      checked={global.twitterSelected}
                      onChange={() => handleTwitterChange()}
                    />
                  </td>
                </tr> 
                  <tr>
                    <td>
                      <Button className="new-thumbnail">
                        <img src={plusIcon} />
                      </Button>
                    </td>
                    <td className="description">
                      <div className="description">Add</div>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Tab>
          <Tab
            className="d-flex flex-column"
            eventKey="layer opacity"
            title="Layer Opacity"
          >
            <LayerTransparency global={global} />
          </Tab>
        </Tabs>
      </div>
    </Visible>
  );
}
