import React, { useEffect, useState } from "react";
import { Layer, Source, Popup, CircleLayer } from "react-map-gl";
import { Feature, GeoJsonProperties, Point, FeatureCollection } from 'geojson';
import { fitBoundsMapData } from '../../../features/reports/shared/report-data';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/pro-solid-svg-icons'
import './apple-layer.scss'

const AppleLocs = ({ global, mapRef, data, id }: any) => {
    const [dataArray, setDataArray] = useState<any>();
    const [featureData, setFeatureData] = useState<FeatureCollection>();
    const [features, setFeatures] = useState<any>();
    const [popup, setPopup] = useState<any>();

    const tabActive = 'Overview';

    const newFeatureCollection = (fData: any) => {
        const features: Array<Feature<Point, GeoJsonProperties>> = [];
        for (let dataItem of fData) {
            if (dataItem) {
                let huc12Item = dataItem;
                if (huc12Item) {
                    const featureWithPoint: Feature<Point> = {
                        type: 'Feature',
                        geometry: {
                            type: 'Point',
                            coordinates: [+huc12Item.Longitude, +huc12Item.Latitude],
                        },
                        properties: dataItem,
                    };
                    if (featureWithPoint && featureWithPoint.properties) {
                        featureWithPoint.properties.lat = huc12Item.Latitude;
                        featureWithPoint.properties.lng = huc12Item.Longitude;
                    }
                    features.push(featureWithPoint);
                }
            }
        }
        const featureCollectionFromReportData: FeatureCollection = {
            type: 'FeatureCollection',
            features: features,
        };
        return featureCollectionFromReportData;
    };

    const handleFeatureData = (arr: any, setFcn: any) => {
        const newFeatureSet: any = newFeatureCollection([...arr]);
        setFcn(newFeatureSet);

        if (newFeatureSet.features.length > 0) {
            if (mapRef && mapRef.current) {
                const mapRef_ = mapRef;
                setTimeout(() => {
                    fitBoundsMapData(newFeatureSet, mapRef_);
                }, 400);
            }
        }
    }
  
    useEffect(() => {
        if (dataArray) handleFeatureData(dataArray, setFeatureData);
    }, [dataArray]);

    const handleClose = () => setPopup(undefined);

    const createPopup = (input: any) => {
        const { City, Country, Location_Address, Location_Name, State, Year_Built, Zip_Code, lat, lng }: any = input
        setPopup(
            <Popup
                longitude={parseFloat(lng)}
                latitude={parseFloat(lat)}
                style={{ width: '350px' }}
                anchor="bottom"
                onClose={handleClose}
            >
                <FontAwesomeIcon style={{ position: 'absolute', right: "3%", top: "4%", cursor: 'pointer' }} onClick={handleClose} icon={faXmark} />
                <section className="top">
                    <div>
                      <p>City: <strong>{City}, {Location_Name}</strong></p>
                      <p>State: <strong>{State}</strong></p>
                      <p>Address: <strong>{Location_Address}</strong></p>
                      <p>Zip Code: <strong>{Zip_Code}</strong></p>
                      <p>Country: <strong>{Country}</strong></p>
                      <p>Year Built: <strong>{Year_Built}</strong></p>
                      <p>Future Year of Interest: {input['Future Year of Interest']}</p>
                    </div> 
                </section>     
            </Popup>
        )
    };

    useEffect(() => {
     if(features) createPopup(features)
    }, [features])

    useEffect(() => {
        if (data) setDataArray(data);
    }, [data]);

    const mapInstance = mapRef.current;

    useEffect(() => {
        mapInstance && mapInstance.on('click', (event: any) => {
            const { properties }: any = mapInstance.queryRenderedFeatures(event.point)?.[0];
            if (properties && properties) {
                setFeatures(properties);
            }
        });
    }, [mapInstance]);

    const pointColor = features && features[global.customDataLayerValue];

    const layerStylePoint: CircleLayer = {
        id: 'custom-layer' + id,
        type: 'circle' as const,
        paint: {
            'circle-radius': 12,
            'circle-color': pointColor ? pointColor : "#4fc22c",
            'circle-stroke-color': 'white',
            'circle-stroke-width': 2,
            'circle-opacity': 0.7,
        },
    };

    return (
        <>
            {featureData && (
                <Source id={('custom-layer' + id)} type='geojson' data={featureData} key={tabActive}>
                    <Layer {...layerStylePoint} />
                </Source>
            )}
            {popup}
        </>
    )
}

export default AppleLocs;