import React from 'react';
import LayerItem  from '../../layout/Layers/LayerItem'
import hucImg from '../../assets/huc-layer.svg';
import gwNitrateAssessmentImg from '../../assets/gw-nitrate-assessment.svg';
import outflowsImg from '../../assets/outflows-layer.svg';
import nhdImg from '../../assets/nhd-layer.svg';
import zipIcon from '../../assets/zipCodesv2.png';
import top100 from '../../assets/top100.png';
import domesticWells from '../../assets/popWell2.png';
import PrincipleAquifers from '../../assets/PA.png';
import drought from '../../assets/drought.png';
import FEMA from '../../assets/femaZones.png';
import MTCL from '../../assets/MTCL-logo.png';
import MECL from '../../assets/MECL.png';
import EJDEMO from '../../assets/EJDEMO.png';
import EJLOWINC from '../../assets/EJLOWINC.png';
import EJPOC from '../../assets/EJPOC.png';
import EJUNEMP from '../../assets/EJUNEMP.png';
import EJLingIso from '../../assets/EJLingIso.png';
import EJLHS from '../../assets/EJLHS.png';
import EJ64 from '../../assets/EJ64.png';
import EJ5UNDER from '../../assets/EJ5UNDER.png';
import MEXHUC from '../../assets/MEXHUC.png';
import EJUST from '../../assets/EJUST.png';
import EJHWP from '../../assets/EJHWP.png';
import EJRMPIMG from '../../assets/EJRMP.png';
import EJSUP from '../../assets/EJSUP.png';
import EJWWD from '../../assets/EJWWD.png';
import Tier1 from '../../assets/tier1.png';
import Tier2 from '../../assets/tier2.png';
import Tier3 from '../../assets/tier3.png';
import AACoastalFloodRisk from '../../assets/AACoastalFloodRisk.png';
import AARiverine from '../../assets/AARiverine.png';
import AAWaterStress from '../../assets/AAWaterStress.png';
import EPACircles from '../../assets/EPACircles.png';
import EPLayer from '../../assets/EPLayer.png';
import I10 from '../../assets/I10huc12s.png';
import resFEMAIMG from '../../assets/resFEMAIMG.png';
import PHBasins from '../../assets/PHBasins.png';
import PHRivers from '../../assets/PHRivers2.png';
import NOAASLR from '../../assets/NOAASLR.png';
import WDPAIcon from '../../assets/WDPA.png';
import BIAIcon from '../../assets/BIAIcon.png';
import AUSPH from '../../assets/AUSPH.png';
import ANGISLowerIcon from '../../assets/ANGISLower.png';
import ANGISMiddleIcon from '../../assets/ANGISMid.png';
import ANGISUpperIcon from '../../assets/ANGISUpper.png';
import GBIF_Icon from '../../assets/GBIF_Icon.png';
import ABWDIcon from '../../assets/ABWDIcon.png';
import PHRegions from '../../assets/philRegion.png';
import PHFH from '../../assets/PHFH.png';
import gemstats from '../../assets/gemstats.png';
import phgemstat from '../../assets/phgemstat.png';
import Ausgemstat from '../../assets/Ausgemstat.png';
import wrfc from '../../assets/wrfc.png';
import eddiIcon from '../../assets/layer/layer_eddi.png';
import NOAAMixIcon from '../../assets/layer/layer_NOAAMix.png';
import WQPIcon from '../../assets/layer/layer_WQP.png';
import yspc from '../../assets/yspc.png';
import { getLastUpdatedDate } from '../../features/utils';
import MRK from '../../assets/mrk.png';
import WWS from '../../assets/WWS.png';
import wficon from '../../assets/wficon.png';
import hfdl from '../../assets/hfdl.png';
import HB from '../../assets/H&B.png';
import RFF from '../../assets/RFF.png';
import cviIcon from '../../assets/cviIcon.png';
import AAV4WSR from '../../assets/MyStompingGrounds.png';
import AAV4CFR from '../../assets/AAV4CFR.png';
import AAV4RFR from '../../assets/AAV4RFR.png';
import CL from '../../assets/CustomLayer.png';
import mobipsr_icon from '../../assets/MoBiPSR.png';
import mobiAll from '../../assets/mobiAll.png';
import mobiPI from '../../assets/mobiPI.png';
import mobiV from '../../assets/mobiV.png';
import NWIL from '../../assets/NWIL.png';
import mobisra from '../../assets/mobisra.png';
import WC from '../../assets/WC.png';
import IndoWatersheds from '../../assets/IndoWatersheds.png';
import IHI from '../../assets/IHI.png';
import ISOs from '../../ISO.json';
import oab from '../../assets/oab.png';
import oabus from '../../assets/oabus.png';
import oabph from '../../assets/oabph.png';
import oabmx from '../../assets/oabmx.png';
import ogwtd from '../../assets/ogwtd.png';
import oabrid from '../../assets/oabrid.png';
import oabrph from '../../assets/oabrph.png';
import oabrpk from '../../assets/oabrpk.png';
import oabrca from '../../assets/oabrca.png';
import oabrmx from '../../assets/oabrmx.png';
import oabrsg from '../../assets/oabrsg.png';
import oabsph from '../../assets/oabsph.png';
import oabsmx from '../../assets/oabsmx.png';
import oabsid from '../../assets/oabsid.png';
import oabsca from '../../assets/oabsca.png';
import oabspk from '../../assets/oabspk.png';
import oabssg from '../../assets/oabssg.png';
import usgswuc from '../../assets/usgswuc.png';
import owpd from '../../assets/owpd.png';
import fhbp from '../../assets/fhbp.png';
import cwa from '../../assets/cwa.png';
import mec from '../../assets/mec.png';
import fbfib from '../../assets/fbfib.png';
import CEJST_icon from '../../assets/CEJST_icon.png';
import USGSHuc12_icon from '../../assets/USGSHuc12_icon.png';
import skyFiAustin from '../../assets/skyFiAustin.png';
import skyFiWhiting from '../../assets/skyFiWhiting.png';
import nsg from '../../assets/NSG.png';
import _GGDE from '../../assets/GGDE.png';
import _HBA from '../../assets/HBAi.png';
import WISE_i from '../../assets/WISE_i.png';
import MBFIBDT_icon from '../../assets/MBFIBDT.png';
import { DateTime } from 'luxon';

const layerList = (global: any) => [
  <LayerItem
    global={global}
    indexed={true}
    catagory="Global Data"
    img={WISE_i}
    name= "WISE Water Framework Directive Database"
    selected={global.Wise}
    setSelected={global.setWise}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="Global Data"
    img={_HBA}
    name= "Global Hyrobasins"
    selected={global.Hydrobasins}
    setSelected={global.setHydrobasins}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="Global Data"
    img={_GGDE}
    name= "Global Groundwater Dependent Ecosystems"
    selected={global.GGDE}
    setSelected={global.setGGDE}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="state specific data"
    country="United States"
    state="Florida"
    img={fhbp}
    name="Florida Healthy Beaches Program"
    selected={global.FHBP}
    setSelected={global.setFHBP}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="state specific data"
    country="United States"
    state="Florida"
    img={fbfib}
    name="FIB Sampling"
    selected={global.FBFIB}
    setSelected={global.setFBFIB}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="state specific data"
    country="United States"
    state="Florida"
    img={MBFIBDT_icon}
    name="Manuel's Branch FIB Sampling Data and Trends"
    selected={global.MBFIBDT}
    setSelected={global.setMBFIBDT}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="state specific data"
    country="United States"
    state="Florida"
    img={mec}
    name="Manuels Branch E.coli Trends"
    selected={global.MEC}
    setSelected={global.setMEC}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="state specific data"
    country="United States"
    state="Montana"
    img={yspc}
    name="Yellowstone Contaminants"
    selected={global.yellowstoneLayer}
    setSelected={global.setYellowstoneLayer}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="state specific data"
    country="United States"
    state="Indiana"
    img={skyFiWhiting}
    name="SkyFi Whiting IN"
    selected={global.SkyFiWhiting}
    setSelected={global.setSkyFiWhiting}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="state specific data"
    country="United States"
    state="Texas"
    img={skyFiAustin}
    name="SkyFi Austin"
    selected={global.SkyFiAustin}
    setSelected={global.setSkyFiAustin}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="state specific data"
    country="United States"
    state="Ohio"
    img={cwa}
    name="Cleveland Water Alliance"
    selected={global.CWALayer}
    setSelected={global.setCWALayer}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="state specific data"
    country="United States"
    state="Ohio"
    img={EPLayer}
    name="East Palestine Outflows"
    selected={global.EPOLayer}
    setSelected={global.setEPOLayer}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="state specific data"
    country="United States"
    state="Oregon"
    img={owpd}
    name="Oregon Water Quality Portal Demo"
    selected={global.OWQPDemo}
    setSelected={global.setOWQPDemo}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="Aquifer and Groundwater"
    country="United States"
    img={domesticWells}
    name="Domestic Well Locations"
    selected={global.domesticWellsSelected}
    setSelected={global.setDomesticWellsSelected}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="Aquifer and Groundwater"
    country="United States"
    img={PrincipleAquifers}
    name="Principal Aquifers"
    selected={global.principalAquifersSelected}
    setSelected={global.setPrincipalAquifersSelected}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="Aquifer and Groundwater"
    country="United States"
    img={nsg}
    name="Neon Science Sensor Location Data"
    selected={global.NSG}
    setSelected={global.setNSG}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="Climate and Biodiversity"
    country="United States"
    img={mobipsr_icon}
    name="MoBi - Plant Species Richness"
    selected={global.MoBiPSR}
    setSelected={global.setMoBiPSR}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="Climate and Biodiversity"
    country="United States"
    img={mobiAll}
    name="MoBi - All Species Richness"
    selected={global.MoBiSRAll}
    setSelected={global.setMoBiSRAll}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="Climate and Biodiversity"
    country="United States"
    img={mobiPI}
    name="MoBi - Pollinator Inverts Species Richness"
    selected={global.MoBiPI}
    setSelected={global.setMoBiPI}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="Climate and Biodiversity"
    country="United States"
    img={mobiV}
    name="MoBi - Vertebrates Species Richness"
    selected={global.MoBiV}
    setSelected={global.setMoBiV}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="Climate and Biodiversity"
    country="United States"
    img={mobisra}
    name="MoBi - Aquatic Inverts"
    selected={global.MoBiSRA}
    setSelected={global.setMoBiSRA}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="Climate and Weather"
    country="United States"
    img={cviIcon}
    name="Climate Vulnerability Index (CVI)"
    selected={global.cvi}
    setSelected={global.setCVI}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="Climate and Weather"
    country="United States"
    img={eddiIcon}
    name="Evaporative Demand Drought Index (EDDI)"
    selected={global.eddi}
    setSelected={global.setEddi}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="Climate and Weather"
    country="United States"
    img={RFF}
    name={`Excessive Rainfall Potential Forecast\n\n(Last updated: ${getLastUpdatedDate(5, 1)})`}
    selected={global.rainfallForecast}
    setSelected={global.setRainfallForecast}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="Climate and Weather"
    country="United States"
    img={FEMA}
    name="FEMA Flood Hazard Zones & Boundaries"
    selected={global.femaZones}
    setSelected={global.setFemaZones}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="Climate and Weather"
    country="United States"
    img={NOAASLR}
    name="NOAA Sea Level Rise"
    selected={global.NOAASeaLevelRise}
    setSelected={global.setNOAASeaLevelRise}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="Climate and Weather"
    country="United States"
    img={drought}
    name={`US Drought Monitoring\n\n(Last updated: ${getLastUpdatedDate(5, 1)})`}
    selected={global.droughtMonitoringSelected}
    setSelected={global.setDroughtMonitoringSelected}
  />,
  <LayerItem
    global={global}
    indexed={true}
    country="United States"
    catagory="Climate and Weather"
    img={NOAAMixIcon}
    name={`QPE: Quantitative Precipitation Estimates\n\n(Last updated: ${DateTime.now().setZone('America/New_York').minus({ hours: 12 }).toLocaleString(DateTime.DATE_FULL)})`}
    selected={global.NOAAMix}
    setSelected={global.setNOAAMix}
  />,
 <LayerItem
    global={global}
    indexed={true}
    country="United States"
    catagory="Climate and Weather"
    img={require(`../../assets/layer/${"nwshazard"}.png`)}
    name={`NOAA All Hazards \n\n(Updates every 5 minutes)`}
    selected={global.nwshazard}
    setSelected={global.setNwshazard}
  />,
  <LayerItem
    global={global}
    indexed={true}
    country="United States"
    catagory="Enviromental Justice Data"
    subcatagory="Pollution and Sources"
    img={EJHWP}
    name="Hazardous Waste Proximity"
    selected={global.EJHazardousWaste}
    setSelected={global.setEJHazardousWaste}
  />,
  <LayerItem
    global={global}
    indexed={true}
    country="United States"
    catagory="Enviromental Justice Data"
    subcatagory="Pollution and Sources"
    img={EJRMPIMG}
    name="RMP Facility Proximity"
    selected={global.EJRMP}
    setSelected={global.setEJRMP}
  />,
  <LayerItem
    global={global}
    indexed={true}
    country="United States"
    catagory="Enviromental Justice Data"
    subcatagory="Pollution and Sources"
    img={EJSUP}
    name="Superfund Proximity"
    selected={global.EJSuperfund}
    setSelected={global.setEJSuperfund}
  />,
  <LayerItem
    global={global}
    indexed={true}
    country="United States"
    catagory="Enviromental Justice Data"
    subcatagory="Pollution and Sources"
    img={EJUST}
    name="Underground Storage Tanks"
    selected={global.EJUndergroundStorageTanks}
    setSelected={global.setEJUndergroundStorageTanks}
  />,
  <LayerItem
    global={global}
    indexed={true}
    country="United States"
    catagory="Enviromental Justice Data"
    subcatagory="Pollution and Sources"
    img={EJWWD}
    name="Wastewater Discharge"
    selected={global.EJWasteWaterDischarge}
    setSelected={global.setEJWasteWaterDischarge}
  />,
  <LayerItem
    global={global}
    indexed={true}
    country="United States"
    catagory="Enviromental Justice Data"
    subcatagory="Socioeconomic Indicators"
    img={EJDEMO}
    name="Demographic Index"
    selected={global.EJDemoSelected}
    setSelected={global.setEJDemoSelected}
  />,
  <LayerItem
    global={global}
    indexed={true}
    country="United States"
    catagory="Enviromental Justice Data"
    subcatagory="Socioeconomic Indicators"
    img={EJLHS}
    name="Less Than High School Education"
    selected={global.EJLessHSSelected}
    setSelected={global.setEJLessHSSelected}
  />,
  <LayerItem
    global={global}
    indexed={true}
    country="United States"
    catagory="Enviromental Justice Data"
    subcatagory="Socioeconomic Indicators"
    img={EJLingIso}
    name="Linguistically Isolated"
    selected={global.EJLingIsoSelected}
    setSelected={global.setEJLingIsoSelected}
  />,
  <LayerItem
    global={global}
    indexed={true}
    country="United States"
    catagory="Enviromental Justice Data"
    subcatagory="Socioeconomic Indicators"
    img={EJLOWINC}
    name="Low Income"
    selected={global.EJLowIncomeSelected}
    setSelected={global.setEJLowIncomeSelected}
  />,
  <LayerItem
    global={global}
    indexed={true}
    country="United States"
    catagory="Enviromental Justice Data"
    subcatagory="Socioeconomic Indicators"
    img={EJPOC}
    name="People of Color"
    selected={global.EJPOCSelected}
    setSelected={global.setEJPOCSelected}
  />,
  <LayerItem
    global={global}
    indexed={true}
    country="United States"
    catagory="Enviromental Justice Data"
    subcatagory="Socioeconomic Indicators"
    img={EJUNEMP}
    name="Unemployment"
    selected={global.EJUnemploymentSelected}
    setSelected={global.setEJUnemploymentSelected}
  />,
  <LayerItem
    global={global}
    indexed={true}
    country="United States"
    catagory="Enviromental Justice Data"
    subcatagory="Socioeconomic Indicators"
    img={EJ5UNDER}
    name="Under Age 5"
    selected={global.EJFiveAndUnderAgeSelected}
    setSelected={global.setEJFiveAndUnderAgeSelected}
  />,
  <LayerItem
    global={global}
    indexed={true}
    country="United States"
    catagory="Enviromental Justice Data"
    subcatagory="Socioeconomic Indicators"
    img={EJ64}
    name="Over Age 64"
    selected={global.EJSixtyFourAndOverSelected}
    setSelected={global.setEJSixtyFourAndOverSelected}
  />,
  <LayerItem
    global={global}
    indexed={true}
    country="United States"
    catagory="Enviromental Justice Data"
    subcatagory="Socioeconomic Indicators"
    img={CEJST_icon}
    name="Climate and Economic Justice Screening"
    selected={global.CEJST}
    setSelected={global.setCEJST}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="Geographic Boundaries and Administrative Areas"
    country="United States"
    img={BIAIcon}
    name="BIA National Land Area Representation"
    selected={global.biaLayer}
    setSelected={global.setBIALayer}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="Geographic Boundaries and Administrative Areas"
    country="United States"
    img={HB}
    name="Highways and Bridges"
    selected={global.toggleHighwayStyle}
    setSelected={global.setToggleHighwayStyle}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="Geographic Boundaries and Administrative Areas"
    country="United States"
    img={resFEMAIMG}
    name="US Counties"
    selected={global.usCounties}
    setSelected={global.setUSCounties}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="Geographic Boundaries and Administrative Areas"
    country="United States"
    img={Tier1}
    name="USA Water Service Boundary (EPIC) Tier 1"
    selected={global.waterSystemBoundaryTier1}
    setSelected={global.setWaterSystemBoundaryTier1}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="Geographic Boundaries and Administrative Areas"
    country="United States"
    img={Tier2}
    name="USA Water Service Boundary (EPIC) Tier 2a"
    selected={global.waterSystemBoundaryTier2}
    setSelected={global.setWaterSystemBoundaryTier2}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="Geographic Boundaries and Administrative Areas"
    country="United States"
    img={Tier3}
    name="USA Water Service Boundary (EPIC) Tier 3"
    selected={global.waterSystemBoundaryTier3}
    setSelected={global.setWaterSystemBoundaryTier3}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="Geographic Boundaries and Administrative Areas"
    country="United States"
    img={zipIcon}
    name="Zip Codes"
    selected={global.zipSelected}
    setSelected={global.setZipSelected}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="Geographic Boundaries and Administrative Areas"
    country="United States"
    img={oabus}
    name="Overture Administrative Boundaries for the US"
    selected={global.OABUS}
    setSelected={global.setOABUS}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="Geographic Boundaries and Administrative Areas"
    country="United States"
    img={usgswuc}
    name="USGS Monthly Public Water Usage"
    selected={global.USGSWUC}
    setSelected={global.setUSGSWUC}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="Geographic Boundaries and Administrative Areas"
    country="United States"
    img={USGSHuc12_icon}
    name="USGS Monthly Public Water Usage By HUC12"
    selected={global.USGSHuc12}
    setSelected={global.setUSGSHuc12}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="Geographic Boundaries and Administrative Areas"
    country="United States"
    img={skyFiWhiting}
    name="SkyFi Whiting IN"
    selected={global.SkyFiWhiting}
    setSelected={global.setSkyFiWhiting}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="Geographic Boundaries and Administrative Areas"
    country="United States"
    img={skyFiAustin}
    name="SkyFi Austin"
    selected={global.SkyFiAustin}
    setSelected={global.setSkyFiAustin}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="Water Contamination and Pollution"
    country="United States"
    img={EPLayer}
    name="East Palestine Outflows"
    selected={global.EPOLayer}
    setSelected={global.setEPOLayer}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="Water Contamination and Pollution"
    country="United States"
    img={EPACircles}
    name="EPA Superfund Sites (National Priorities List)"
    selected={global.EPASuperfundSites}
    setSelected={global.setEPASuperfundSites}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="Water Contamination and Pollution"
    country="United States"
    img={gwNitrateAssessmentImg}
    name="Groundwater Nitrate Assessment"
    selected={global.gwNitrateAssessmentSelected}
    setSelected={global.setGwNitrateAssessmentSelected}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="Water Contamination and Pollution"
    country="United States"
    img={hfdl}
    name="Historical Fire Data"
    selected={global.allFireData}
    setSelected={global.setAllFireData}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="Water Contamination and Pollution"
    country="United States"
    img={I10}
    name="I-10 HUC 12's"
    selected={global.I10Huc12s}
    setSelected={global.setI10Huc12s}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="Water Contamination and Pollution"
    country="United States"
    img={top100}
    name="Toxic 100 Water Polluters"
    selected={global.pollutionSelected}
    setSelected={global.setPollutionSelected}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="Water Contamination and Pollution"
    country="United States"
    img={gemstats}
    name="UN Gemstat Stations"
    selected={global.gemstat}
    setSelected={global.setGemstat}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="Water Contamination and Pollution"
    country="United States"
    img={wficon}
    name={`Wild Fire Boundaries\n\n(Last updated: ${getLastUpdatedDate(5, 1)})`}
    selected={global.wildFireBoundaries}
    setSelected={global.setWildFireBoundaries}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="Water Contamination and Pollution"
    country="United States"
    img={WQPIcon}
    name="Water Quality Portal (WQP)"
    selected={global.waterQualityPortal}
    setSelected={global.setWaterQualityPortal}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="Water Contamination and Pollution"
    country="United States"
    img={NWIL}
    name="National 303D Impaired Waters Layer"
    selected={global.NationalWaterImpairements}
    setSelected={global.setNationalWaterImpairements}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="Watershed and Hydrology"
    country="United States"
    img={nhdImg}
    name='Lakes, Rivers, and Streams'
    selected={global.nhdSelected}
    setSelected={global.setNhdSelected}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="Watershed and Hydrology"
    country="United States"
    img={WWS}
    name='National View USGS WaterWatch Sites'
    selected={global.waterWatchSites}
    setSelected={global.setWaterWatchSites}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="Watershed and Hydrology"
    country="United States"
    img={outflowsImg}
    name='Outflows'
    selected={global.outflowsSelected}
    setSelected={global.setOutflowsSelected}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="Watershed and Hydrology"
    country="United States"
    img={hucImg}
    name="Watersheds (HUC's)"
    selected={global.hucsSelected}
    setSelected={global.setHucsSelected}
  />,
  <LayerItem
    global={global}
    indexed={true}
    country="Australia"
    img={AUSPH}
    name="Australia's Principal Hydrogeology"
    selected={global.ausPrinHydro}
    setSelected={global.setAusPrinHydro}
  />,
  <LayerItem
    global={global}
    indexed={true}
    country="Australia"
    img={ANGISUpperIcon}
    name="NGIS Upper"
    selected={global.ANGISUpper}
    setSelected={global.setANGISUpper}
  />,
  <LayerItem
    global={global}
    indexed={true}
    country="Australia"
    img={ANGISMiddleIcon}
    name="NGIS Middle"
    selected={global.ANGISMid}
    setSelected={global.setANGISMid}
  />,
  <LayerItem
    global={global}
    indexed={true}
    country="Australia"
    img={ANGISLowerIcon}
    name="NGIS Lower"
    selected={global.ANGISLower}
    setSelected={global.setANGISLower}
  />,
  <LayerItem
    global={global}
    indexed={true}
    country="Australia"
    img={ABWDIcon}
    name="Australia Bore & Water Data"
    selected={global.ABWDLayer}
    setSelected={global.setABWDLayer}
  />,
  <LayerItem
    global={global}
    indexed={true}
    country="Australia"
    img={Ausgemstat}
    name="Australia Gemstat"
    selected={global.ausGemstat}
    setSelected={global.setAusGemstat}
  />,
  <LayerItem
    global={global}
    indexed={true}
    country="Pakistan"
    img={oabrpk}
    name="Overture Pakistan River Systems"
    selected={global.OABRPK}
    setSelected={global.setOABRPK}
  />,
  <LayerItem
    global={global}
    indexed={true}
    country="Pakistan"
    img={oabspk}
    name="Overture Pakistan Stream Systems"
    selected={global.OABSPK}
    setSelected={global.setOABSPK}
  />,
  <LayerItem
    global={global}
    indexed={true}
    country="Mexico"
    img={MEXHUC}
    name="HUC's Mexico"
    selected={global.HUCsMexico}
    setSelected={global.setHUCsMexico}
  />,
  <LayerItem
    global={global}
    indexed={true}
    country="Mexico"
    img={oabmx}
    name="Overture Administrative Boundaries for Mexico"
    selected={global.OABMX}
    setSelected={global.setOABMX}
  />,
  <LayerItem
    global={global}
    indexed={true}
    country="Mexico"
    img={oabrmx}
    name="Overture Mexican River Systems"
    selected={global.OABRMX}
    setSelected={global.setOABRMX}
  />,
  <LayerItem
    global={global}
    indexed={true}
    country="Mexico"
    img={oabsmx}
    name="Overture Mexican Stream Systems"
    selected={global.OABSMX}
    setSelected={global.setOABSMX}
  />,
  <LayerItem
    global={global}
    indexed={true}
    country="Singapore"
    img={oabrsg}
    name="Overture Singapore River Systems"
    selected={global.OABRSG}
    setSelected={global.setOABRSG}
  />,
  <LayerItem
    global={global}
    indexed={true}
    country="Singapore"
    img={oabssg}
    name="Overture Singapore Stream Systems"
    selected={global.OABSSG}
    setSelected={global.setOABSSG}
  />,
  <LayerItem
    global={global}
    indexed={true}
    country="Canada"
    img={oabrca}
    name="Overture Canadian River Systems"
    selected={global.OABRCA}
    setSelected={global.setOABRCA}
  />,
  <LayerItem
    global={global}
    indexed={true}
    country="Canada"
    img={oabsca}
    name="Overture Canadian Stream Systems"
    selected={global.OABSCA}
    setSelected={global.setOABSCA}
  />,
  <LayerItem
    global={global}
    indexed={true}
    country="Indonesia"
    img={IndoWatersheds}
    name="Indonesian Watersheds"
    selected={global.indoWatersheds}
    setSelected={global.setIndoWatersheds}
  />,
  <LayerItem
    global={global}
    indexed={true}
    country="Indonesia"
    img={IHI}
    name="Indonesian HUCs"
    selected={global.indoHucs}
    setSelected={global.setIndoHucs}
  />,
  <LayerItem
    global={global}
    indexed={true}
    country="Indonesia"
    img={oab}
    name="Overture Administrative Boundaries Indonesia"
    selected={global.OABID}
    setSelected={global.setOABID}
  />,
  <LayerItem
    global={global}
    indexed={true}
    country="Indonesia"
    img={oabrid}
    name="Overture Indonesia River Systems"
    selected={global.OABRID}
    setSelected={global.setOABRID}
  />,
  <LayerItem
    global={global}
    indexed={true}
    country="Indonesia"
    img={oabsid}
    name="Overture Indonesia Stream Systems"
    selected={global.OABSID}
    setSelected={global.setOABSID}
  />,
  <LayerItem
    global={global}
    indexed={true}
    country="Philippines"
    img={PHBasins}
    name="Philippines River Basins"
    selected={global.philippinesRiverBasins}
    setSelected={global.setPhilippinesRiverBasins}
  />,
  <LayerItem
    global={global}
    indexed={true}
    country="Philippines"
    img={PHRivers}
    name="Philippines Water Courses (Rivers)"
    selected={global.philippinesRivers}
    setSelected={global.setPhilippinesRivers}
  />,
  <LayerItem
    global={global}
    indexed={true}
    country="Philippines"
    img={PHRegions}
    name="Philippine Regions"
    selected={global.philRegions}
    setSelected={global.setPhilRegions}
  />,
  <LayerItem
    global={global}
    indexed={true}
    country="Philippines"
    img={PHFH}
    name="Philippine Flood Hazard Zones"
    selected={global.phFloodHazard}
    setSelected={global.setPHFloodHazard}
  />,
  <LayerItem
    global={global}
    indexed={true}
    country="Philippines"
    img={phgemstat}
    name="Philippine Gemstat Station"
    selected={global.gemstatPH}
    setSelected={global.setGemstatPH}
  />,
  <LayerItem
    global={global}
    indexed={true}
    country="Philippines"
    img={oabph}
    name="Overture Administrative Boundaries for the Philippines"
    selected={global.OABPH}
    setSelected={global.setOABPH}
  />,
  <LayerItem
    global={global}
    indexed={true}
    country="Philippines"
    img={oabrph}
    name="Philippines River Systems"
    selected={global.OABRPH}
    setSelected={global.setOABRPH}
  />,
  <LayerItem
    global={global}
    indexed={true}
    country="Philippines"
    img={oabsph}
    name="Philippines Strem Systems"
    selected={global.OABSPH}
    setSelected={global.setOABSPH}
  />,
  <LayerItem
    global={global}
    indexed={true}
    img={CL}
    name="Apple Organization Location Data"
    selected={global.AppleLocationData}
    setSelected={global.setAppleLocationData}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="Global Data"
    img={AACoastalFloodRisk}
    name="Aqueduct Atlas Coastal Flood Risk v3.0"
    selected={global.aqueductAtlasCoastalFlood}
    setSelected={global.setAqueductAtlasCoastalFlood}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="Global Data"
    img={AAWaterStress}
    name="Aqueduct Atlas Water Stress Risk v3.0"
    selected={global.aqueductAtlasWaterStress}
    setSelected={global.setAqueductAtlasWaterStress}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="Global Data"
    img={AARiverine}
    name="Aqueduct Atlas Riverine Flood Risk v3.0"
    selected={global.aqueductAtlasRiverine}
    setSelected={global.setAqueductAtlasRiverine}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="Global Data"
    img={AAV4CFR}
    name="Aqueduct Atlas Coastal Flood Risk v4.0"
    selected={global.AAV4CoastalFloodRisk}
    setSelected={global.setAAV4CoastalFloodRisk}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="Global Data"
    img={AAV4WSR}
    name="Aqueduct Atlas Water Stress Risk v4.0"
    selected={global.AAV4WaterStress}
    setSelected={global.setAAV4WaterStress}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="Global Data"
    img={AAV4RFR}
    name="Aqueduct Atlas Riverine Flood Risk v4.0"
    selected={global.AAV4RiverineFloodRisk}
    setSelected={global.setAAV4RiverineFloodRisk}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="Global Data"
    img={MECL}
    name="Microsoft Terraclimate Evapotranspiration"
    disabled={global.terraClimateLayerSelected}
    selected={global.evapotranspirationSelected}
    setSelected={global.setEvapotranspirationSelected}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="Global Data"
    img={MTCL}
    name="Microsoft Terraclimate Precipitation"
    disabled={global.evapotranspirationSelected}
    selected={global.terraClimateLayerSelected}
    setSelected={global.setTerraClimateLayerSelected}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="Global Data"
    img={WDPAIcon}
    name="Protected Areas World Database"
    selected={global.WDPA}
    setSelected={global.setWDPA}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="Global Data"
    img={wrfc}
    name="WRF Country Risk"
    selected={global.wrfcRisk}
    setSelected={global.setWRFCRisk}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="Global Data"
    img={GBIF_Icon}
    name="Global Biodiversity Information Facility (GBIF)"
    selected={global.GBIF}
    setSelected={global.setGBIF}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="Global Data"
    img={WC}
    name={`Water Conflict\n\n(Last updated: ${getLastUpdatedDate(5, 1)})`}
    selected={global.WaterConflict}
    setSelected={global.setWaterConflict}
  />,
  <LayerItem
    global={global}
    indexed={true}
    catagory="Global Data"
    img={ogwtd}
    name="Overture Global Water Treatment Data"
    selected={global.OGWTD}
    setSelected={global.setOGWTD}
  />
];

export default layerList;