// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.circledata-popup .mapboxgl-popup-content div {
  text-align: center; }

.circledata-popup .mapboxgl-popup-content .close-btn {
  color: white;
  position: absolute;
  top: 5%;
  right: 3%;
  font-weight: bolder;
  cursor: pointer; }

.circledata-popup .mapboxgl-popup-content .goTo {
  color: #4fc2d9;
  font-weight: bold;
  text-decoration: underline;
  border-radius: 6px;
  cursor: pointer; }
`, "",{"version":3,"sources":["webpack://./src/components/Layers/CircleLayer/circle-data-layer.scss"],"names":[],"mappings":"AAAA;EAGY,kBAAkB,EAAA;;AAH9B;EAOY,YAAY;EACZ,kBAAkB;EAClB,OAAO;EACP,SAAS;EACT,mBAAmB;EACnB,eAAe,EAAA;;AAZ3B;EAgBY,cAAc;EACd,iBAAiB;EACjB,0BAA0B;EAC1B,kBAAkB;EAClB,eAAe,EAAA","sourcesContent":[".circledata-popup {\n    .mapboxgl-popup-content {\n        div {\n            text-align: center;\n        }\n\n        .close-btn {\n            color: white;\n            position: absolute;\n            top: 5%;\n            right: 3%;\n            font-weight: bolder;\n            cursor: pointer;\n        }\n\n        .goTo {\n            color: #4fc2d9;\n            font-weight: bold;\n            text-decoration: underline;\n            border-radius: 6px;\n            cursor: pointer;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
