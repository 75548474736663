import { useEffect, useState, useRef, useCallback, useContext, useMemo, Ref, useLayoutEffect } from "react";
import * as React from 'react';
import {
  Map, MapboxStyle, useMap, MapRef, MapLayerMouseEvent, Popup,
  FullscreenControl,
  NavigationControl,
  GeolocateControl,
  useControl,
  Layer,
  Source,
  FillLayer,
} from "react-map-gl";
import settings from "../../../settings.json";
import { Form, Button, Row, Col, Table, Container, Tabs, Tab, OverlayTrigger, Popover, ListGroup, Modal, Carousel, Alert, Badge, Dropdown, DropdownButton, ProgressBar } from "react-bootstrap";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { Expression, StyleFunction } from 'mapbox-gl';
import agent from "../../../api/agent";
import { formatRowValue, renderRestrictions } from "../../utils";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faLeft, faRight, faGear, faArrowRight, faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import axios, { AxiosResponse } from 'axios';
import LoadingDataAnimation from "../../../components/LoadingDataAnimation";
import { Feature, GeoJsonProperties, Point, Polygon, GeometryCollection, FeatureCollection, Geometry } from 'geojson';
import type { LayerProps } from 'react-map-gl';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import moment from 'moment';
import * as d3Array from 'd3-array';
import * as d3Format from 'd3-format';
import * as d3Scale from 'd3-scale';
import * as d3ScaleChromatic from 'd3-scale-chromatic';
import bbox from '@turf/bbox';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { DateTime } from 'luxon';
import { parse } from 'papaparse';
import { ToastContainer, toast } from 'react-toastify';

import { AppContext } from '../../../AppContext';
import { CatalogsContext } from '../../../CatalogsProvider';
import { Location, useLocation } from 'react-router-dom';
import MapLegend from '../shared/MapLegend';
import MapAddition from  '../shared/MapAddition';
import FilterState from '../shared/FilterState';
import ReportHeader from '../shared/ReportHeader';
import ReportFooter from '../shared/ReportFooter';
import ReportMap from '../shared/ReportMap';
import ReportTableExpanded from '../shared/ReportTableExpanded';
import {
  useTable, useExpanded, HeaderGroup,
  HeaderProps
} from 'react-table'

import queryString from 'query-string';
import {
  QueryParamProvider,
  useQueryParam,
  BooleanParam,
  StringParam
} from 'use-query-params';
import { useNavigate } from 'react-router';


import { flyToHUC12sLocation, flyToInitialLocationHandler, getCurrentHuc12LongLat, getHuc12MembersOfHUC8, getCurrentHuc8LongLat } from '../../utils';
import Render from "../../../components/render";
import { RefContext } from '../../../RefContext';
import { createFeatureCollection, processStatusMessage, uniqueValues, updateChartData, groupByKey, fitBoundsMapData, findFittingDateRange } from '../shared/report-data';

import './styles.scss'

import fullscreenIcon from "../../../assets/fullscreen-icon.svg";
import ellipsisIcon from "../../../assets/ellipsis-icon.svg";
import logoImg from '../../../assets/true-elements-logo.svg';

import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import { features } from "process";


import RiskCategories from './RiskCategories';
import RiskGroupings from './RiskGroupings';
import RiskCollection from './RiskCollection';
import { RiskEvaluation, getHUC12, setSurfaceWaterDataEvaluation, RiskEvaluationAsync } from './RiskEvaluations';
import { OrganizationConfig } from '../../../utils/OrganizationConfig';

import localForage from "localforage";
import { unparse } from 'papaparse';
import html2canvas from 'html2canvas';
import { jsPDF, ImageOptions } from "jspdf";
import autoTable from 'jspdf-autotable';

import { RiskAssessment } from "../PrintReports/RiskAssessment";
import { RiskAssessmentPrimary } from "../PrintReports/RiskAssessmentPrimary";
import { calculateIntensity, calculateRecurrence, processClimateSites } from '../ClimatePrecipitationCalculator/ClimateCalculations';



export default function Risk(props: any) {
  const { global } = props;
  const appContext = useContext(AppContext);
  const catalogsContext = useContext(CatalogsContext);
  let selectedHUC8Value = appContext.selectedHUC8;
  const selectedCategory = (global.filter1Selected || '');
  const selectedFilterLocations = global.filterLocationsSelected;
  const [selectedPointCategory, setSelectedPointCategory] = useState<string>('Intensity in 2022'); //atrisk  

  const location: Location = useLocation();
  //@ts-ignore
  const routeData = location.state?.data;

  const colorGreen = "#4fc22c";
  const colorYellow = "#e6ee00";
  const colorYellowDarker = "#d3da00";
  const colorRed = "#c22c4f";
  const locationField = 'name';

  const { current: map } = useMap();
  const [popupInfo, setPopupInfo] = useState<any>(null);
  const [cursor, setCursor] = useState<string>('auto');
  const mapRef = useRef<MapRef>();
  const [mapLoaded, setMapLoaded] = useState(false);

  const [mapHoverFilter, setMapHoverFilter] = useState<any>(['in', ['get', locationField], '']);
  const [mapLegendData, setMapLegendData] = useState<any[]>([]);
  const [circleColorStyle, setCircleColorStyle] = useState<any>([]);
  const [mapStyleData, setMapStyleData] = useState<any>([]);

  const [userSelectedLocation, setUserSelectedLocation] = useState<any>(null);
  const [userSelectedLocations, setUserSelectedLocations] = useState<any[]>([]);
  const [usLocations, setUsLocations] = useState<any>([]);
  const [hoverLocation, setHoverLocation] = useState<any>([]);
  const [clickLocation, setClickLocation] = useState<any>([]);

  const [nationalArray, setNationalArray] = useState<any[]>([]);

  const [dataLoaded, setDataLoaded] = useState(false);
  const [features, setFeatures] = useState({});
  const [featureData, setFeatureData] = useState<FeatureCollection>();
  const [reportData, setReportData] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);

  const [statusMessage, setStatusMessage] = useState<any>(null);
  const [layerStyle, setLayerStyle] = useState<any>([]);
  const [currentDate, setCurrentDate] = useState<any>(null);
  const chartRef = useRef<any>();
  const [filterLabelDays, setFilterLabelDays] = useState<any>([]);

  const [layerStylePoint, setLayerStylePoint] = useState<any>([]);
  const [mapExpressionPoint, setMapExpressionPoint] = useState<Expression>(['match', ['get', 'pointCategoryColor'], "#c22c4f",
    "#c22c4f",
    "#4fc22c",
    "#4fc22c",
    "#e6ee00",
    "#e6ee00",
    "rgba(0,0,0,0)"]);

  const [dataArray, setDataArray] = useState<any[]>([]);
  const [dataArray_unfiltered, setDataArray_unfiltered] = useState<any[]>([]);
  const [surfaceWaterData, setSurfaceWaterData] = useState<any[]>([]);

  const [filterCategory, setFilterCategory] = useState<string>('');

  const [data, setData] = useState({
    labels: [] as any,
    datasets: [] as any,
  } as const);

  const navigate = useNavigate();
  const [config, setConfig] = useState({} as const);
  const [isDataReportLoaded, setIsDataReportLoaded] = useState(false);
  const [isTableSort, setTableSort] = useState(true);
  const FullScreenComp = FullScreen as any;
  const fullscreenhandle = useFullScreenHandle() as any;
  const [isFullScreenActive, setFullScreenActive] = useState(false);
  const fullscreenContainerHandler = () => { setFullScreenActive(!isFullScreenActive); };

  const [importShow, setImportShow] = useState(false);
  const [indexImport, setIndexImport] = useState(0);

  const [qent, setqent] = useQueryParam('ent', BooleanParam);
  const [qccds, setqccds] = useQueryParam('ccds', StringParam);
  const [qcind, setqcind] = useQueryParam('ind', StringParam);
  const [qcinds, setqcinds] = useQueryParam('inds', StringParam);
  const [qcrcl, setqcrcl] = useQueryParam('rcl', StringParam);
  const [currentDataset, setCurrentDataset] = useState("");
  const [currentDatasetLabel, setCurrentDatasetLabel] = useState("");

  const [fillMap, setFillMap] = useState(false); //+++
  const [pointMap, setPointMap] = useState(true);

  const [interactiveLayers, setInteractiveLayers] = useState<any[]>(['pointlayer']);
  const [mapLegendPointData, setMapLegendPointData] = useState<any[]>([]);
  const [pointAccess, setPointAccess] = useState(true);
  const [tabOverview, setTabOverview] = useState(true);
  const [tabActive, setTabActive] = useState('Overview');
  const [assessmentField, setAssessmentField] = useState('   ');

  const analysisWaterUsage = [{ active: 'true', title: 'Consumed' }, { active: 'true', title: 'Withdrawn' }, { active: 'true', title: 'Discharged' }];
  const analysisIngredientSourcing = [{ active: 'true', title: 'Agriculture Products' }];
  const [analysisFields, setAnalysisFields] = useState<any[]>(analysisWaterUsage);
  const [analysisUnit, setAnalysisUnit] = useState('');
  const [sasbData, setSasbData] = useState<any[]>([]);

  const [progress, setProgress] = useState<number>(0);
  const [isNotInProgress, setIsNotInProgress] = useState(true);

  let rC = [...RiskCategories];
  let previousIndustry = sessionStorage.getItem("reportTabs_group");
  const rC_session = sessionStorage.getItem("reportTabs");
  if (rC_session) {
    /*    if(previousIndustry && previousIndustry === qcind){
          //@ts-ignore
          rC = JSON.parse(rC_session);
        } else if (!previousIndustry){
          //@ts-ignore
          rC = JSON.parse(rC_session);
        }*/
  }

  const [reportTabs, setReportTabs] = useState<any[]>([...rC]);
  const [reportTabsAvailable, setReportTabsAvailable] = useState<any[]>([...rC]);
  const [reportTabsActiveLength, setReportTabsActiveLength] = useState(0);
  const [locationsLoaded, setLocationsLoaded] = useState(false);

  const { setCurrentRef } = useContext(RefContext);
  const reportBounds = mapRef.current ? mapRef.current.getMap().getBounds().toArray().flat() : null;

  let reportTabs_ = [...RiskCategories];

  const internationalEval = [...RiskCategories].filter(x => x.location === 'international')?.map(function (x: any) { return x.title; });
  const USA = ['US', 'USA', 'U.S.', 'U.S.A.', 'United States', 'United States of America'];

  let selectedClimateCategory = '100yr';
  let yearFacilityDesign = 1961;
  let yearInterest = 2023;

  const [uploaderStep, setUploaderStep] = useState(1);
  const [uploaderLocations, setUploaderLocations] = useState<any[]>([]);
  const [uploaderProcessedLocations, setUploaderProcessedLocations] = useState<any[]>([]);
  const [uploaderFile, setUploaderFile] = useState<any>();
  const [layerName, setLayerName] = useState<any>();
  const [alertUploaderShow, setAlertUploaderShow] = useState(false);
  const [alertUploaderMessage, setAlertUploaderMessage] = useState({title: 'a', message:'b', variant: 'success'});
  const [uploaderGeocode, setUploaderGeocode] = useState(true);
  const [uploaderRevGeocode, setUploaderRevGeocode] = useState(true);
  const [uploaderName, setUploaderName] = useState(true);
  const [locationAdd, setLocationAdd] =  useState<any[]>([]);


  useEffect(() => {
    setCurrentRef?.(mapRef)
  }, [mapRef])

  useEffect(() => {
    global.setUserLogged(true);
    if (global.trackerOn) global.setTrackerOn(false)  
  }, []);

  useEffect(() => {
    if (global.guiSwitch && global.guiSwitch.indexOf('riskimport') > -1) {
      setImportShow(true);
    }
  }, [global.guiSwitch]);

  useEffect(() => { //+ ensure new category is in RiskCollections
    
    const clearRisk = localStorage.getItem('ra_clearRiskTabs');
    const clearRiskFactor = '5'; //++ ra_clearRiskTabs is for clearing RiskCategories cache, increment to clear
    if (clearRisk !== clearRiskFactor) {
      setReportTabs([...RiskCategories]);
      localStorage.setItem('ra_clearRiskTabs', clearRiskFactor);
    }
    
  }, []);

  useEffect(() => {
    let initialSectorCode = 'FB';
    let initialIndustryCode = 'AG';
    let initialSector = 'Food and Beverage';
    let initialIndustry = 'Agriculture Products';

    let rGroupings = [...RiskGroupings];
    if (qcinds && qcinds.length === 4) {
      initialSectorCode = qcinds.substr(0, 2);
      initialIndustryCode = qcinds.substr(2, 2);
    }

    let cGroupings = rGroupings.find(x => x.type === 'Industry' && x.code === initialIndustryCode);
    let sectorFound = rGroupings.find(x => x.type === 'Sector' && x.code === initialSectorCode);
    //@ts-ignore
    initialSector = sectorFound.label || 'Food and Beverage';
    //@ts-ignore
    initialIndustry = cGroupings.label || 'Agriculture Products';

    let sGroupings = rGroupings.filter(x => x.type === 'Sector').map(i => i['label']);
    let iGroupings = rGroupings.filter(x => x.type === 'Industry' && x.sectorParent === initialSectorCode).map(i => i['label']);
    //@ts-ignore
    let FilterOptions = rGroupings.filter(x => x.type === 'Industry');//.map(i=>i['label']);

    global.setfilter1Populated(sGroupings);
    global.setfilter1Selected(initialSector);
    global.setSelectedMeasurementFilterOptions(FilterOptions);
    global.setSelectedMeasurementFilterValue(initialIndustry)
    //@ts-ignore
    setAnalysisFields(cGroupings.s440a1_fields || []);
    //@ts-ignore
    setAnalysisUnit(cGroupings.s440a1_unit || '');
  }, []);

  const industryChange = (isector: any, _industry: any, noqcinds: boolean) => { //sectorLabel, industryLabel
    let industry = _industry;
    if(_industry && _industry.length === 1 && _industry[0].length > 5){
      industry = _industry[0];
    }
    if (!industry || industry === 'Streamflow' || (industry.length>0 && industry[0] === 'Streamflow')) {
      return false;
    }

    let rGroupings = [...RiskGroupings];

    let ind = rGroupings.find(x => x.type === 'Industry' && x.title === industry)
    console.log(ind, industry)
    ///@ts-ignore
    let sector = (ind && ind.sectorParentTitle) || 'Food and Beverage';//@ts-ignore

    let sGroupings = rGroupings.find(x => x.type === 'Sector' && x.label === sector);
    //@ts-ignore
    let iGroupings = sGroupings && sGroupings.code && rGroupings.filter(x => x.type === 'Industry' && x.sectorParent === sGroupings.code).map(i => i['label']);
    let iGroupingsLabel = industry;
    if (!industry && iGroupings) {
      iGroupingsLabel = iGroupings[0]; //industryLabel
    }
    let sGroupingsLabel = sGroupings && sGroupings.code;
    //@ts-ignore
    let FilterOptions = sGroupings && sGroupings.code && rGroupings.filter(x => x.type === 'Industry');

    if (iGroupingsLabel && sGroupingsLabel) {
      let cGroupings = rGroupings.find(x => x.type === 'Industry' && x.label === iGroupingsLabel);
      console.log(isector, rGroupings, ind, sector, sGroupings, iGroupings, iGroupingsLabel, cGroupings)
      global.setSelectedMeasurementFilterOptions(FilterOptions);
      global.setSelectedMeasurementFilterValue(iGroupingsLabel)
      if (!noqcinds && sGroupings && cGroupings && sGroupings.code && cGroupings.code) {
        //@ts-ignore
        setqcinds(sGroupings.code + cGroupings.code);
      }

      //@ts-ignore
      setAnalysisFields(cGroupings.s440a1_fields || []);
      //@ts-ignore
      setAnalysisUnit(cGroupings.s440a1_unit || '');

      let sAF = 'By Cost';
      //@ts-ignore
      sAF = cGroupings && cGroupings.s440a1_fields && cGroupings.s440a1_fields[0] && cGroupings.s440a1_fields[0]?.title;
      if (sAF) {
        setAssessmentField(sAF);
      }
      onSelectTabs(tabActive);
    }
  }

  const getIndustryGrouping = () => {
    let initialSectorCode = 'FB';
    let initialIndustryCode = 'AG';
    if (qcinds && qcinds.length === 4) {
      initialSectorCode = qcinds.substr(0, 2);
      initialIndustryCode = qcinds.substr(2, 2);
    }
    let cGroupings = [...RiskGroupings].find(x => x.type === 'Industry' && x.code === initialIndustryCode);
    if (cGroupings && cGroupings.sasbsections && cGroupings.sasbsections.length > 0) {
      //@ts-ignore
      // console.log(initialIndustryCode, cGroupings, cGroupings.sasbsections, [...RiskCategories].filter(x => cGroupings.sasbsections.indexOf(x.sasbrefid)>-1))
      //@ts-ignore
      cGroupings.sasbcategory = [...RiskCategories].filter(x => cGroupings.sasbsections.indexOf(x.sasbrefid) > -1); //@ts-ignore

      for (let dataItem of cGroupings.sasbcategory) {
        dataItem.sasblabel = initialSectorCode + '-' + initialIndustryCode + '-' + dataItem.sasbrefid;
      }
    }
    return cGroupings;
  }

  const checkIndustry = (noset?: boolean) => {
    let initialSectorCode = 'FB';
    let initialIndustryCode = 'AG';
    let initialSector = 'Food and Beverage';
    let initialIndustry = 'Agriculture Products';
    let rGroupings = [...RiskGroupings];

    if (qcinds && qcinds.length === 4) {
      initialSectorCode = qcinds.substr(0, 2);
      initialIndustryCode = qcinds.substr(2, 2);
    }

    let cGroupings = rGroupings.find(x => x.type === 'Industry' && x.code === initialIndustryCode);
    let sGroupings = rGroupings.find(x => x.type === 'Sector' && x.code === initialSectorCode);
    //@ts-ignore
    let iGroupings = sGroupings && sGroupings.code && rGroupings.filter(x => x.type === 'Industry' && x.sectorParent === sGroupings.code).map(i => i['label']);
    //@ts-ignore
    let iGroupingsLabel = iGroupings[0];
    let sGroupingsLabel = sGroupings && sGroupings.code;

    if (iGroupingsLabel && sGroupingsLabel) {
      if (!noset) {
        //@ts-ignore
        setAnalysisFields(cGroupings.s440a1_fields || []);
        //@ts-ignore
        setAnalysisUnit(cGroupings.s440a1_unit || '');

      }//@ts-ignore
      return cGroupings.s440a1_fields;
    }
  }


  useEffect(() => {
    if (global.filter1Selected) {
      industryChange(global.filter1Selected, null, false);
    }
  }, [global.filter1Selected]);

  useEffect(() => {
    if (global.selectedMeasurementFilterValue) {
      industryChange(global.filter1Selected, global.selectedMeasurementFilterValue, false);
    }
  }, [global.selectedMeasurementFilterValue]);


  const checkRiskCollection = (currentTabs:any) => {
    if (qcrcl && qcrcl.length >1) {

      let newTabsAvailable = [] as any;
      let rGroupings = [...RiskCollection];
      let qGroupings = rGroupings.find(x => x.code === qcrcl); //@ts-ignore
     
      sessionStorage.setItem("reportTabs_collection", qcrcl)
      let rTabs = [...currentTabs];
      if (qGroupings && qGroupings.evaluations && reportTabs) {
        for (let dataItem of rTabs) { 
          if (qGroupings.evaluations.indexOf(dataItem.title) < 0) {
            dataItem.active = "";
          } else {
            dataItem.active = "true";
            newTabsAvailable.push(dataItem)
          }
        }
        setReportTabsAvailable(newTabsAvailable);
   
        return rTabs

      } else {
          return null;
      }
    }
  }

  useEffect(() => {
    if (qcinds && qcinds.length === 4) {
      sessionStorage.setItem("reportTabs_groups", qcinds)
      let rGroupings = [...RiskGroupings];
      let qGroupings = rGroupings.find(x => x.code === qcinds.substr(2, 2));
      let rTabs = [...reportTabs];
      if (qGroupings && qGroupings.evaluations && reportTabs) {
        for (let dataItem of rTabs) {
          if (qGroupings.evaluations.indexOf(dataItem.title) < 0) {
            dataItem.active = "";
          } else {
            dataItem.active = "true";
          }
        }

        let newTabs = checkRiskCollection(rTabs);
        if(newTabs){
          setReportTabs(newTabs);
        } else {
          setReportTabs(rTabs);
        }       
      }

      setTimeout(() => {
        summarizeAssessments('qcinds')
      }, 111);
    }
  }, [qcinds]);


  useEffect(() => {
    if (qcrcl && qcrcl.length >1) {
      let rTabs = [...reportTabs];
      let newTabs = checkRiskCollection(rTabs);
      if(newTabs){
        setReportTabs(rTabs);
      }
    }
  }, [qcrcl]);

  useEffect(() => {//@ts-ignore
    console.log('locationAdd', locationAdd, locationAdd.length, locationAdd!==[])//@ts-ignore
    if(locationAdd && locationAdd.Location_Name){
      let dataItem = locationAdd;
      //@ts-ignore
      if (!dataItem["HUC8"] && USA.indexOf(dataItem.Country) > -1) { 
          //@ts-ignore
          if (dataItem["HUC12"]) {//@ts-ignore
              dataItem["HUC8"] = dataItem["HUC12"].substr(0, 8);
              upLocationsAdd(dataItem);
          } else {
            getHUC12(dataItem, upLocationsAdd);
          }  
      } else {  
        upLocationsAdd(dataItem);      
      }

    }   
  }, [locationAdd])

  useEffect(() => {//@ts-ignore
    //console.log('uploaderLocations', uploaderLocations)//@ts-ignore
    if(uploaderLocations && uploaderLocations.length>0){

    }   
  }, [uploaderLocations])

  useEffect(() => {//@ts-ignore
    //console.log('uploaderProcessedLocations', uploaderProcessedLocations)//@ts-ignore

  }, [uploaderProcessedLocations])

  const upLocationsAdd = (dataItem: any) => {
        //console.log('upLocationsAdd_', uploaderProcessedLocations)
        let upLocations = [...uploaderProcessedLocations];
        const location = upLocations && upLocations.filter((item: any) => !(item.Latitude === dataItem.Latitude && item.Longitude === dataItem.Longitude && item.Location_Name === dataItem.Location_Name))
        location.push(dataItem)
        setLocationAdd([]);//@ts-ignore
       
        upLocations.push(dataItem)
       
        //setUploaderProcessedLocations(upLocations.push(dataItem)); 
  }
  

  useLayoutEffect(() => {
    return () => {
      setMapLayers('null');
    }
  }, [])

  useEffect(() => {
    let pointsFileURL = "";
    const qOrganizationConfig = OrganizationConfig.find(x => x.ccds === qccds);
    if (qOrganizationConfig) {
      pointsFileURL = qOrganizationConfig.csv;
      setCurrentDataset(pointsFileURL);
      setCurrentDatasetLabel(qOrganizationConfig.org_label);
    } else {
      setCurrentDatasetLabel('');
    }
  
    if (currentDataset !== pointsFileURL) {
      setCurrentDataset(pointsFileURL);
      fetch(pointsFileURL)
        .then((response) => response.text())
        .then((responseText) => {
          const data: any[] = parse(responseText, {
            header: true,
            skipEmptyLines: true,
          }).data;
          global.handleCustomLocationData(data);
          //processLocations(data);
        });
    }     
  }, [qccds]);

  useEffect(() => {
   
    if (global.customLocations && global.customLocations.length > 0) {
      handleNewLocs([...global.customLocations], 'csv');
    } else {
      setDataArray([]);
      setDataArray_unfiltered([]);
      setCurrentDatasetLabel('');
    }
    /*    if (!global.customLocations || global.customLocations.length === 0) {
          setCurrentDatasetLabel('');
        }*/
  }, [global.customLocations]);

  const handleNewLocs = (data: any, source: string) => {
    
    let newClimateLocations = processClimateSites(data, dataNOAAArray, data1960Array, selectedClimateCategory, yearFacilityDesign, yearInterest);
    processLocations(newClimateLocations, source);
  }

  const dataLoadedSwitch = (switchOff: boolean) => {
    setTimeout(() => {
      setIsDataReportLoaded(switchOff);
    }, 4444);
  }

  const [data1960Array, setData1960Array] = useState<any[]>([]);
  const [dataNOAAArray, setDataNOAAArray] = useState<any[]>([]);
  let rainfall1960 = [] as any;
  let rainfallNOAA = [] as any;
  useMemo(() => {
    const statesFileURL = "/climatesasb/1960S RAINFALL ZIP.csv";
    fetch(statesFileURL)
      .then((response) => response.text())
      .then((responseText) => {
        const data: any[] = parse(responseText, {
          header: true,
        }).data;
        setData1960Array(data);
      });
  }, []);

  useEffect(() => {
    const statesFileURL = "/climatesasb/NOAA_Zips.csv";
    fetch(statesFileURL)
      .then((response) => response.text())
      .then((responseText) => {
        const data: any[] = parse(responseText, {
          header: true,
        }).data;
        setDataNOAAArray(data)
      });
  }, []);

  let tableColumns_grid = [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: (props: any) => (
        <p style={{ minWidth: 190 }}>
          {props.value}
        </p>
      ),
    },
  ];

  if (reportTabs && reportTabs.length > 0) {
    for (let dataItem of reportTabs) {
      if (dataItem.title !== 'Overview' && dataItem.active === 'true') {
        tableColumns_grid.push({
          Header: dataItem.title,
          accessor: dataItem.title,
          Cell: (props: any) => (
            <span
              style={{ display: 'inline-flex', backgroundColor: props.value, color: props.value, width: '-webkit-fill-available' }}
            >
              {props.value}
            </span>
          ),
        });
      }
    }
  }

 

  /*   
     
    useMemo(() => {
        console.log('useMemo')
        if(reportTabs && reportTabs.length > 0){
          for (let dataItem of reportTabs) {
             if(dataItem.title!=='Overview'&&dataItem.active==='true'){
                  tableColumns_grid.push( {
                  Header: dataItem.title,
                  accessor: dataItem.title,                
                  Cell: (props: any) => (
                    <span
                      style={{display: 'inline-flex', backgroundColor: props.value, color: props.value,  width: '-webkit-fill-available'}}
                    >
                      {props.value}
                    </span>
                  ),
                });
              }  
          }  
        }  
    }, []);*/

  useEffect(() => {
    if (reportTabs) {
      const qrTabs = reportTabs.filter(x => x.active === 'true').length;
      setReportTabsActiveLength(qrTabs);
      sessionStorage.setItem("reportTabs", JSON.stringify(reportTabs));
    }
  }, [reportTabs]);

  useEffect(() => {
    agent.Reports.SurfaceWater('11111111').then(({ body }: any) => {
      if (body && body.length > 0) {
        setSurfaceWaterData(body);
        setSurfaceWaterDataEvaluation(body);
      }
    });
  }, []);

  useEffect(() => {
    let matchExpressionZip = ['interpolate', ['linear'], ['get', 'pointCategory']] as Expression; //selectedPointCategory
    matchExpressionZip = ['match', ['get', 'pointCategoryColor']] as Expression;
    matchExpressionZip.push(colorRed);
    matchExpressionZip.push(colorRed);
    matchExpressionZip.push(colorGreen);
    matchExpressionZip.push(colorGreen);
    matchExpressionZip.push(colorYellow);
    matchExpressionZip.push(colorYellow);
    matchExpressionZip.push('rgba(0,0,0,0)');

    setMapExpressionPoint(matchExpressionZip);
    updateLayerPoints(matchExpressionZip);

    //updateLayerPoints(mapExpressionPoint );
    if (global.customLocations && global.customLocations.length > 0) {
      handleNewLocs([...global.customLocations], 'global.customLocations');
      dataLoadedSwitch(true);
    }
  }, []);


  useEffect(() => {
    const qOrganizationConfig = OrganizationConfig.find(x => x.ccds === qccds);
   
    let ccds = "";
    if (qOrganizationConfig && qOrganizationConfig.ccds) {
      ccds = qOrganizationConfig.ccds;
    }
    

    let pointsFileURL = "";
    if (qOrganizationConfig) {
      pointsFileURL = qOrganizationConfig.csv;
    }
      
    if (!global.customLocations || global.customLocations.length < 1) {
      const clearRisk = localStorage.getItem('ra_clearRisk');
      const clearRiskFactor = '2'; //++ ra_clearRisk is for clearing cache, increment to clear

      if (clearRisk && clearRisk === clearRiskFactor && !ccds) {
        localForage.getItem('riska', function (err, value) {
          if (err) {
            console.log('lF: error', err)
          }
          //@ts-ignore
          if (value && !err && value.length > 0) {
            handleNewLocs(value, 'lf');
            dataLoadedSwitch(true);
            /*                setTimeout(() => {
                              processLocations(value, 'lf');
                            }, 11111);*/
          } else {
            loadCSV();
          }
        }).catch(function (err) {
          console.log('err',  err);
          loadCSV();
        });
      } else {

        if(!pointsFileURL || pointsFileURL.length<1){
          localForage.removeItem('riska').then(function () {
            console.log('riska cleared');
            localStorage.setItem("ra_clearRisk", clearRiskFactor);
            loadCSV();
          }).catch(function (err) {
            console.log('err', err);
            loadCSV();
          });         
        } else {
          dataLoadedSwitch(true);
        }
      }
    }
  }, []);

  const loadCSV = () => {
    let fipsFileURL = "/climatesasb/sasb_default_enc.csv"; //++++ test_geo
    if (currentDataset) {
      fipsFileURL = currentDataset;
    }

    fetch(fipsFileURL)
      .then((response) => response.text())
      .then((responseText) => {
        const data: any[] = parse(responseText, {
          header: true,
          skipEmptyLines: true,
        }).data;

        global.handleCustomLocationData(data)
        //processLocations(data, 'csv');
        dataLoadedSwitch(true);
      });
  }


  useEffect(() => {
    if (dataArray) {

      const newFeatureSet = newFeatureCollection([...dataArray]);
      setFeatureData(newFeatureSet);

      if (newFeatureSet.features.length > 0) {
        if (mapRef && mapRef.current) {
          const mapRef_ = mapRef;
          setTimeout(() => {
            fitBoundsMapData(newFeatureSet, mapRef_);
          }, 400);
        }
      }
    }
  }, [dataArray]);

  useEffect(() => {
    if (dataArray_unfiltered) {
      summarizeAssessments('dataArray_unfiltered');
      for (let dataItem of dataArray_unfiltered) {
        if (dataItem["queryapi"] || dataItem["queryreturn"]) {
          //console.log(dataItem["queryapi"], dataItem["queryreturn"], dataItem["queryev"])
        }
      }
      setTimeout(() => {
        localForage.setItem('riska', dataArray_unfiltered);
      }, 1400);
    }
  }, [dataArray_unfiltered]);

  const getHUC = (lat: any, lng: any, dataItem: any) => {
    if(lat && lng){
      return agent.Utilities.LatLngToHuc8(lat, lng)
        .then((res: any) => {
          const responseBody = res.body;
          if (responseBody && responseBody.length > 0 && responseBody[0].Huc8) {
            const responseHUC8 = responseBody[0].Huc8;
            dataItem.HUC8 = responseHUC8;
          }
        })
    }
  }

  useEffect(() => {
    if (filterCategory) {
      let filteredData = [...dataArray_unfiltered].filter((o: any) => o["pointCategoryColor"] === filterCategory);
      setDataArray(filteredData)
    } else {
      setDataArray(dataArray_unfiltered)
    }
  }, [filterCategory]);

  const checkLocations = (data: any) => {
    //console.log('checkLocations', data.length)
    let i = 0;
    for (let dataItem of dataArray_unfiltered) {
      dataItem.index = i;
      i++;
      for (let dataReport of reportTabs_) {
        if (dataReport.title !== 'Overview') {
          if (!dataItem[dataReport.title + 'result']) {
            if (!dataItem["HUC8"] && USA.indexOf(dataItem.Country) > -1) {

              getHUC(dataItem["Latitude"], dataItem["Longitude"], dataItem);
            }
            //RiskEvaluation(dataReport.title, dataItem);

            if (USA.indexOf(dataItem.Country) < 0) {
              if (internationalEval.indexOf(dataReport.title) > -1) {
                RiskEvaluation(dataReport.title, dataItem);
              }
            } else {
              RiskEvaluation(dataReport.title, dataItem);
            }
          }
        }
      }
    }

    setTimeout(() => {
      setDataArray([...data])
      setDataArray_unfiltered([...data])
    }, 1111);
  }

  const processLocations = (data: any, source: string) => {
    if (data && data.length > 0 && data[0]['<!DOCTYPE html>']) {
      return null;
    }

    let i = 0;
    for (let dataItem of data) {
      dataItem.index = i;
      i++;

      dataItem.name = dataItem["Location_Name [character varying]"];
      if (!dataItem["Location_Name [character varying]"]) {
        dataItem.name = dataItem["Location_Name"];
      }

      if (!dataItem["HUC8"] && USA.indexOf(dataItem.Country) > -1) {
        getHUC(dataItem["Latitude"], dataItem["Longitude"], dataItem);
      }
      if (!dataItem["pointCategory"]) {
        dataItem["pointCategory"] = +dataItem[selectedPointCategory];
      }
      if (dataItem["HUC8"] && dataItem["HUC8"].length === 7) {
        dataItem["HUC8"] = '0' + dataItem["HUC8"]
      }

      /*      dataItem["Longitude"] = dataItem["Longitude"].toFixed(8);
            dataItem["Latitude"] = dataItem["Latitude"].toFixed(8);*/

      dataItem["centroid_longitude"] = dataItem["Longitude"];
      dataItem["centroid_latitude"] = dataItem["Latitude"];

      if ((!dataItem["HUC12"] || !dataItem["HUC12NAME"]) && USA.indexOf(dataItem.Country) > -1) {

        getHUC12(dataItem);
      }

      let allCalls = [] as any;
      let j = 0;
      for (let dataReport of reportTabs_) {
        j++;
        if (dataReport.title !== 'Overview' && dataReport.title !== 'Site Surface Water Quality Threshold') {
          if (dataItem["Country"] !== 'US' && dataItem["Country"] !== 'U.S.') {
            if (internationalEval.indexOf(dataReport.title) > -1) {
              RiskEvaluation(dataReport.title, dataItem);
            }

          } else {
            //RiskEvaluation(dataReport.title, dataItem);
            //allCalls.push(RiskEvaluation)

            setTimeout(() => {
              RiskEvaluation(dataReport.title, dataItem);
              if (i === data.length && j === reportTabs_.length) {
                //console.log('processEnd', i, j)
              }
            }, i * 55);

          }

        } else if (dataReport.title === 'Site Surface Water Quality Threshold') {
          if (USA.indexOf(dataItem.Country) > -1) {
            if (i === 0) {
              RiskEvaluation(dataReport.title, dataItem);
            } else {
              setTimeout(() => {
                RiskEvaluation(dataReport.title, dataItem);
              }, i * 55);
            }
          }
        }
      }
      dataItem["RiskStatus"] = 'Success';

      //console.log(allCalls)
      if(source!=='importer'){ 
        if (dataItem[tabActive + 'result']) {
          dataItem["pointCat"] = dataItem[tabActive + 'result'];
          dataItem["pointCategoryColor"] = dataItem[tabActive];
          dataItem["pointObj"] = { result: dataItem[tabActive + 'result'], color: dataItem[tabActive] };
        }

        if (tabActive === 'Overview') {
          const tabDefault = reportTabs_[1].title;
          dataItem["pointCat"] = dataItem[tabDefault + 'result'];
          dataItem["pointCategoryColor"] = colorGreen;//dataItem[tabDefault];
          dataItem["pointObj"] = { result: dataItem[tabDefault + 'result'], color: colorGreen };
        }

      }
    }

   if(source!=='importer'){
        setTimeout(() => {
          setDataArray(data)
          setDataArray_unfiltered([...data])
        }, 4444);

        setTimeout(() => {
          checkLocations(data);
          //setDataArray(data)
          //setDataArray_unfiltered([...data])
        }, 8888);

        /*     setTimeout(() => {
              summarizeAssessments('process111111')
            }, 11111);*/     
    } else {
      setTimeout(() => {
          setUploaderLocations(data);
        }, 4444);
        
      setTimeout(() => {
          setUploaderLocations(data);
        }, 88888);

    }
  }

  const formatCalc = (sumWithInitial: number, w_all: any) => {
    return Math.round((sumWithInitial / w_all) * 100).toString() + '% (' + d3Format.format(',')(sumWithInitial) + ')';
  }

  const summarizeAssessments = (source?: string) => {
    //console.log('summarizeAssessments', source, dataArray_unfiltered, reportTabs.length)
    const data = [...dataArray_unfiltered];
    const reportTabsNew = [...reportTabs];
    const lengthData = data.length;

    let industryGrouping = getIndustryGrouping();
    let sData = [];

    for (let dataReport of reportTabsNew) {
      if (dataReport.title !== 'Overview') {


        let fData = data.filter((o: any) => o[dataReport.title] === colorGreen);
        let percent = '' + Math.round((fData.length / lengthData) * 100);
        dataReport["resultgreen"] = `${fData.length} sites (${percent}%)`;
        fData = data.filter((o: any) => o[dataReport.title] === colorYellow);
        percent = '' + Math.round((fData.length / lengthData) * 100);
        dataReport["resultyellow"] = `${fData.length} sites (${percent}%)`;
        fData = data.filter((o: any) => o[dataReport.title] === colorRed);
        percent = '' + Math.round((fData.length / lengthData) * 100);
        dataReport["resultred"] = `${fData.length} sites (${percent}%)`
        let redResult = fData.length.toString();

        if ((dataReport.title === 'Water Management' || dataReport.title === 'Ingredient Sourcing') && data && data.length > 0) {
          //_supfield1, _supfield2, _supfield3  wc, ww, wd

          fData = [...data];
          if (dataReport.title === 'Ingredient Sourcing' && analysisFields && analysisFields.length > 0) {
            dataReport["fieldsSelected" + dataReport.title] = checkIndustry(true);
            for (let dataR of data) {
              dataR['ra_' + dataReport.title + '_supfield1'] = dataR[analysisFields[0].title];
            }
            //+++
          }

          if (dataReport.title === 'Water Management' && analysisWaterUsage) {
            dataReport["fieldsSelected" + dataReport.title] = analysisWaterUsage;
          }



          let fieldsSelected = ['ra_' + dataReport.title + '_supfield1', 'ra_' + dataReport.title + '_supfield2', 'ra_' + dataReport.title + '_supfield3'];

          dataReport["fieldsSelected"] = (dataReport.title === 'Water Management') ? analysisWaterUsage : analysisFields; //areportTabs;


          let sumWithInitial = data.map(i => i[fieldsSelected[0]]).reduce((a: any, b: any) => +a + +b);
          let sumWithInitial2 = data.map(i => i[fieldsSelected[1]]).reduce((a: any, b: any) => +a + +b);
          let sumWithInitial3 = data.map(i => i[fieldsSelected[2]]).reduce((a: any, b: any) => +a + +b);

          let w_all = [sumWithInitial, sumWithInitial2, sumWithInitial3];
          dataReport["resultcalc"] = [formatCalc(sumWithInitial, w_all[0]), formatCalc(sumWithInitial2, w_all[1]), formatCalc(sumWithInitial3, w_all[2])];
          //console.log(dataReport.title, w_all, data)
          fData = [...data].filter((o: any) => o[dataReport.title] === colorGreen);
          if (fData && fData.length > 0) {
            sumWithInitial = fData.map(i => i["ra_" + dataReport.title + '_supfield1']).reduce((a: any, b: any) => +a + +b);
            sumWithInitial2 = fData.map(i => i["ra_" + dataReport.title + '_supfield2']).reduce((a: any, b: any) => +a + +b);
            sumWithInitial3 = fData.map(i => i["ra_" + dataReport.title + '_supfield3']).reduce((a: any, b: any) => +a + +b);
            dataReport["resultgreencalc"] = [formatCalc(sumWithInitial, w_all[0]), formatCalc(sumWithInitial2, w_all[1]), formatCalc(sumWithInitial3, w_all[2])];
          } else {
            dataReport["resultgreencalc"] = [formatCalc(0, w_all[0]), formatCalc(0, w_all[1]), formatCalc(0, w_all[2])];
            sumWithInitial = 0; sumWithInitial2 = 0; sumWithInitial3 = 0;
          }

          fData = [...data].filter((o: any) => o[dataReport.title] === colorYellow);
          if (fData && fData.length > 0) {
            sumWithInitial = fData.map(i => i["ra_" + dataReport.title + '_supfield1']).reduce((a: any, b: any) => +a + +b);
            sumWithInitial2 = fData.map(i => i["ra_" + dataReport.title + '_supfield2']).reduce((a: any, b: any) => +a + +b);
            sumWithInitial3 = fData.map(i => i["ra_" + dataReport.title + '_supfield3']).reduce((a: any, b: any) => +a + +b);
            dataReport["resultyellowcalc"] = [formatCalc(sumWithInitial, w_all[0]), formatCalc(sumWithInitial2, w_all[1]), formatCalc(sumWithInitial3, w_all[2])];
          } else {
            dataReport["resultyellowcalc"] = [formatCalc(0, w_all[0]), formatCalc(0, w_all[1]), formatCalc(0, w_all[2])];
            sumWithInitial = 0; sumWithInitial2 = 0; sumWithInitial3 = 0;
          }

          fData = [...data].filter((o: any) => o[dataReport.title] === colorRed);
          if (fData && fData.length > 0) {
            sumWithInitial = fData.map(i => i["ra_" + dataReport.title + '_supfield1']).reduce((a: any, b: any) => +a + +b);
            sumWithInitial2 = fData.map(i => i["ra_" + dataReport.title + '_supfield2']).reduce((a: any, b: any) => +a + +b);
            sumWithInitial3 = fData.map(i => i["ra_" + dataReport.title + '_supfield3']).reduce((a: any, b: any) => +a + +b);
            dataReport["resultredcalc"] = [formatCalc(sumWithInitial, w_all[0]), formatCalc(sumWithInitial2, w_all[1]), formatCalc(sumWithInitial3, w_all[2])];
          } else {
            dataReport["resultredcalc"] = [formatCalc(0, w_all[0]), formatCalc(0, w_all[1]), formatCalc(0, w_all[2])];
            sumWithInitial = 0; sumWithInitial2 = 0; sumWithInitial3 = 0;
          }
          dataReport["resultredcalctop"] = [Math.round((sumWithInitial / w_all[0]) * 100).toString() + '%', Math.round((sumWithInitial2 / w_all[1]) * 100).toString() + '%', Math.round((sumWithInitial3 / w_all[2]) * 100).toString() + '%']

          dataReport["resultredcalc" + dataReport.title] = dataReport["resultredcalc"];
          dataReport["resultredcalctop" + dataReport.title] = dataReport["resultredcalctop"];

          if (dataReport.title === 'Water Management') {
            redResult = '1. Water Withdrawn\r\n    ' + dataReport["resultredcalc"][1] + ' m³\r\n\r\n2. Water Consumed\r\n    ' + dataReport["resultredcalc"][0] + ' m³\r\n\r\n';
          } else {
            redResult = dataReport["resultredcalc"][0];
          }

        }


        //@ts-ignore
        let cCat = industryGrouping && industryGrouping.sasbcategory.find(x => x.title === dataReport.title);
        if (cCat) {
          dataReport["sasbrefbool"] = true;
          //@ts-ignore
          //console.log('cCat', cCat, industryGrouping, dataReport ) ///, cCat.sasbrefid.replace('.', ''),/.replace('characterToReplace', '');
          //@ts-ignore
          sData.push({ 'sasbrefid': cCat.sasbrefid, 'category': dataReport.title, 'metriclabel': industryGrouping['s' + cCat.sasbrefid.replace('.', '') + '_label'], 'section': cCat.sasbsection, 'sasbreference': cCat.sasblabel, 'metric': industryGrouping['s' + cCat.sasbrefid.replace('.', '')], 'disclosure': redResult, 'disclosurelabel': redResult + ' ' + industryGrouping['s' + cCat.sasbrefid.replace('.', '') + '_unit'], 'disclosureunit': industryGrouping['s' + cCat.sasbrefid.replace('.', '') + '_unit'], 'titlesection': dataReport.title })
        } else {
          //dataReport["sasbrefid"] = "";
          dataReport["sasbrefbool"] = false;
        }

      }
    }
    setReportTabs(reportTabsNew)
    setSasbData(sData)
  }

  const colorScalePoints = (evalValue: number) => {
    const colorValue = d3ScaleChromatic.interpolateRdYlGn(evalValue);
    return colorValue;
  }



  const newFeatureCollection = (fData: any) => {
    const features: Array<Feature<Point, GeoJsonProperties>> = [];
    for (let dataItem of fData) {
      if (dataItem) {
        let huc12Item = dataItem;
        if (huc12Item) {
          const featureWithPoint: Feature<Point> = {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [+huc12Item.Longitude, +huc12Item.Latitude],
            },
            properties: dataItem,
          };
          if (featureWithPoint && featureWithPoint.properties) {
            featureWithPoint.properties.lat = huc12Item.Latitude;
            featureWithPoint.properties.lng = huc12Item.Longitude;
          }
          features.push(featureWithPoint);
        }
      }
    }
    const featureCollectionFromReportData: FeatureCollection = {
      type: 'FeatureCollection',
      features: features,
    };
    return featureCollectionFromReportData;
  };


  const updateLayerPoints = (mapExp: any) => {
    const newLayerStyle: LayerProps = {
      id: 'pointlayer',
      type: 'circle' as const,
      layout: { 'visibility': 'visible' },
      paint: {
        'circle-radius': 10,
        'circle-color': mapExp,
        'circle-stroke-color': 'white',
        'circle-stroke-width': 1,
        'circle-opacity': 0.8,
      },
    }
    setLayerStylePoint(newLayerStyle);
  }

  const hoverLayerStyle: LayerProps = {
    id: 'hoverlayer',
    type: 'circle' as const,
    paint: {
      'circle-radius': 12,
      'circle-color': '#faebd7',
      'circle-stroke-color': 'white',
      'circle-stroke-width': 2,
      'circle-opacity': 0.7,
    },
  };

  const populatePopup = (event: any | null) => {
    let newPopup = event.features[0].properties;
    let newLocation = event.features[0].properties[locationField];
    let newHover = event.features[0].properties[locationField];
    setCursor('pointer');
    newPopup.centroid_longitude = event.lngLat.lng;
    newPopup.centroid_latitude = event.lngLat.lat;
    setPopupInfo(newPopup);
  }


  useEffect(() => {
    if (usLocations.length > 0) {
      if (userSelectedLocations.indexOf(usLocations[0][locationField]) > -1) {
        setPopupInfo(null);
        setUserSelectedLocations([]);
        setMapHoverFilter(['in', ['get', locationField], '']);
      } else {
        setUserSelectedLocations(usLocations[0][locationField]);
        /*        setPopupInfo(usLocations[0]);
                setTimeout(() => {
                  setMapHoverFilter(['in', ['get', locationField], usLocations[0][locationField]]);
                }, 111);*/
      }
    }
  }, [usLocations]);

  const onHandleClick = useCallback((event: any | null) => {
    const feature = event.features && event.features[0];
    if (feature) {
      setUserSelectedLocation(event.features[0].properties[locationField]);
      setUsLocations([event.features[0].properties]);

      if (mapRef.current) {
        if (event && event.lngLat) {
          mapRef.current.flyTo({
            center: [
              parseFloat(event.lngLat.lng),
              parseFloat(event.lngLat.lat),
            ],
          });
        }
      }
    }
  }, []);

  const onMouseEnter = useCallback((event: any | null) => {
    const feature = event.features && event.features[0];
    if (feature) {
      populatePopup(event)
    }

    if (event.features && event.features[0] && event.features[0].properties) {
      setCursor('pointer');
      setPopupInfo(event.features[0].properties);
      setUserSelectedLocation(event.features[0].properties.name);
      setMapHoverFilter(['in', ['get', 'name'], event.features[0].properties.name]);
    }
  }, []);

  const onMouseLeave = useCallback((event: any | null) => {
    setCursor('');
    setPopupInfo(null);
    setMapHoverFilter(['in', ['get', 'name'], '']);
    if (userSelectedLocation) {
      setUserSelectedLocation(null);
    }
  }, []);

  const onMapLoad = useCallback(() => {
    if (mapRef && mapRef.current) {
      setMapLoaded(true);
      mapRef.current.resize();
    }
  }, []);


  const clickRowHandler = (event: any) => {
    if (userSelectedLocations && userSelectedLocations.indexOf(event[locationField]) > -1) {
      setUserSelectedLocations([])

      setPopupInfo(null);
      setMapHoverFilter(['in', ['get', locationField], '']);
    } else {
      setUserSelectedLocations([event[locationField]])
      setMapHoverFilter(['in', ['get', locationField], event[locationField]])

      setPopupInfo(event);

      /*    if (mapRef.current) {
            if (event && event.centroid_longitude) {
              mapRef.current.flyTo({
                center: [
                  parseFloat(event.centroid_longitude),
                  parseFloat(event.centroid_latitude),
                ],
              });
            }
          }*/
    }
  };


  const renderPopup = () => {
    return (
      <Popup
        longitude={Number(popupInfo.centroid_longitude)}
        latitude={Number(popupInfo.centroid_latitude)}
        onClose={() => setPopupInfo(null)}
      >
        <div className="popup-container">
          <h2><p className="popup-container-location" style={{ maxWidth: 300, padding: 2, fontSize: '1.4rem' }}>{popupInfo["name"]}</p></h2>
          <div className="popup-content-row">
            <span className="popup-content-label">City</span>
            <span className="popup-content-value">{popupInfo["City"]}</span>
          </div>
          <div className="popup-content-row">
            <span className="popup-content-label">Assessment</span>
            <span className="popup-content-value">{popupInfo["pointCat"]}</span>
          </div>
        </div>
      </Popup>
    )
  }


  let tableColumns = [
    { Header: 'Name', accessor: 'name' },
    { Header: 'Location', accessor: 'Location_Address' },
    { Header: 'City', accessor: 'City' },
    { Header: 'State', accessor: 'State' },
    {
      Header: 'Assessment',
      accessor: 'pointCat',
      Cell: (props: any) => (
        <span style={{ color: `${props.row.original.pointCategoryColor}` }}>
          {props.value}
        </span>
      ),
    },
    { Header: assessmentField, accessor: "ra_supfield1" },
    { Header: '  ', accessor: "ra_supfield2" },
    {
      Header: '  ',
      accessor: 'pointCategoryColor',
      Cell: (props: any) => (
        <p style={{ backgroundColor: props.value, color: props.value }}>
          {props.value}
        </p>
      ),
    },
  ];

  //++
  if (tabActive === 'Water Management' || tabActive === 'Water Consumption' || tabActive === 'Water Manaement') {

    tableColumns = [
      { Header: 'Name', accessor: 'name' },
      { Header: 'Location', accessor: 'Location_Address' },
      { Header: 'City', accessor: 'City' },
      { Header: 'State', accessor: 'State' },
      {
        Header: 'Assessment',
        accessor: 'pointCat',
        Cell: (props: any) => (
          <span style={{ color: `${props.row.original.pointCategoryColor}` }}>
            {props.value}
          </span>
        ),
      },
      { Header: `Water Consumed (m³)`, accessor: "ra_" + tabActive + '_supfield1' },
      { Header: `Water Withdrawn (m³)`, accessor: "ra_" + tabActive + '_supfield2' },
      { Header: 'Water Discharge (m³)', accessor: "ra_" + tabActive + '_supfield3' },
      {
        Header: '  ',
        accessor: 'pointCategoryColor',
        Cell: (props: any) => (
          <p style={{ backgroundColor: props.value, color: props.value }}>
            {props.value}
          </p>
        ),
      },
    ];

  } else if (tabActive === 'Ingredient Sourcing') {

    tableColumns = [
      { Header: 'Name', accessor: 'name' },
      { Header: 'Location', accessor: 'Location_Address' },
      { Header: 'City', accessor: 'City' },
      { Header: 'State', accessor: 'State' },
      {
        Header: 'Assessment',
        accessor: 'pointCat',
        Cell: (props: any) => (
          <span style={{ color: `${props.row.original.pointCategoryColor}` }}>
            {props.value}
          </span>
        ),
      },
      { Header: assessmentField + ' (' + analysisUnit + ')', accessor: "ra_" + tabActive + '_supfield1' },
      {
        Header: '  ',
        accessor: 'pointCategoryColor',
        Cell: (props: any) => (
          <p style={{ backgroundColor: props.value, color: props.value }}>
            {props.value}
          </p>
        ),
      },
    ];

  }





  const setMapLayers = (e: any) => {
    let layersArr = ['setAqueductAtlasWaterStress', 'setAqueductAtlasCoastalFlood', 'setAqueductAtlasRiverine', 'setDroughtMonitoringSelected', 'setFemaZones', 'setWDPA', 'setAllFireData'];
    let trueLayer = '';

    if (e === 'Water Management' || e === 'Ingredient Sourcing' || e === 'Site Water Security / Distress') {
      trueLayer = 'setAAV4WaterStress'//setAqueductAtlasWaterStress';
      global[trueLayer](true);
    } else if (e === 'Drought Status') {
      trueLayer = 'setDroughtMonitoringSelected';
      global[trueLayer](true);
    } else if (e === 'Riverine Flood Risk') {
      trueLayer = 'setAAV4CoastalFloodRisk' //setAqueductAtlasRiverine';
      global[trueLayer](true);
    } else if (e === 'Coastal Flood Risk') {
      trueLayer = 'setAAV4RiverineFloodRisk'//setAqueductAtlasCoastalFlood';
      global[trueLayer](true);
    } else if (e === 'Site Flood Zone Ratings') {
      trueLayer = 'setFemaZones';
      global[trueLayer](true);
    } else if (e === 'Located within a Protected Area') {
      trueLayer = 'setWDPA';
      global[trueLayer](true);
    } else if (e === 'Wildfire') {
      trueLayer = 'setWildFireBoundaries';
      global[trueLayer](true);
    }

    for (let dataItem of layersArr) {
      if (dataItem !== trueLayer) {
        global[dataItem](false);
      }
    }
  }

  useEffect(() => {

    tableColumns = [
      { Header: 'Name', accessor: 'name' },
      { Header: 'Location', accessor: 'Location_Address' },
      { Header: 'City', accessor: 'City' },
      { Header: 'State', accessor: 'State' },
      {
        Header: 'Assessment',
        accessor: 'pointCat',
        Cell: (props: any) => (
          <span style={{ color: `${props.row.original.pointCategoryColor}` }}>
            {props.value}
          </span>
        ),
      },
      { Header: assessmentField, accessor: "ra_supfield1" },
      { Header: '  ', accessor: "ra_supfield2" },
      {
        Header: '  ',
        accessor: 'pointCategoryColor',
        Cell: (props: any) => (
          <p style={{ backgroundColor: props.value, color: props.value }}>
            {props.value}
          </p>
        ),
      },
    ];
  }, [assessmentField]);


  const checkFields = (e: any) => {
    let iSourcing = checkIndustry() || [{ active: 'true', title: 'Agriculture Products' }];
    if (e === 'Water Management') {
      setAnalysisFields([{ active: 'true', title: 'Consumed' }, { active: 'true', title: 'Withdrawn' }, { active: 'true', title: 'Discharged' }]);
    } else if (e === 'Ingredient Sourcing') {
      //@ts-ignore
      setAnalysisFields(iSourcing);
      if (iSourcing && iSourcing[0]) {
        setAssessmentField(iSourcing[0].title);
      } else {
        setAssessmentField('');
      }
    };
  }

  const onSelectTabs = (e: any) => {

    if (e === 'Water Management' || e === 'Ingredient Sourcing') {
      checkFields(e);
    } else {
      let categoryConfig = [...reportTabs].find(x => x.title === e);
      if (categoryConfig && categoryConfig.assessmentfield) {
        setAssessmentField(categoryConfig.assessmentfield)
      } else {
        setAssessmentField('')
      }
    }


    if (e === 'Overview') {
      setTabOverview(true)
    } else {
      setTabOverview(false)
    }
    let data = [...dataArray_unfiltered]
    setTabActive(e);
    setFilterCategory('');
    //processLocations(data, 'ost'); 

    let i = 0;
    for (let dataItem of data) {
      dataItem.index = i;
      i++;
      for (let dataReport of reportTabs_) {
        if (dataReport.title !== 'Overview') {
          if (!dataItem[dataReport.title + 'result']) {
            if (!dataItem["HUC8"]) {
              if (dataItem["HUC12"]) {
                dataItem["HUC8"] = dataItem["HUC12"].substr(0, 8);
              } else {
                getHUC(dataItem["Latitude"], dataItem["Longitude"], dataItem);
              }
            }
            RiskEvaluation(dataReport.title, dataItem);
          }
        }
      }
    }

    for (let dataItem of data) {
      dataItem["pointCat"] = dataItem[e + 'result'];
      dataItem["pointCategoryColor"] = dataItem[e];
      dataItem["pointObj"] = dataItem[e + 'result'];
      dataItem["ra_supfield1"] = dataItem['ra_' + e + '_supfield1'];
      dataItem["ra_supfield2"] = dataItem['ra_' + e + '_supfield2'];
      if (e === 'Overview') {
        const tabDefault = reportTabs_[1].title;
        dataItem["Overviewresult"] = dataItem[tabDefault + 'result'];
        dataItem["Overview"] = dataItem[tabDefault];
        dataItem["pointCat"] = dataItem[tabDefault + 'result'];
        dataItem["pointCategoryColor"] = dataItem[tabDefault];
        dataItem["pointObj"] = { result: dataItem[tabDefault + 'result'], color: dataItem[tabDefault] };
      }
    }
    setDataArray(data)
    setDataArray_unfiltered([...data])

    setMapLayers(e);
  }


  const onClickUpload = (e: any) => {
    interface data {
      data?: any
      errors?: any;
    }
    if (e.target.form && e.target.form[0].files && e.target.form[0].files[0]) {
      let file = e.target.form[0].files[0];
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onloadend = () => {
        var data = parse(reader.result as string, {
          header: true,
          skipEmptyLines: true,
        });
        if (data.errors && data.errors.length > 0) {
          global.notify('Some errors during import.');
          console.log(data.errors)
       
        }

        if (data.data && data.data.length > 0) {
          //@ts-ignore
          /* if(!data.data[0]["Location_Name"]){
             global.notify('Missing Location_Name field.  Please check and verify csv file.');
           } else {
             global.handleCustomLocationData(data.data)
             statusMessage = 'Locations uploaded: ' + data.data.length;
           }*/
        }
      };
    }
  }

  const AlertUploader = () => {
    if (alertUploaderShow) {
      return (
        <Alert variant={alertUploaderMessage.variant || 'success'} onClose={() => setAlertUploaderShow(false)} dismissible>
          <Alert.Heading>{alertUploaderMessage.title}</Alert.Heading>
          <p>
            {alertUploaderMessage.message} 
          </p>
        </Alert>
      );
    };
    return <span></span>;
  }

    const updateImportData = (id:any, field: any, fieldvalue:any) => {
/*        let initialData = [...importData];
        let filterData = initialData.filter((o: any) => (o["ImportID"] === id) ); 
        if(filterData && filterData.length > 0){
            filterData[0][field] = fieldvalue;
            setImportData(initialData);
        }*/
    }

    const handleActive= (props: any) => {
/*         if(props && props.row && props.row.values && props.row.values.ImportID){
             let filterData = importData.filter((o: any) => (o["ImportID"] === props.row.values.ImportID) ); 
             if(filterData && filterData.length > 0){
                filterData[0]["includeInImport"] = !filterData[0]["includeInImport"];
                setImportData(importData);
             }
         }*/
    }


    const tableColumnsUploader = [
            {
                Header: 'Import Status',
                accessor: 'ImportStatus',
                Cell: (props: any) => (
                    <div style={(props.value === 'Error') ? {backgroundColor: 'red', color:'red', width: 50} : {backgroundColor: 'green', color:'green', width: 50}}>                      
                          {props.value}                           
                    </div>
                ),
            },
            {
                Header: 'Import Errors',
                accessor: 'ImportErrors',

            },
            {
                Header: 'Name',
                accessor: 'Location_Name',
            },
            {
                Header: 'Address',
                accessor: 'Location_Address',
            },
            {
                Header: 'City',
                accessor: 'City',
            },
            {
                Header: 'State',
                accessor: 'State',
            },
            {
                Header: 'Zip',
                accessor: 'Zip_Code',
            },
            {
                Header: 'Country',
                accessor: 'Country',
            },
            {
                Header: 'Latitude',
                accessor: 'Latitude',
            },
            {
                Header: 'Longitude',
                accessor: 'Longitude',
            }
        ];

/*            {
                Header: 'Include In Import',
                accessor: 'includeInImport',
                Cell: (props: any) => (
                    <div className="action-box">
                        <Form.Check
                            defaultChecked={true}
                            onChange={() => {
                                handleActive(props)            
                            }}
                        />
                    </div>
                ),
            },*/

        const tableColumnsUploaderStep = [{
                Header: 'Import Status',
                accessor: 'ImportStatus',
                Cell: (props: any) => (
                    <div style={(props.value === 'Error') ? {backgroundColor: 'red', color:'red', width: 50} : {backgroundColor: 'green', color:'green', width: 50}}>                      
                          {props.value}                           
                    </div>
                ),
            },
            {
                Header: 'Import Errors',
                accessor: 'ImportErrors',

            },
            {
                Header: 'Name',
                accessor: 'Location_Name',
            },
            {
                Header: 'Address',
                accessor: 'Location_Address',
            },
            {
                Header: 'City',
                accessor: 'City',
            },
            {
                Header: 'State',
                accessor: 'State',
            },
            {
                Header: 'Zip',
                accessor: 'Zip_Code',
            },
            {
                Header: 'Country',
                accessor: 'Country',
            },
            {
                Header: 'Latitude',
                accessor: 'Latitude',
            },
            {
                Header: 'Longitude',
                accessor: 'Longitude',
            },
            {
                Header: 'HUC8',
                accessor: 'HUC8',
            },
            {
                Header: 'HUC12',
                accessor: 'HUC12',
            },
            {
                Header: 'HUC12 name',
                accessor: 'HUC12NAME',
            }
        ];

        let tableColumnsUploaderStepEval = [
            {
                Header: 'Import Status',
                accessor: 'ImportStatus',
                Cell: (props: any) => (
                    <div style={(props.value === 'Error') ? {backgroundColor: 'red', color:'red'} : {backgroundColor: 'green', color:'green'}}>                      
                          {props.value}                           
                    </div>
                ),
            },
            {
                Header: 'Import Errors',
                accessor: 'ImportErrors',

            },
            {
                Header: 'Name',
                accessor: 'Location_Name',
            },
            {
                Header: 'Address',
                accessor: 'Location_Address',
            },
            {
                Header: 'City',
                accessor: 'City',
            },
            {
                Header: 'Country',
                accessor: 'Country',
            },

            {
                Header: 'Risk Assessment',
                accessor: 'RiskStatus',
                Cell: (props: any) => (
                    <div style={(props.value === 'Error') ? {backgroundColor: 'red', color:'red'} : {backgroundColor: 'green', color:'green'}}>                      
                          {props.value}                           
                    </div>
                ),
            }
        ];




    useMemo(() => {

      tableColumnsUploaderStepEval = [
            {
                Header: 'Import Status',
                accessor: 'ImportStatus',
                Cell: (props: any) => (
                    <div style={(props.value === 'Error') ? {backgroundColor: 'red', color:'red'} : {backgroundColor: 'green', color:'green'}}>                      
                          {props.value}                           
                    </div>
                ),
            },
            {
                Header: 'Import Errors',
                accessor: 'ImportErrors',

            },
            {
                Header: 'Name',
                accessor: 'Location_Name',
            },
            {
                Header: 'Address',
                accessor: 'Location_Address',
            },
            {
                Header: 'City',
                accessor: 'City',
            },
            {
                Header: 'Country',
                accessor: 'Country',
            },

            {
                Header: 'Risk Assessment',
                accessor: 'RiskStatus',
                Cell: (props: any) => (
                    <div style={(props.value === 'Error') ? {backgroundColor: 'red', color:'red'} : {backgroundColor: 'green', color:'green'}}>                      
                          {props.value}                           
                    </div>
                ),
            }
        ];

      let j = 0;
      for (let dataReport of reportTabs_) {
        j++;
        if (dataReport.title !== 'Overview' && dataReport.active) {
          tableColumnsUploaderStepEval.push( {
                Header: dataReport.title,
                accessor: dataReport.title,
                Cell: (props: any) => (
                  <p style={{ backgroundColor: props.value, color: props.value }}>
                    {props.value}
                  </p>
                ),                
              });
        }
      }
    }, [reportTabs_]);
    
    interface placeGeo {
      locality?: string;
      Location_Name?: string;
      Name?: string;
      Location_Address?: string;
      Address?: string;
      City?: string;
      State?: string;
      City_sc?: string;
      State_sc?: string;
      Distric?: string;
      Country?: string;
      Country_sc?: string;
      Zip_Code?: string;
      postcode?: string;
      postcode_placename ?: string;
      neighborhood?: string;
      fulladdress?: string;
      addressaccuracy?: string;
    }     

    async function onProcess_s(dataItem: any){

        let objItem = Object.assign({}, dataItem);
        if(dataItem["ImportErrors"] && dataItem["ImportErrors"]!=='-'){
          dataItem["ImportStatus"] = 'Error';
          return dataItem;
        }

     
        dataItem["ImportErrors"] = '-';
        if(!dataItem["Year_Built"]){
          dataItem["Year_Built"] = 1970 + Math.floor(Math.random() * 53) + 1;
        }
        if(!dataItem["Future Year of Interest"] ){
          dataItem["Future Year of Interest"] = 2040;
        }                          

        if(!dataItem['Address'] && dataItem['Location_Address']){
          dataItem['Address'] = dataItem['Location_Address'];
        }  
   
        if(dataItem['Address'] && !dataItem['Location_Address']){
          dataItem['Location_Address'] =  dataItem['Address'];
        }                           

        if(!dataItem['Name'] && dataItem['Location_Name']){
          dataItem['Name'] = dataItem['Location_Name'];
        }  
   
        if(dataItem['Name'] && !dataItem['Location_Name']){
          dataItem['Location_Name'] =  dataItem['Name'];
        }   

        let pathFlag = false;
        let usArray = ['United States', 'USA', 'US'];




          if(uploaderGeocode){//@ts-ignore
            if(!dataItem['Latitude']||!dataItem['Longitude']){//@ts-ignore
              pathFlag = true;
              //geocode(dataItem); 
              
              dataItem = await geocode(dataItem); 
             
           }
         }

         if(uploaderRevGeocode){//@ts-ignore
           if((!dataItem['Address']||!dataItem['Country']) && dataItem['Latitude']  && dataItem['Longitude']){
              pathFlag = true;

              dataItem =  await localForage.getItem("ra_placegeo_" + dataItem["Longitude"] + dataItem["Latitude"], function(err, value) {
        
                if(value){
                
                  let merged = {...dataItem, ...value};
                  return merged;
                } else { 
                 
                  dataItem = reverseGeocode(dataItem);
                }
              }).then(data => {
                     //@ts-ignore
                  let merged = {...dataItem, ...data};
                  return merged;
              })  
        
              //reverseGeocode(dataItem)    
              //return reverseGeocode(dataItem);
      
           }
         }

         if(!dataItem["HUC12"] || !dataItem["HUC8"]){
           if(USA.indexOf(dataItem["Country"])>-1)
           dataItem = await getHUC12a(dataItem["Latitude"], dataItem["Longitude"], dataItem)
            
         }


         return dataItem;
  }




    async function reverseGeocode(dataItem: any){ 


                const input = `${dataItem["Longitude"]},${dataItem["Latitude"]}`
                let URL = `https://api.mapbox.com/geocoding/v5/mapbox.places/${input}.json?access_token=${settings.maboxKey}`;
           
                return await axios.get(URL)
                     .then((response) => {


                       if(response.status === 200 && response.data && response.data.features && response.data.features[0] && response.data.features[0].center){
                            let cacheObj = {} as placeGeo; 

                            let nameFlag = false;
                            if(!dataItem["Location_Name"] && uploaderName){
                              nameFlag = true;
                            }
                          
                            let matching = response.data.features.find(
                               (name:any ) => name.place_type.indexOf('locality')>-1 
                            );
                            if(matching){
                                if(nameFlag){
                                  dataItem["Location_Name"] = matching.text
                                }
                                cacheObj["Location_Name"] = matching.text
                                cacheObj["locality"] = matching.text
                                dataItem["locality"] = matching.text
                            }
                            matching = response.data.features.find(
                               (name:any ) => name.place_type.indexOf('poi')>-1 
                            );
                            if(matching){
                              dataItem["Location_Address"] = `${(matching.properties.address) ? matching.properties.address + ' ' : ''}`
                              cacheObj["Location_Address"] =  dataItem["Location_Address"]
                                //dataItem["Country"] = matching.text
                                //dataItem["Country_sc"] = matching.properties.short_code;
                            }
                            matching = response.data.features.find(
                               (name:any ) => name.place_type.indexOf('country')>-1 
                            );
                            if(matching){
                                dataItem["Country"] = matching.text
                                dataItem["Country_sc"] = matching.properties.short_code;
                                cacheObj["Country"] = matching.text
                                cacheObj["Country_sc"] = matching.properties.short_code;
                            }
                            matching = response.data.features.find(
                               (name:any ) => name.place_type.indexOf('region')>-1 
                            );
                            if(matching){
                                dataItem["State"] = matching.text
                                dataItem["State_sc"] = matching.properties.short_code;
                                cacheObj["State"] = matching.text
                                cacheObj["State_sc"] = matching.properties.short_code;
                            }

                            matching = response.data.features.find(
                               (name:any ) => name.place_type.indexOf('distric')>-1 
                            );
                            if(matching){
                                dataItem["Distric"] = matching.text
                                cacheObj["Distric"] = matching.text
                                //dataItem["Distric_sc"] = matching.properties.short_code;
                            }
                            
                            matching = response.data.features.find(
                               (name:any ) => name.place_type.indexOf('place')>-1 
                            );
                            if(matching){
                                dataItem["City"] = matching.text;
                                cacheObj["City"] = matching.text;
                                cacheObj["Location_Name"] = matching.place_name
                                if(nameFlag){
                                  dataItem["Location_Name"] = matching.place_name
                                }
                            }
                            matching = response.data.features.find(
                               (name:any ) => name.place_type.indexOf('postcode')>-1 
                            );
                            if(matching){
                                dataItem["Zip_Code"] = matching.text
                                dataItem["postcode"] = matching.text
                                dataItem["postcode_placename"] = matching.place_name
                                cacheObj["Zip_Code"] = matching.text
                                cacheObj["postcode"] = matching.text
                                cacheObj["postcode_placename"] = matching.place_name
                            }

                            matching = response.data.features.find(
                               (name:any ) => name.place_type.indexOf('neighborhood')>-1 
                            );
                            if(matching){
                                dataItem["neighborhood"] = matching.text
                                cacheObj["neighborhood"] = matching.text
                            }

                            matching = response.data.features.find(
                               (name:any ) => name.place_type.indexOf('address')>-1 
                            );
                            if(matching){
                                if(nameFlag){
                                     dataItem["Location_Address"] = `${(matching.address) ? matching.address + ' ' : ''}${matching.text}`
                                }

                                dataItem["fulladdress"] = matching.place_name
                                dataItem["addressaccuracy"] = matching.properties.accuracy
                                cacheObj["fulladdress"] = matching.place_name
                                cacheObj["addressaccuracy"] = matching.properties.accuracy
                                cacheObj["Location_Address"] = `${(matching.address) ? matching.address + ' ' : ''}${matching.text}`
                            }        

                            if(matching && matching.text && dataItem["postcode_placename"] && nameFlag){
                                dataItem["Location_Name"] = `${matching.text}, ${dataItem["postcode_placename"]}`
                                cacheObj["Location_Name"] = `${matching.text}, ${dataItem["postcode_placename"]}`
                            }
                            
                            dataItem["ImportStatus"] = '';
                            //setLocationAdd(dataItem);
                            setStore('ra_placegeo_' + dataItem["Longitude"] + dataItem["Latitude"], cacheObj);
                            
                            return dataItem;
                      } else {
                           dataItem["ImportStatus"] = 'Error';
                      }
                      //setLocationAdd(dataItem);
                      return dataItem;
                 });   

    }


    async function getHUC8(lat: any, lng: any, dataItem: any){
      if(lat && lng){
        return agent.Utilities.LatLngToHuc8(lat, lng)
          .then((res: any) => {
            const responseBody = res.body;
            if (responseBody && responseBody.length > 0 && responseBody[0].Huc8) {
              const responseHUC8 = responseBody[0].Huc8;
              dataItem.HUC8 = responseHUC8;
            }
          })
      }
    }

    async function getHUC12a(lat: any, lng: any, dataItem: any){
   
      if(lat && lng && dataItem){
 
        //if(!dataItem["HUC12"]){
          return await localForage.getItem("ra_" + 'HUC12OBJ' + '_' + dataItem["Longitude"] + dataItem["Latitude"], function(err, value) {
            if(err){
              console.log(err)
            }

            if(value){
              
              //@ts-ignore
              const huc = JSON.parse(value);
              dataItem["HUC12"] = huc["HUC12"];
              dataItem["HUC12NAME"] = huc["HUC12NAME"];
              if(!dataItem["HUC8"]){
                dataItem["HUC8"] = dataItem["HUC12"].substr(0,8);
              }
              return dataItem;

            } else {
               
                const  URL = `https://api.mapbox.com/v4/${settings.tileSetURLs.HUC12.substr(9)}/tilequery/${dataItem["Longitude"]},${dataItem["Latitude"]}.json?access_token=${settings.maboxKey}`;
                axios.get(URL)
                  .then((response) => {
                    if(response.status === 200 && response.data && response.data){
                        if(response.data.features && response.data.features.length>0 && response.data.features[0].properties.HUC12){
                            const huc12 = response.data.features[0].properties.HUC12;
                            dataItem["HUC12"] = huc12;
                            dataItem["HUC12NAME"] = response.data.features[0].properties.NAME;
                            if(!dataItem["HUC8"]){
                              dataItem["HUC8"] = dataItem["HUC12"].substr(0,8);
                            }

                            const huc = JSON.stringify({"HUC12" : dataItem["HUC12"], "HUC12NAME": dataItem["HUC12NAME"] })                       
                            setStore("ra_" + 'HUC12OBJ' + '_' + dataItem["Longitude"] + dataItem["Latitude"], huc);

                           return dataItem;
                        }
                    }
                })
            }

          }).then(data => {  
              return dataItem;
          });

      }
      return dataItem;
    }

    async function geocode(dataItem: any){ 
        //let usArray = ['United States', 'USA', 'US'];
        let input = `${dataItem["Location_Address"]} ${dataItem["City"]} ${dataItem["State"]} ${dataItem["Zip_Code"]} ${(dataItem["Country"]) ? dataItem["Country"] : ""}`;
        let URL = `https://api.mapbox.com/geocoding/v5/mapbox.places/${input}.json?&&limit=3&access_token=${settings.maboxKey}`;

        if(USA.indexOf(dataItem["Country"])>-1){
          input = `${dataItem["Location_Address"]} ${dataItem["City"]} ${dataItem["State"]} ${dataItem["Zip_Code"]}`;
          URL = `https://api.mapbox.com/geocoding/v5/mapbox.places/${input}.json?&&country=US&limit=3&access_token=${settings.maboxKey}`;
        }                                       

        return await axios.get(URL)
              .then((response) => {
           
                if(response.status === 200 && response.data && response.data.features && response.data.features[0]&& response.data.features[0].center){
                      let nameFlag = false;
                      if(!dataItem["Location_Name"] && uploaderName){
                        nameFlag = true;
                      }

                      let matching = response.data.features.find(
                         (name:any ) => name.place_type.indexOf('locality')>-1 
                      );
                      if(matching){
                          if(nameFlag){
                            dataItem["Location_Name"] = matching.text
                          }
                          dataItem["locality"] = matching.text
                      }

                      matching = response.data.features.find(
                         (name:any ) => name.place_type.indexOf('country')>-1 
                      );
                      if(matching){
                          dataItem["Country"] = matching.text
                          dataItem["Country_sc"] = matching.properties.short_code;
                      }

                      matching = response.data.features.find(
                         (name:any ) => name.place_type.indexOf('region')>-1 
                      );
                      if(matching){
                          dataItem["State"] = matching.text
                          dataItem["State_sc"] = matching.properties.short_code;
                      }
                      matching = response.data.features.find(
                         (name:any ) => name.place_type.indexOf('postcode')>-1 
                      );
                      if(matching){
                          dataItem["Zip_Code"] = matching.text
                          dataItem["postcode"] = matching.text
                          dataItem["postcode_placename"] = matching.place_name
                          dataItem["Latitude"] = matching.geometry.coordinates[1];
                          dataItem["Longitude"] = matching.geometry.coordinates[0];
                       }

                      matching = response.data.features.find(
                         (name:any ) => name.place_type.indexOf('place')>-1 
                      );
                      if(matching){
                          dataItem["Latitude"] = matching.geometry.coordinates[1];
                          dataItem["Longitude"] = matching.geometry.coordinates[0];
                       }

                      matching = response.data.features.find(
                         (name:any ) => name.place_type.indexOf('address')>-1 
                      );
                      if(matching){
                          dataItem["Latitude"] = matching.geometry.coordinates[1];
                          dataItem["Longitude"] = matching.geometry.coordinates[0];
                       }



                      dataItem["ImportStatus"] = 'S';
                     
                      return dataItem;
                 } else {
                     dataItem["ImportStatus"] = 'error';
                }
             
                return dataItem;
        });     

    }


  const processLocations_a = (dataItem: any, source: string, index?: any) => {
/*    if (data && data.length > 0 && data[0]['<!DOCTYPE html>']) {
      return null;
    }
*/

  
    let i = 0;
   
      dataItem.index = index;
      //i++;

      dataItem.Name = dataItem["Location_Name [character varying]"];
      if (!dataItem["Location_Name [character varying]"]) {
        dataItem.Name = dataItem["Location_Name"];
      }

      if(!dataItem['Address'] && dataItem['Location_Address']){
        dataItem['Address'] = dataItem['Location_Address'];
      }  
 
      if(dataItem['Address'] && !dataItem['Location_Address']){
        dataItem['Location_Address'] =  dataItem['Address'];
      }                           


      if (!dataItem["HUC8"] && USA.indexOf(dataItem.Country) > -1) {
        getHUC(dataItem["Latitude"], dataItem["Longitude"], dataItem);
      }
      if (!dataItem["pointCategory"]) {
        dataItem["pointCategory"] = +dataItem[selectedPointCategory];
      }
      if (dataItem["HUC8"] && dataItem["HUC8"].length === 7) {
        dataItem["HUC8"] = '0' + dataItem["HUC8"]
      }

      /*      dataItem["Longitude"] = dataItem["Longitude"].toFixed(8);
            dataItem["Latitude"] = dataItem["Latitude"].toFixed(8);*/

      dataItem["centroid_longitude"] = dataItem["Longitude"];
      dataItem["centroid_latitude"] = dataItem["Latitude"];

/*      if ((!dataItem["HUC12"] || !dataItem["HUC12NAME"]) && USA.indexOf(dataItem.Country) > -1) {
        getHUC12(dataItem);
      }
*/


      let allCalls = [] as any;
      let j = 0;
      for (let dataReport of reportTabs_) {
        j++;
        if (dataReport.title !== 'Overview' && dataReport.title !== 'Site Surface Water Quality Threshold') {
          if ((USA.indexOf(dataItem.Country) < 0)) {
            if (internationalEval.indexOf(dataReport.title) > -1) {
             let aaa =  RiskEvaluationAsync(dataReport.title, dataItem);
          
            }

          } else {


            //setTimeout(() => {
              let aaa =  RiskEvaluationAsync(dataReport.title, dataItem);
      
            //}, i * 5);

          }

        } else if (dataReport.title === 'Site Surface Water Quality Threshold') {
          if (USA.indexOf(dataItem.Country) > -1) {
            RiskEvaluationAsync(dataReport.title, dataItem);
            if (i === 0) {
              //RiskEvaluation(dataReport.title, dataItem);
            } else {
              setTimeout(() => {
                //RiskEvaluation(dataReport.title, dataItem);
              }, i * 55);
            }
          }
        }
      }
      dataItem["RiskStatus"] = 'Success';
      return dataItem;
  
/*      if(source!=='importer'){ 
        if (dataItem[tabActive + 'result']) {
          dataItem["pointCat"] = dataItem[tabActive + 'result'];
          dataItem["pointCategoryColor"] = dataItem[tabActive];
          dataItem["pointObj"] = { result: dataItem[tabActive + 'result'], color: dataItem[tabActive] };
        }

        if (tabActive === 'Overview') {
          const tabDefault = reportTabs_[1].title;
          dataItem["pointCat"] = dataItem[tabDefault + 'result'];
          dataItem["pointCategoryColor"] = colorGreen;//dataItem[tabDefault];
          dataItem["pointObj"] = { result: dataItem[tabDefault + 'result'], color: colorGreen };
        }

      }*/



    //}
/*

      setTimeout(() => {
          setUploaderLocations(data);
        }, 4444);

    }*/
  }








    const uploaderStep1 = (e: any) => {
      clearAlert();
      setUploaderProcessedLocations([]);
      setUploaderStep(2)
    }

    const uploaderStep2 = (e: any) => {
      const input = (document.getElementById('uploader-file') as HTMLInputElement);
        if (input && input.files && input.files[0]) {
          let file = input.files[0];
          let fileName = file.name.split('.')[0];
          setUploaderFile(file)
          setLayerName(fileName)
          const reader = new FileReader();
          reader.readAsText(file);
          reader.onloadend = () => {
            var data = parse(reader.result as string, {
              header: true,
            });
           
            let resultMessage = ''
            if (data.errors && data.errors.length > 0) {
/*              global.notify('Some errors during import.');*/
              resultMessage = 'Some errors during import. ' + JSON.stringify(data.errors[0]);
              //setStatusMessage(resultMessage);
              console.log(data.errors)
              for (let dataErr of data.errors) {
                if(dataErr.row){ //@ts-ignore
                  data.data[dataErr.row].ImportStatus = 'Error'; //@ts-ignore
                  data.data[dataErr.row].ImportErrors = dataErr.message;
                }
              }
            }

            if(data && data.data&& data.data.length>0){
              let newData = [...data.data];
              for (let dataItem of data.data) {//@ts-ignore
                  if(!dataItem['Address'] && dataItem['Location_Address']){//@ts-ignore
                    dataItem['Address'] = dataItem['Location_Address'];
                  }  
                  //@ts-ignore
                  if(dataItem['Address'] && !dataItem['Location_Address']){//@ts-ignore
                    dataItem['Location_Address'] =  dataItem['Address'];
                  }                           
                  //@ts-ignore
                  if(!dataItem['Name'] && dataItem['Location_Name']){//@ts-ignore
                    dataItem['Name'] = dataItem['Location_Name'];
                  }  
                 //@ts-ignore
                  if(dataItem['Name'] && !dataItem['Location_Name']){//@ts-ignore
                    dataItem['Location_Name'] =  dataItem['Name'];
                  }   

                  //@ts-ignore
                  if(!dataItem['Name'] && !dataItem['Address'] && !dataItem['Latitude'] && !dataItem.ImportErrors){//@ts-ignore
                       dataItem.ImportStatus = 'Error'; //@ts-ignore
                       dataItem.ImportErrors = 'Missing fields';
                  }
              }

              setUploaderLocations(data.data);              
              setUploaderStep(3)
              setAlertUploaderMessage({title: 'File read successfully', message: resultMessage, variant: 'success'})
              setAlertUploaderShow(true)
            } else {
              setAlertUploaderMessage({title: 'No data imported', message: '', variant: 'error'})
              setAlertUploaderShow(true)              
            }
          };
        } else {
            //Alert
            setAlertUploaderMessage({title: 'No file found - Please select file', message: '', variant: 'warning'})
            setAlertUploaderShow(true)
        }
    }

    const uploaderStep3 = (e: any) => {
      //clearAlert();
      //console.log('uploaderStep3')

      setUploaderStep(4)
    }

    const removeEmptyElements = (array:any) => array.filter((item:any) => item && item!==null);

    async function getNextBatch(cit:any){
        return await Promise.all(cit.map((seg:any) => onProcess_s(seg))).then((values) => {
        
          if(values && values.length>0 && values[0]){
            //newData.concat(values)
            //setUploaderProcessedLocations(newData);   

            return values;         
          }
        });
    }

    async function sleep(ms:any) {
      return await new Promise(resolve => setTimeout(resolve, ms));
    }

    const uploaderStep4 = (e: any) => {
      setIsNotInProgress(false);

      let rawData = [...uploaderLocations];

      let data = rawData.filter((item:any) => !item.ImportErrors);
      



/*      let batchLength = 1;
      let dataLength = data.length;

      let newData = [] as any;

      for (var i = 0; i < dataLength; i++) {
        const cit = data.slice(i*batchLength, i*batchLength + batchLength);
        console.log(cit, dataLength, i*batchLength, batchLength, data);

        let newResult = getNextBatch(cit).then(result => console.log('result', result));
        console.log('nR', newResult)


      }*/

      // /Promise.all(data.map((seg:any, index:any) =>{ sleep(index*1000); return onProcess_s(seg); })).then((values) => {
        Promise.all(data.map((seg:any, index:any) =>{ return onProcess_s(seg); })).then((values) => {
       
          if(values && values.length>0){
            //let newData = [] as any;

            //newData.concat(values)
            
            setUploaderProcessedLocations(removeEmptyElements(values));   
            //setIsNotInProgress(true);         
          }
        });

      
        

      
      //onProcess(e);
      setUploaderStep(5)
    }

    const uploaderStep5= (e: any) => {
      //let newData = processLocations(uploaderLocations, "importer");

      //let newData = processLocations(uploaderProcessedLocations, "importer");
      setIsNotInProgress(false);

      Promise.all(uploaderProcessedLocations.map((seg:any, index:any) => processLocations_a(seg, 'uploader', index))).then((values) => {
        //console.log('processLocations_A', values, removeEmptyElements(values));
        if(values && values.length>0){
          let newClimateLocations = processClimateSites(removeEmptyElements(values), dataNOAAArray, data1960Array, selectedClimateCategory, yearFacilityDesign, yearInterest);

          setUploaderProcessedLocations(newClimateLocations)



          setTimeout(() => {
            setIsNotInProgress(true);
            setAlertUploaderMessage({title: 'Risk Assessment complete', message: '', variant: 'success'})
            setAlertUploaderShow(true)
            setUploaderStep(6)
          }, 1111);
        }
        
      });



      
    }

    const uploaderStep6 = (e: any) => {
      //global.setCustomLocations(uploaderLocations);
      global.setCustomLocations(uploaderProcessedLocations);
      global.setGuiSwitch('');
      setImportShow(false);
      setUploaderStep(1);
    }

    const uploaderStep7 = (e: any) => {

      setUploaderStep(1)
    }

    const clearAlert = () => {
        setAlertUploaderMessage({title: '', message: '', variant: 'success'})
        setAlertUploaderShow(false)
    }

    const onNext = (e: any) => {
      clearAlert();
      if(uploaderStep === 1){
        uploaderStep1(e)
      } else if(uploaderStep === 2){
        uploaderStep2(e)
      } else if(uploaderStep === 3){
        uploaderStep3(e)
      } else if(uploaderStep === 4){
        uploaderStep4(e)
      } else if(uploaderStep === 5){
        uploaderStep5(e)
      } else if(uploaderStep === 6){
        uploaderStep6(e)
      }  else if(uploaderStep === 7){
        uploaderStep7(e)
      }     
    }

    const stepList = (e: any) => {
     clearAlert();
     if(e.target.hash === '#1'){
        setUploaderStep(1)
        //uploaderStep1(e)
      } else if(e.target.hash === '#2'){
        setUploaderStep(2)
       //uploaderStep2(e)
      } else if(e.target.hash === '#3'){
        setUploaderStep(3)
        //uploaderStep3(e)
      } else if(e.target.hash === '#4'){
        setUploaderStep(4)
        //uploaderStep4(e)
      } else if(e.target.hash === '#5'){
        setUploaderStep(5)
        //uploaderStep5(e)
      } else if(e.target.hash === '#6'){
        setUploaderStep(6)
        //uploaderStep6(e)
      }  else if(e.target.hash === '#7'){
        setUploaderStep(7)
        //uploaderStep7(e)
      }     
    }

    const onPrevious = (e: any) => {
      setAlertUploaderMessage({title: '', message: '', variant: 'success'})
      setAlertUploaderShow(false)
      setUploaderStep(uploaderStep - 1)
    }



    const setStore = (keyname: string, dataPayload: any) => { 
      setTimeout(() => {
        localForage.setItem(keyname, dataPayload);
      }, 1111);
    }


    const onProcess = (e: any) => {
      let data = uploaderLocations;
      const cleanData:any = [];

      for (let dataItem of data) {
         /*if (dataItem["Location_Name"] || dataItem["Location_Name [character varying]"]) {
           cleanData.push(dataItem);
         }*/
       
        //dataItem["ImportStatus"] = 'success';
        //

        if(dataItem["ImportErrors"]){
          return;
        }
        
        dataItem["ImportErrors"] = '-';
        if(!dataItem["Year_Built"]){
          dataItem["Year_Built"] = 1970 + Math.floor(Math.random() * 53) + 1;
        }
        if(!dataItem["Future Year of Interest"] ){
          dataItem["Future Year of Interest"] = 2040;
        }                            
                            
        let pathFlag = false;
          


          if(uploaderGeocode){//@ts-ignore
            if(!dataItem['Latitude']||!dataItem['Longitude']){//@ts-ignore
              pathFlag = true;
              geocode(dataItem);  
            }
          }

         if(uploaderRevGeocode){//@ts-ignore
           if(!dataItem['Address'] && dataItem['Latitude']  && dataItem['Longitude']){
              pathFlag = true;

              localForage.getItem("ra_placegeo_" + dataItem["Longitude"] + dataItem["Latitude"], function(err, value) {
                console.log(err, value)
                if(value){
                 
                  let merged = {...dataItem, ...value};
                 
                } else { 
                  //fetchData(evaluation, dataItem);
                }
              });

              reverseGeocode(dataItem)    

      
           }
         }

         if(!pathFlag){
             let upLocations = [...uploaderProcessedLocations]
             setLocationAdd(dataItem);
         }
    }
  }


  const handleImportSelect = (selectedIndex: any) => {
    setIndexImport(selectedIndex);
  };

  const handleImportClose = () => {
    setImportShow(false);
    setUploaderStep(1);
    global.setGuiSwitch('');
  };

  const handleImportShow = () => setImportShow(true);

  const onClickPopover = (event: any) => {
    let rTabs = [...reportTabs];
    let qreportTabs = rTabs.find(x => x.title === event.target.textContent);
    if (qreportTabs.active === 'true') {
      qreportTabs.active = '';
    } else {
      qreportTabs.active = 'true';
    }
    setReportTabs(rTabs)
  };

  const onClickFieldsPopover = (event: any) => {
    let rTabs = [...analysisFields];
    let qreportTabs = rTabs.find(x => x.title === event.target.textContent);
    if (qreportTabs.active === 'true') {
      qreportTabs.active = '';
    } else {
      qreportTabs.active = 'true';
    }
    setAnalysisFields(rTabs)
  };



  const popover = (
    <Popover id="risk-categories-popover" key={"risk-categories-popover-" + reportTabsActiveLength}>
      <Popover.Header as="h3">Select Risk Categories</Popover.Header>
      <Popover.Body>
        <ListGroup onClick={onClickPopover}>
          {reportTabsAvailable && reportTabsAvailable.map((item: any, index: any) => {
            if (item.title !== 'Overview') {
              return (
                <ListGroup.Item action key={'risk-categories-popover-item' + index} className={item.active === 'true' ? 'risk-categories-popover-selected' : ''}>
                  {item.title}
                </ListGroup.Item>
              )
            }
          }
          )}
        </ListGroup>
      </Popover.Body>
    </Popover>
  );

  const popoverFields = (
    <Popover id="risk-categories-popover" key={"risk-categories-popover-" + reportTabsActiveLength}>
      <Popover.Header as="h3">Select Percentage Fields</Popover.Header>
      <Popover.Body>
        <ListGroup onClick={onClickFieldsPopover}>
          {analysisFields && analysisFields.map((item: any, index: any) => {
            if (item.title !== 'Overview') {
              return (
                <ListGroup.Item action key={'risk-categories-popover-item' + index} className={item.active === 'true' ? 'risk-categories-popover-selected' : ''}>
                  {item.title}
                </ListGroup.Item>
              )
            }
          }
          )}
        </ListGroup>
      </Popover.Body>
    </Popover>
  );

  let sasbprefix = 'SASB: SV-HL-';
  if (qcinds && qcinds.length === 4) {
    sasbprefix = `SASB: ${qcinds.substr(0, 2)}-${qcinds.substr(2, 2)}-`
  }

  const transformFeatures = ({ type, features }: any) => {

    const removeDuplicateObjects = (arr: any) => {
     
      const uniqueObjects:any = [];

      const uniqueStrings:any = [];

      
      arr.forEach((obj:any) => {
        
        const objString = JSON.stringify(obj);

        if (!uniqueStrings.includes(objString)) {
          
          uniqueObjects.push(obj);

          uniqueStrings.push(objString);
        }
      });

      return uniqueObjects;
    }

    return {
      type, 
      features: removeDuplicateObjects(features)
    }

  }

  const dropdownRiskCollectionHandler = (event: any) => {
    if(event.target.id && event.target.id.length===2){
        setqcrcl(event.target.id);
    }    
  };



  return (
    <>
      <FullScreenComp handle={fullscreenhandle} className={'risk-fullscreen'}>
        <Container fluid className="risk-container" >
          <div className="risk" id='risk-report'>
            <ReportHeader global={global}
              subHeader={(currentDatasetLabel) ? `(${currentDatasetLabel})` : ``}
              data={dataArray_unfiltered}
              data2={reportTabs.filter(x => x.active === 'true')}
              data3={sasbData}
              filterMode={'sasb upload climatecalc'}
              reportID={"risk-report"} filterAppliedOff={true} fullScreenClickHandle={fullscreenhandle.enter} />
            <Row className="container-row-top">
              <Col m={1} className="column-container">
                <div className="map-report-container">

                  <Map
                    id="risk"
                    mapboxAccessToken={settings.maboxKey}
                    mapStyle={global.mapStyle}
                    onLoad={onMapLoad}
                    onClick={(e) => {
                      global.onMapClick(e)
                      onHandleClick(e)
                    }}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    cursor={cursor}
                    preserveDrawingBuffer={true}
                    interactiveLayerIds={['pointlayer']}
                    ref={mapRef as Ref<MapRef>}
                    projection={global.globeView ? 'globe' : 'mercator' as any}
                    onMoveEnd={(e) => {
                      global.setViewport({
                        longitude: e.viewState.longitude,
                        latitude: e.viewState.latitude,
                        zoom: e.viewState.zoom,
                      })
                    }
                    }
                  >

                    {featureData && (
                      <Source id='pointsource' type='geojson' data={transformFeatures(featureData)} key={tabActive}>
                        <Layer {...layerStylePoint} />
                        <Layer {...hoverLayerStyle} filter={mapHoverFilter} />
                      </Source>
                    )}

                    <MapAddition global={global} 
                                mapRef={mapRef}
                              
                                PointInPolygonField={locationField}
                               
                                position={'low'}
                                zipOff={true}
                                MapSliderAdd={true}
                                statusMessage={statusMessage}/>

                    {popupInfo && renderPopup()}

                    <div className='map-legend-container'>
                      <MapLegend
                        global={global} legendWidth={400}
                      />
                    </div>
                  </Map>
                </div>
              </Col>

            </Row>

            <Row className="container-row-bottom">
              <div className={isFullScreenActive ? 'expand-tab-container tab-container' : 'tab-container'}>
                <img className='logo' src={logoImg} style={{ display: 'none' }} />
                <div className="report-container">
                  <DropdownButton 
                      key={'dropdown-risk' + qcrcl} 
                      onClick={dropdownRiskCollectionHandler} 
                      className={'risk-dropdown'}
                      id="risk-dropdown-collection" //@ts-ignore
                      title={(qcrcl && qcrcl.length>1 && RiskCollection && RiskCollection.find(x => x.code === qcrcl) && RiskCollection.find(x => x.code === qcrcl).label) ? RiskCollection.find(x => x.code === qcrcl).label : 'Risk Segment' } 
                      style={(qcrcl && qcrcl.length>1)? {minWidth: 258, marginTop: 10} :{display:'none'}}>
                      {RiskCollection.map((collection) => (
                          <Dropdown.Item key={`droprisk${collection.code}`} id={collection.code} eventKey={collection.code}>{collection.label}</Dropdown.Item>
                      ))}
                  </DropdownButton>
                  <div className="report-options">
                    <OverlayTrigger  rootClose trigger="click" placement="left" overlay={popover}>
                      <Button style={{ color: 'blue', marginRight: 4 }}>
                        <FontAwesomeIcon icon={faGear} />
                      </Button>
                    </OverlayTrigger>
                    <Button onClick={fullscreenContainerHandler}>
                      <img src={fullscreenIcon} />
                    </Button>
                  </div>

                  <Tabs
                    style={{ marginTop: 40 }}
                    defaultActiveKey="Overview"
                    className=""
                    onSelect={onSelectTabs}
                    key={"risk-tabs-" + reportTabsActiveLength}
                  >
                    {reportTabs && reportTabs.map((item: any, index: any) => {

                      if (item.active === 'true') {

                        if (item.title === 'Water Management' || item.title === 'Ingredient Sourcing') {
                          return <Tab eventKey={item.title} key={'reportTabsA' + item.title} title={item.title} style={{ position: 'relative' }}>

                            <div className="report-options-fields">
                              <OverlayTrigger  rootClose trigger="click" placement="left" overlay={popoverFields}>
                                <Button style={{ color: 'blue', marginRight: 4 }}>
                                  <FontAwesomeIcon icon={faGear} />
                                </Button>
                              </OverlayTrigger>
                            </div>

                            <div id={'reportTabs' + index} style={{ marginTop: 10, paddingLeft: 8, paddingRight: 8, minHeight: 320 }}>
                              <Row style={{ justifyContent: 'space-between', minHeight: 320 }}>
                                <Col className={'risk-tabs-column-first'}>
                                  <h3 style={{ fontSize: '1.5rem' }}>{item.titlesection}</h3>

                                  {analysisFields && analysisFields.filter(x => x.active === 'true').map((itemq: any, indexq: any) => {
                                    return <span key={'analysisFields' + indexq} style={{ marginLeft: 10 }}>
                                      <span className={'category-select-calc-top-pr'}>{item.resultredcalc && item.resultredcalctop[indexq]}</span>
                                      <span className={'category-select-calc-top'} > of {(item.title === 'Water Management') ? 'Water ' : ''} {itemq.title} is in a basin with </span>
                                      <span>High or Extremely High Baseline Water Stress</span>.
                                    </span>

                                  })
                                  }

                                  <div style={{ marginTop: 'auto' }}>
                                    {item.attribution && (<p style={{ marginLeft: 10, marginTop: 10 }}>Source: <a target="_blank" href={item.attribution_url}>{item.attribution}</a></p>)}
                                    {item.attribution2 && (<p style={{ marginLeft: 10, marginTop: 10 }}>Source: <a target="_blank" href={item.attribution_url2}>{item.attribution2}</a></p>)}
                                    {item.sasbrefid && item.sasbrefbool && (<p style={{ marginLeft: 10, marginTop: 10 }}>{sasbprefix}{(item.title === 'Ingredient Sourcing' && qcinds && qcinds.length === 4 && qcinds.substr(2, 2) === 'AG') ? '440a.2' : item.sasbrefid}</p>)}
                                  </div>
                                </Col>
                                <Col style={{ maxWidth: '35vw', fontSize: '1.4rem' }}>
                                  {item.resultgreen && (<span className={'category-select-header'} ><span className={'category-select'} onClick={() => {
                                    setFilterCategory('')
                                  }} style={{ textDecoration: 'underline' }}>Site Assessment</span> </span>)}

                                  {item.resultgreen && (<span className={'category-select'} >
                                    <span className={'category-select-result'}>
                                      <span className={'circle-report-green'} style={{ display: 'none' }} />
                                    </span>
                                    <span className={'category-select-calc-b'} ></span>

                                    {analysisFields && analysisFields.filter(x => x.active === 'true').map((itemq: any, indexq: any) => {
                                      return <span key={'analysisFieldsA' + indexq} className={'category-select-calc category-select-text'} >{itemq.title}</span>
                                    })
                                    }

                                  </span>)}

                                  {item.resultgreen && (<span className={'category-select'} onClick={() => {
                                    (filterCategory === colorGreen) ? setFilterCategory('') : setFilterCategory(colorGreen)
                                  }} style={(filterCategory !== '' && filterCategory !== colorGreen) ? { opacity: .5 } : {}}>
                                    <span className={'category-select-result'}>
                                      <span className={'circle-report-green'} /> {item.resultgreen}
                                    </span>
                                    <span className={'category-select-calc-b'} ></span>
                                    {analysisFields && analysisFields.filter(x => x.active === 'true').map((itemq: any, indexq: any) => {
                                      return <span key={'analysisFieldsB' + indexq} className={'category-select-calc'} >{item.resultgreencalc && item.resultgreencalc[indexq]} </span>
                                    })
                                    }
                                  </span>)}
                                  {item.resultyellow && (<span className={'category-select'} onClick={() => {
                                    (filterCategory === colorYellow) ? setFilterCategory('') : setFilterCategory(colorYellow)
                                  }} style={(filterCategory !== '' && filterCategory !== colorYellow) ? { opacity: .5 } : {}}>
                                    <span className={'category-select-result'}>
                                      <span className={'circle-report-yellow'} /> {item.resultyellow}
                                    </span>
                                    <span className={'category-select-calc-b'} ></span>
                                    {analysisFields && analysisFields.filter(x => x.active === 'true').map((itemq: any, indexq: any) => {
                                      return <span key={'analysisFieldsC' + indexq} className={'category-select-calc'} >{item.resultyellowcalc && item.resultyellowcalc[indexq]} </span>
                                    })
                                    }
                                  </span>)}
                                  {item.resultred && (<span className={'category-select'} onClick={() => {
                                    (filterCategory === colorRed) ? setFilterCategory('') : setFilterCategory(colorRed)
                                  }} style={(filterCategory !== '' && filterCategory !== colorRed) ? { opacity: .5 } : {}}>
                                    <span className={'category-select-result'}>
                                      <span className={'circle-report-red'} /> {item.resultred}
                                    </span>
                                    <span className={'category-select-calc-b'} ></span>
                                    {analysisFields && analysisFields.filter(x => x.active === 'true').map((itemq: any, indexq: any) => {
                                      return <span key={'analysisFieldsD' + indexq} className={'category-select-calc'} >{item.resultredcalc && item.resultredcalc[indexq]} </span>
                                    })
                                    }
                                  </span>)}
                                  {item.resultgreen && (<span className={'category-select'}>
                                    <span className={'category-select-result'}> </span>
                                    <span className={'category-select-calc-b'} ></span>

                                    {analysisFields && analysisFields.filter(x => x.active === 'true').map((itemq: any, indexq: any) => {
                                      return <span key={'analysisFieldsE' + indexq} className={'category-select-calc category-select-total'} >{item.resultcalc && item.resultcalc[indexq]} </span>
                                    })
                                    }

                                  </span>)}

                                  {item.resultgreen && (<span className={'category-select'}>
                                    <span className={'category-select-result'}> </span>
                                    <span className={'category-select-calc-b'} ></span>
                                    <span style={{ fontSize: '.8em', marginLeft: 45 }} >{(item.title === 'Ingredient Sourcing') ? analysisUnit : ''}{(item.title === 'Water Management') ? 'in Thousand Cubic Meters (m³) ' : ''}</span>

                                  </span>)}

                                </Col>
                                <Col style={{ fontSize: '1rem', maxWidth: '20vw', marginRight: 40 }}>
                                  <p>{item.text}</p>
                                  <p className={'text-color-green'}>{item.textgreen}</p>
                                  <p className={'text-color-yellow'}>{item.textyellow}</p>
                                  <p className={'text-color-red'}>{item.textred}</p>
                                  <p >{item.textadditional}</p>
                                </Col>
                              </Row>
                            </div>
                          </Tab>
                        }

                        return (
                          <Tab eventKey={item.title} key={'reportTabs' + item.title} title={item.title}>

                            <div id={'reportTabs' + index} style={{ marginTop: 10, paddingLeft: 8, paddingRight: 8 }}>
                              <Row style={item.title === 'Overview' ? { flexFlow: 'nowrap', display: 'none' } : { flexFlow: 'nowrap', justifyContent: 'space-between', minHeight: 240 }}>
                                <Col style={{ display: 'flex', flexDirection: 'column', maxWidth: '25vw' }}>
                                  <h3 style={{ fontSize: '1.5rem' }}>{item.titlesection}</h3>
                                  {(item.title === 'Site Flood Zone Ratings' && qcinds === 'SVHL') ?
                                    <span key={'analysisFieldsaa'} style={{ marginLeft: 10 }}>
                                      <span className={'category-select-calc-top-pr'}>{item.resultred}</span>
                                      <span className={'category-select-calc-top'} > Lodging Facilities located in </span>
                                      <span style={{  }} >100-year flood zones</span>.
                                    </span> : ''
                                  }
                                  {(item.title === 'Located within a Protected Area' && qcinds === 'SVHL') ?
                                    <span key={'analysisFieldsaa'} style={{ marginLeft: 10 }}>
                                      <span className={'category-select-calc-top-pr'}>{item.resultred}</span>
                                      <span className={'category-select-calc-top'} > Lodging Facilities located in or near areas of </span>
                                      <span style={{  }} >protected conservation status or endangered species habitat</span>.
                                    </span> : ''
                                  }
                                  <div style={{ marginTop: 'auto' }}>
                                    {item.attribution && (<p style={{ marginLeft: 10, marginTop: 10 }}>Source: <a target="_blank" href={item.attribution_url}>{item.attribution}</a></p>)}
                                    {item.attribution2 && (<p style={{ marginLeft: 10, marginTop: 10 }}>Source: <a target="_blank" href={item.attribution_url2}>{item.attribution2}</a></p>)}
                                    {item.sasbrefid && item.sasbrefbool && (<p style={{ marginLeft: 10, marginTop: 10 }}>{sasbprefix}{(item.title === 'Ingredient Sourcing' && qcinds && qcinds.length === 4 && qcinds.substr(2, 2) === 'AG') ? '440a.2' : item.sasbrefid}</p>)}

                                  </div>
                                </Col>
                                <Col style={{ maxWidth: '20vw', fontSize: '1.4rem' }}>
                                  {item.resultgreen && (<span className={'category-select'} onClick={() => {
                                    setFilterCategory('')
                                  }} style={{ textDecoration: 'underline' }}>Site Assessment</span>)}
                                  {item.resultgreen && (<span className={'category-select'} onClick={() => {
                                    (filterCategory === colorGreen) ? setFilterCategory('') : setFilterCategory(colorGreen)
                                  }} style={(filterCategory !== '' && filterCategory !== colorGreen) ? { opacity: .5 } : {}}><span className={'circle-report-green'} />{item.resultgreen}</span>)}
                                  {item.resultyellow && (<span className={'category-select'} onClick={() => {
                                    (filterCategory === colorYellow) ? setFilterCategory('') : setFilterCategory(colorYellow)
                                  }} style={(filterCategory !== '' && filterCategory !== colorYellow) ? { opacity: .5 } : {}}><span className={'circle-report-yellow'} /> {item.resultyellow}</span>)}
                                  {item.resultred && (<span className={'category-select'} onClick={() => {
                                    (filterCategory === colorRed) ? setFilterCategory('') : setFilterCategory(colorRed)
                                  }} style={(filterCategory !== '' && filterCategory !== colorRed) ? { opacity: .5 } : {}}><span className={'circle-report-red'} /> {item.resultred}</span>)}
                                </Col>
                                <Col style={{ fontSize: '1rem' }}>
                                  <p>{item.text}</p>
                                  <p className={'text-color-green'}>{item.textgreen}</p>
                                  <p className={'text-color-yellow'}>{item.textyellow}</p>
                                  <p className={'text-color-red'}>{item.textred}</p>
                                  <p >{item.textadditional}</p>
                                </Col>
                              </Row>
                            </div>
                          </Tab>
                        )
                      }
                    })}
                  </Tabs>

                  <Modal className='riskmodal' fullscreen={true} scrollable={true} 
                      show={importShow} onHide={handleImportClose}  >                  
                    <div className='uploader-sidebar' >
                        <img className='logo' src={logoImg} />
                        <h2 style={{marginTop: 50}}>Import</h2>
                        <ListGroup>
                          <ListGroup.Item action onClick={stepList} href="#1">
                            <FontAwesomeIcon style={(uploaderStep>1) ? {display:'block'} :{display:'none'}} icon={faCheck} size='lg' className="fs-icon-app" />
                            <Badge style={(uploaderStep<2) ? {display:'block'} :{display:'none'}}  pill bg={(uploaderStep===1) ? 'primary' : 'secondary'}>1</Badge>Getting Started
                          </ListGroup.Item>
                          <ListGroup.Item  action onClick={stepList} href="#2">
                            <FontAwesomeIcon style={(uploaderStep>2) ? {display:'block'} :{display:'none'}} icon={faCheck} size='lg' className="fs-icon-app" />
                            <Badge  style={(uploaderStep<3) ? {display:'block'} :{display:'none'}} pill bg={(uploaderStep===2) ? 'primary' : 'secondary'}>2</Badge>Upload CSV file
                          </ListGroup.Item>
                          <ListGroup.Item disabled={(uploaderStep>2) ? false : true} action onClick={stepList} href="#3">
                          <  FontAwesomeIcon style={(uploaderStep>3) ? {display:'block'} :{display:'none'}} icon={faCheck} size='lg' className="fs-icon-app" />
                            <Badge  style={(uploaderStep<4) ? {display:'block'} :{display:'none'}} pill bg={(uploaderStep===3) ? 'primary' : 'secondary'}>3</Badge>Name Dataset
                          </ListGroup.Item>
                          <ListGroup.Item disabled={(uploaderStep>3) ? false : true} action onClick={stepList} href="#4">
                            <FontAwesomeIcon style={(uploaderStep>4) ? {display:'block'} :{display:'none'}} icon={faCheck} size='lg' className="fs-icon-app" />
                            <Badge  style={(uploaderStep<5) ? {display:'block'} :{display:'none'}} pill bg={(uploaderStep===4) ? 'primary' : 'secondary'}>4</Badge>Populate Data
                          </ListGroup.Item>
                          <ListGroup.Item disabled={(uploaderStep>4) ? false : true} action onClick={stepList} href="#5">
                            <FontAwesomeIcon style={(uploaderStep>5) ? {display:'block'} :{display:'none'}} icon={faCheck} size='lg' className="fs-icon-app" />
                            <Badge  style={(uploaderStep<6) ? {display:'block'} :{display:'none'}} pill bg={(uploaderStep===5) ? 'primary' : 'secondary'}>5</Badge>Process Risk Assessments
                          </ListGroup.Item>
                          <ListGroup.Item  disabled={(uploaderStep>5) ? false : true} action onClick={stepList} href="#6">
                            <FontAwesomeIcon style={(uploaderStep>6) ? {display:'block'} :{display:'none'}} icon={faCheck} size='lg' className="fs-icon-app" />
                            <Badge  style={(uploaderStep<7) ? {display:'block'} :{display:'none'}} pill bg={(uploaderStep===6) ? 'primary' : 'secondary'}>6</Badge>Review
                          </ListGroup.Item>
                        </ListGroup>
                    </div>
                    <div style={{width: 'calc(100% - 250px)'}}>
                      <Modal.Header closeButton>
                          <Modal.Title></Modal.Title>
                      </Modal.Header>
                    <Modal.Body style={{}}>                      
                      <div style={{ minWidth: '50vw', fontSize: 12, height:'100%' }}>
                        <Form style={{ height:'100%' }}>
                          <div id={'uploader-step1'} style={(uploaderStep===1) ? {display:'block', fontSize: '1.3em'} :{display:'none'} }> 
                            <h4>Getting Started</h4>
                              <p style={{ marginTop: 10, marginLeft: 10 }}>
                                  Upload a CSV file of locations for Risk Assessment. 
                              </p>
                              <p>
                                To add your own data to the map, import a csv file of locations. After it has been successfully loaded, a temporary layer will appear in the Layers Menu under "Organization Location Data".
                                Data added to the map will not be stored.  On closing the browser, the data layers may be removed.
                              </p>
                              <p style={{ marginTop: 10, marginLeft: 10 }}>
                                    <ul>
                                      <li>Option One: include all required fields.</li>
                                      <li>Option Two: include Address, City, State, Zip_Code, Country, and possibly Name.  Latitude and Longitude will be geocoded.</li>
                                      <li>Option Three: include Latitude, Longitude, and possibly Name: Address will be reverse-geocoded. </li>
                                    </ul> 
                              </p>
                             <div>
                                <div style={{ marginTop: 50, marginLeft: 10, maxWidth: 559 }}>
                                    <p style={{  }}>Please use the example below to structure your data.</p>
                                    <ul>
                                      <li>Required fields:
                                    Name, Address,
                                    City, State, Zip_Code, Country,
                                    Latitude, Longitude</li>
                                      <li>Required for Climate Calculator: Year_Built</li>
                                      <li>Optional field for Climate Calculator: Future Year of Interest</li>
                                    </ul>                                    
                                    <p style={{ marginTop: 5 }}></p>
                                    <p style={{ marginTop: 5 }}></p>
                                    <p style={{ marginTop: 5 }}><a href="/climatesasb/sasb_upload_locations.csv" target="_blank"> Download Example CSV</a></p>
                                  </div>
                             </div>
                          </div>

                          <div id={'uploader-step2'} style={(uploaderStep===2) ? {display:'block'} :{display:'none'} }> 
                            <h4>Upload CSV file</h4>
                            <Form.Group controlId="uploader-file" className="mb-3" style={{ display: 'flex', alignItems: 'center', fontSize: 18 }}>
                              {/*  <Form.Label>Upload file</Form.Label> */}
                              <Form.Control  type="file" accept=".csv" style={{ maxWidth: 340, fontSize: 18, marginLeft: 10 }} />
                            </Form.Group>
                          </div>

                          <div id={'uploader-step3'} style={(uploaderStep===3) ? {display:'block'} :{display:'none'} }> 
                            <h4>Name Dataset and Map Layer</h4>
                            <Form.Group className="d-flex">
                              <Form.Label style={{ fontSize: 18, width: 100, marginLeft: 10}}>Name Layer:{'  '}</Form.Label>
                              <Form.Control
                                type="text"
                                defaultValue={layerName}
                                onChange={(e: any) => setLayerName(e.target.value)}
                                style={{ maxWidth: 300, fontSize: 14, marginLeft: 20 }}
                              />
                            </Form.Group>
                          </div>

                          <div id={'uploader-step4'} style={(uploaderStep===4) ? {display:'block'} :{display:'none'} }> 
                            <h4>Populate Data</h4> 
                            <Form.Label style={{ fontSize: 18, width: '100%', marginLeft: 10, marginBottom: 10}}>Select options to fill in data</Form.Label>
                            <div className={'GD-progress-container'} style={isNotInProgress ? {display: 'none' }: { display: 'flex' }}>
                              <span className={'GD-progress-containe-span'}>Populating data</span>
                              <ProgressBar key={'ProgressBar' + progress} className={'GD-progress'} min={0} max={100} animated label={`${progress}%`}
                                 now={progress} visuallyHidden={isNotInProgress ? true : false}   />
                            </div>   

                            <div className="uploader-action">
                            
       
                              <div className="uploader-action-box action-box">
                                  <Form.Check
                                      defaultChecked={uploaderGeocode}
                                      onChange={(e:any) => {
                                          setUploaderGeocode(e.target.checked)            
                                      }}
                                  />
                                  <span>Geocode missing coordinates</span>
                              </div>
                              <div className="uploader-action-box action-box">
                                  <Form.Check
                                      defaultChecked={uploaderRevGeocode}
                                      onChange={(e:any) => {
                                          setUploaderRevGeocode(e.target.checked)            
                                      }}
                                  />
                                  <span>Reverse-geocode empty address</span>
                              </div>
                              <div className="uploader-action-box action-box">
                                  <Form.Check
                                      defaultChecked={uploaderName}
                                      onChange={(e:any) => {
                                          setUploaderName(e.target.checked)            
                                      }}
                                  />
                                  <span>Generate name from address when blank</span>
                              </div>
                            </div>
                            <div>
                              <ReportTableExpanded columns={tableColumnsUploader} data={uploaderLocations} />
                            </div> 
                          </div>

                          <div id={'uploader-step5'} style={(uploaderStep===5) ? {display:'block'} :{display:'none'} }> 
                            <h4>Process Risk Assessments</h4>
                            <Form.Label style={{ fontSize: 18, width: '100%', marginLeft: 10, marginBottom: 10}}>Verify changes and proceed to assessment</Form.Label>                           
                            <div className="uploader-action-box action-box">
                            </div>                          
                            <div>
                              <ReportTableExpanded key={'upl-tableA'+uploaderProcessedLocations.length + alertUploaderShow} columns={tableColumnsUploaderStep} data={uploaderProcessedLocations} />
                            </div> 
                          </div>

                          <div id={'uploader-step6'} style={(uploaderStep===6) ? {display:'block'} :{display:'none'} }> 
                            <h4>Review</h4>
                            <Form.Label style={{ fontSize: 18, width: '100%', marginLeft: 10, marginBottom: 10}}>View in Risk Assessment report</Form.Label>                                                      
                            <div className="uploader-action-box action-box">
                              <Button style={{display: 'none'}} className={(uploaderStep===1) ? 'uploader-button  uploader-nodisplay' : 'uploader-button' }                                                                                 
                                onClick={onProcess}>
                                <div style={{display: 'flex', flexDirection: 'column'}}>                                
                                  <span style={{paddingLeft: 2, display: 'inline-block', fontSize:9}}>Finish</span>
                                </div> 
                              </Button>
                            </div> 
                            <div>
                              <ReportTableExpanded key={'upl-table'+uploaderProcessedLocations.length + alertUploaderShow + isNotInProgress } columns={tableColumnsUploaderStepEval} data={uploaderProcessedLocations} />
                            </div> 
                          </div>

                        </Form>  
                        </div>
                    </Modal.Body>
                    
                    <Modal.Footer>
                        <div className='uploader-alert-container'><AlertUploader  /></div>
                      <div style={{ textAlign: 'center', width: '100%', borderTop: '1px solid #dee2e6' }}>
                        <Button className={(uploaderStep===1) ? 'uploader-button  uploader-nodisplay' : 'uploader-button' }                                                                            
                            onClick={onPrevious}>
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                              <FontAwesomeIcon icon={faArrowLeft} size='lg' className="fa-thin fs-icon-app" />
                              <span style={{paddingLeft: 12, display: 'inline-block'}}>Previous</span>
                            </div> 
                        </Button>                        
                        <Button className={(uploaderStep===7) ? 'uploader-button  uploader-nodisplay' : 'uploader-button' }  
                            onClick={onNext}>
                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}} >                                        
                              <span style={{paddingRight: 12, display: 'inline-block'}}>{(uploaderStep===6) ? 'Finish' : 'Next' }</span>
                              <FontAwesomeIcon icon={faArrowRight} size='lg' className="fa-thin fs-icon-app" />
                            </div> 
                        </Button>
                      </div>
                      <Button style={{display: 'block', position: 'absolute', right: 30, bottom: 20}} variant="secondary" onClick={handleImportClose}>
                        Close
                      </Button>
                      <Button style={{display: 'none', position: 'absolute', right: 110}} variant="primary" onClick={handleImportClose}>
                        Save Changes
                      </Button>
                    </Modal.Footer>
                    </div>
                  </Modal>


                  <div style={{ width: '100%' }} id={'reportTableRiskContainer'} key={"risk-table-" + reportTabsActiveLength}>
                    <LoadingDataAnimation dataLoading={!isDataReportLoaded} />
                    <ReportTableExpanded
                      key={'reportTableRiskContainer' + assessmentField + analysisUnit}
                      data={dataArray}
                      columns={(tabOverview) ? tableColumns_grid : tableColumns}
                      clickRowHandler={clickRowHandler}
                      clickLoadStop={true}
                    />

                    <div id='report-table'></div>
                  </div>
                </div>
              </div>
            </Row>
          </div>
        </Container>
      
      </FullScreenComp>
    </>
  );
}