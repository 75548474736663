import { parse } from 'papaparse';
import { Layer, Source, FillLayer } from 'react-map-gl'
import { useState, useEffect } from 'react'
import settings from '../../settings.json'
import HucPopup from '../../components/HucPopup'
import agent from '../../api/agent';
import { adjustHexBrightness } from '../../features/utils';


const TileHover = ({ global, mode, opacity, brightness }: any) => {

  const [huc, setHuc] = useState<any>('')
  const [hucCatalog, setHUCCatalogue] = useState<any>()
  const [huc8Data, setHuc8Data] = useState()

  useEffect(() => {
    const huc8FileURL = '/huc8s_info.csv';
    fetch(huc8FileURL)
      .then((response) => response.text())
      .then((responseText) => {
        const hucCatalog: any = parse(responseText, {
          header: true,
        }).data;
        setHUCCatalogue(hucCatalog);
      })
  }, [])

  useEffect((): any => {
    if (global.action && global.action.lngLat && global.action.lngLat.lat && global.action.lngLat.lng) {   

      const lat = global.action.lngLat.lat
      const lng = global.action.lngLat.lng
      agent
      .Utilities
      .LatLngToHuc8(lat, lng)
      .then(({ body }: any) => {
        if(body && body[0] && body[0].Huc8){
         const res = body[0].Huc8
         findHuc(res)
         setHuc(res) 
         global.setSelectedHUC8(res);      
        }  
      })
    }
  }, [global.action])

  const findHuc = (input: any) => {
    const huc8Data = hucCatalog && hucCatalog.find((item: any) => item.huc8 === input)
    setHuc8Data(huc8Data);
  }
  
  const huc8Layer: any = {
    id: `tile-hover`,
    source: 'True Elements - huc8s',
    "source-layer": 'True Elements - huc8s',
    type: "fill",
    beforeId: settings.defaultBeforeId,
    filter: ['==', 'HUC8', huc],
    paint: {
      "fill-color": `${adjustHexBrightness('#008080', brightness)}`,
      "fill-opacity": opacity,
      'fill-outline-color': `${adjustHexBrightness('#BD1C1C', brightness)}`
    },
  };

  return (
    <>
      <Source
        type="vector"
        url={settings.tileSetURLs.HUC8}
        id={`tile-hover`}
      >
        <Layer {...huc8Layer} />
      </Source>
      {huc8Data && <HucPopup global={global} huc8Data={huc8Data} />}
    </>
  )
}

export default TileHover