import React, { useEffect, useState, useContext } from 'react'
import { RefContext } from "../../../RefContext";
import { PointContext } from "../../../PointContext";
import { Layer, Source, Popup } from 'react-map-gl'
import { useNavigate } from 'react-router-dom'
import { FillLayer } from 'mapbox-gl';
import { Col, Nav } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { countryOutlines, countryFills, countries } from '.'
import { adjustHexBrightness } from "../../../features/utils";

const WiseLayer = ({ 
    id, 
    opacity, 
    tileSet, 
    sourceLayer, 
    beforeID, 
    action,
    brightness
  }: any) => {
    
  const [popupData, setPopupData] = useState<any>()
  const [popup, setPopup] = useState<any>()

  const navigate = useNavigate()
  const { currentRef } = useContext<any>(RefContext)
  const { setCurrentPoint } = useContext<any>(PointContext);
  const ref = currentRef && currentRef?.current;

  const lngLat = action && action.lngLat;

  const getFeaturesAtPoint = async (map: any, event: any) => {
    const features = await map.queryRenderedFeatures(event.point)?.[0]; 
    setPopupData(features.properties);
  };

  const handleClose = () => {
    setPopup(undefined);
    setPopupData(undefined);
  }

  const gotTo = (isoCode: string, country: string, lat: any, lng: any) => {
    console.log(country, isoCode, lat, lng)
    setCurrentPoint({ country, isoCode, lat, lng })
    setTimeout(() => navigate('/WiseReport'), 300)
  }

  useEffect(() => {
    if(popupData && lngLat) {
      const { lat, lng }: any = lngLat
      const { name_en, iso_3166_1 } = popupData
      setPopup(
        <Popup
          longitude={lng}
          latitude={lat}
          onClose={handleClose}
          style={{ width: '150px' }}
        >
          <FontAwesomeIcon
            className="close-btn"
            icon={faXmark}
            onClick={handleClose}
          />
         <Col className='col-12 d-flex justify-center flex-column'>
          <Col style={{ marginBottom: '4px', justifyContent: 'center' }} className='col-12 d-flex justify-center'>Country: {name_en}</Col>
          <Col style={{ justifyContent: 'center'}} className='col-12 d-flex justify-center'>
            <Nav.Link style={{ color: "#4fc2d9", textDecoration: "underline" }} onClick={() => gotTo(iso_3166_1, name_en, lat, lng)}>
              Go to Report
            </Nav.Link>
          </Col>
         </Col> 
      </Popup>
      )
    }
  }, [popupData, lngLat])

  useEffect(() => { 
    if(ref) {
        ref.on('click', (e:any) => getFeaturesAtPoint(ref, e))
    }
  }, [ref])

  const handleColors = (brightness: number) => {
    return countryFills.map((i:string) => {
      if(i.match(/#/)) {
        return adjustHexBrightness(i, brightness)
      } else {
        return i
      }
    })
  }
 
  const WiseLayer: FillLayer = {
    id: `${id}-layer`,
    type: "fill",
    "source-layer": `${sourceLayer}`,
    paint: {
      "fill-opacity": opacity,
      "fill-outline-color": [
        "match",
        ["get", "name_en"], 
        ...countryOutlines,
        "transparent"
      ],
      'fill-color': [
        "match",
        ["get", "name_en"], 
        ...handleColors(brightness),
        "transparent"
      ],
    },
    filter: ['in', "name_en", ...countries]
  };

    return (
        <>
          <Source
            id={`${id}-layer`}
            type="vector"
            url={tileSet}
          >
            <Layer
              beforeId={beforeID}  
              {...WiseLayer} 
            />
          </Source>
          {popup}
        </>
    )
}

export default WiseLayer;
