import { useEffect } from 'react'
import { Layer, Source } from 'react-map-gl'
import { adjustHexBrightness } from "../../features/utils";
import { FillLayer, LineLayer } from 'mapbox-gl';

const Hydrobasins = ({ id, global, HydrobasinsLevel, opacity, tileSet, HydrobasinsKind, source, brightness }: any) => {

    useEffect(() => global.notify('Zoom in to see layer'),[])

    const kind = HydrobasinsKind === "standard" ? 
    ["standard", adjustHexBrightness("#dd660d", brightness)] 
    : ["custom", adjustHexBrightness("#31aa9a", brightness)]

    const HydrobasinsFill: FillLayer = {
        id,
        type: "fill",
        "source-layer": source,
        minzoom: 6.25,
        paint: {
            "fill-opacity": opacity,
            'fill-color': [
                "match",
                ["get", "kind"],
                ...kind,
                'transparent' 
            ],
        },
        filter: ['in', "level", parseInt(HydrobasinsLevel)]
    };

    const HydrobasinsLine: LineLayer = {
        id: `${id}-outline`,
        type: "line",
        "source-layer": source,
        minzoom: 6.25,
        paint: {
            "line-color": "#000000", 
            "line-width": 3,
        },
        filter: ['in', "level", parseInt(HydrobasinsLevel)]
    };

    return (
        <Source id={id} type="vector" url={tileSet}>
            <Layer {...HydrobasinsFill} />
            <Layer {...HydrobasinsLine} />
        </Source>
    )
}

export default Hydrobasins
