import { useEffect, useState, useContext } from 'react';
import { Popup } from 'react-map-gl';
import { useNavigate } from 'react-router-dom'
import { PointContext } from '../PointContext'
import { AppContext } from '../AppContext'
import { LayerDataContext } from "../LayerDataContext";
import { getCountryFromStorage } from '../features/utils'
import ReportsConfigs from '../ReportsConfigs';
import { v4 as uuidv4 } from 'uuid';
import agent from '../api/agent';
import axios from 'axios';
import settings from "../settings.json";
import './render-popup.scss' 

const RenderPopup = (props: any) => {
    
    
    const { current, setCurrent, reportUrl, global } = props
    const { setCurrentPoint } = useContext(PointContext)
    const appContext = useContext(AppContext)
    const { setCurrentLayerData } = useContext(LayerDataContext);
    const navigate = useNavigate()

    const updateContext = (lat: any, lng: any, city: any, report: any) => {

        return agent.Utilities.LatLngToHuc8(lat, lng)
            .then((res: any) => {
                const responseBody = res.body;
                const responseHUC8 = responseBody[0].Huc8;
                if (appContext.updateContext) {
                    appContext.updateContext(responseHUC8, city, global.currentReport, getCountryFromStorage());
                }
            }).then(() => {
                global.setLoading(false)
                report === reportUrl[1] && global.setUnregulatedReport(true)

                if(props.popupfieldsreflect===true){

                     let  URL = `https://api.mapbox.com/v4/${settings.tileSetURLs.NationalImpairedWaterPolygons.substr(9)}/tilequery/${lng},${lat}.json?radius=311010&access_token=${settings.maboxKey}`;
                     axios.get(URL)
                        .then((response) => {
                            if(response && response.data && response.data.features && response.data.features.length>0){
                                navigate303D(lat, lng, report, response, '303d_polygons')
                            } else if(response && response.data && response.data.features && response.data.features.length<1){
                                URL = `https://api.mapbox.com/v4/${settings.tileSetURLs.NationalImpairedWaterLines.substr(9)}/tilequery/${lng},${lat}.json?radius=311010&access_token=${settings.maboxKey}`;
                                   axios.get(URL)
                                    .then((response) => {
                                        if(response && response.data && response.data.features && response.data.features.length>0){
                                            navigate303D(lat, lng, report, response, '303d_lines')
                                        } 
                                    }) 
                            }   
                     })                                   
                } else {
                    navigate(report)    
                }
            })
    }

    const navigate303D  = (lat: any, lng: any, report: any, response:any, typeLayer: any) => {
              const reportConfig = ReportsConfigs.find(x => x.route == '/WaterImpairementsPoints');  
          
              let req303 = response.data.features[0].properties;
              req303.tableColumns = request303.tableColumns
              req303.sourceLayer = typeLayer
              req303.sourceLink = reportConfig?.sourceLink || request303.sourceLink
              req303.sourceLabel = reportConfig?.sourceText|| request303.sourceLabel
              req303.url = typeLayer
              setCurrentLayerData?.(req303);
              setTimeout(() => navigate(report+window.location.search), 111);
    }


    const updateHuc8 = (lat: any, lng: any, report: any) => {
        global.setLoading(true)
        return agent.Utilities.LatLngToCity(lat, lng).then((data: any) => {
            // @ts-ignore
            const city = data.features === [] ? data.query : current.city
            updateContext(lat, lng, city, report)
        })
    }

    // useEffect(() => {
    //     const popup: any = document.getElementById("dial-popup")
    //     popup.addEventListener("contextmenu", (event: any) => { event.preventDefault() })
    // }, [])

    // useEffect(() => {
    //     const popup: any = document.getElementById("dial-popup")
    //     popup.addEventListener("contextmenu", (event: any) => {
    //         const e = event as MouseEvent
    //         setOpen(true)
    //         setPosX(e.offsetX)
    //         setPosY(e.offsetY)
    //     })
    // }, [posX, posY, open])

    const renderFields = (objectMap: object) => {
        return Object.entries(objectMap).map((element: any, index:any) => {
          const skipFields = ['national_twpe_score'];
          if(skipFields.indexOf(element[0])>-1){
            return null;
          } else if (element[0] === 'Url') {
            return (
                <p className="GD-p-comid" key={uuidv4()}>
                  <span style={{opacity: .9}}>{'Site info:'}:</span>
                  <span style={{marginLeft: 10, fontSize: '1.3em', opacity: 1}}>  
                    <a href={element[1]} target={'_blank'}>Navigate to more site info</a>
                  </span>
                </p>
              )        
          } else if (element[0] === 'Count') {
            return (
              Object.entries(JSON.parse(element[1])).map((elementb: any, indexb:any) => {
                return (<p className="GD-p-comid" key={uuidv4()}>
                    <span style={{opacity: .9}}>{elementb[0]}:</span>
                    <span style={{marginLeft: 10, fontSize: '1.3em', opacity: 1}}>    {elementb[1]} </span>
                  </p>)

                })
              )        
          } else {
            return (
                <p className="GD-p-comid" key={uuidv4()}>
                  <span style={{opacity: .9}}>{element[0]}:</span>
                  <span style={{marginLeft: 10, fontSize: '1.3em', opacity: 1}}>    {element[1]} </span>
                </p>
              )        
          }
        })
    }

    return (
        <Popup
            style={{ position: 'relative' }}
            longitude={current.longitude ? current.longitude : current.lng}
            latitude={current.latitude ? current.latitude : current.lat}
            className={'sc-popup-main h303'}
            onClose={() => setCurrent(null)}
        >
            <div id="dial-popup">
                {!props.popupfieldsreflect && (
                    <div className="popup-card">
                    <h4><strong>{current.city && `${current.city},`} {current.state}</strong></h4>
                    {current.zip && (<h4><strong>zip:</strong> {current.zip}</h4>)}
                    <h4>{current.longitude ? current.longitude : current.lng}, {current.latitude ? current.latitude : current.lat}</h4>
                    {current.pounds && (<h4><strong>Pounds:</strong> {current.pounds}</h4>)}
                    {current.twpe && (<h4><strong>twpe:</strong> {current.twpe}</h4>)}
                </div>
            )}
                {props.popupfieldsreflect && (
                <div className="popup-container-left">
                    {renderFields(current)}
                </div>)}
                <div style={{ textAlign: 'center', marginTop: "-1rem", fontWeight: "bold" }} className="popup-hover">
                    <a
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            setCurrentPoint?.(current)
                            updateHuc8(
                                current.latitude || current.lat,
                                current.longitude || current.lng,
                                reportUrl
                            )
                        }}
                        className="popup-context-item"
                    >
                        {(props.popupfieldsreflect) ? 'Navigate to report' : 'Go to Regulated Report'}
                    </a>
                </div>
            </div>
        </Popup>
    )
}

let request303 = {
    "url": "303d_polygons",
    "sourceLayer": "303d_polygons",
    "tableColumns": [
        {
            "Header": "GLOBALID",
            "accessor": "GLOBALID"
        },
        {
            "Header": "algal_growth",
            "accessor": "algal_growth"
        },
        {
            "Header": "ammonia",
            "accessor": "ammonia"
        },
        {
            "Header": "assessmentunitidentifier",
            "accessor": "assessmentunitidentifier"
        },
        {
            "Header": "assessmentunitname",
            "accessor": "assessmentunitname"
        },
        {
            "Header": "assmnt_joinkey",
            "accessor": "assmnt_joinkey"
        },
        {
            "Header": "biotoxins",
            "accessor": "biotoxins"
        },
        {
            "Header": "cause_unknown",
            "accessor": "cause_unknown"
        },
        {
            "Header": "cause_unknown_impaired_biota",
            "accessor": "cause_unknown_impaired_biota"
        },
        {
            "Header": "cultural_use",
            "accessor": "cultural_use"
        },
        {
            "Header": "chlorine",
            "accessor": "chlorine"
        },
        {
            "Header": "dioxins",
            "accessor": "dioxins"
        },
        {
            "Header": "ecological_use",
            "accessor": "ecological_use"
        },
        {
            "Header": "fishconsumption_use",
            "accessor": "fishconsumption_use"
        },
        {
            "Header": "has4bplan",
            "accessor": "has4bplan"
        },
        {
            "Header": "hasprotectionplan",
            "accessor": "hasprotectionplan"
        },
        {
            "Header": "hastmdl",
            "accessor": "hastmdl"
        },
        {
            "Header": "hydrologic_alteration",
            "accessor": "hydrologic_alteration"
        },
        {
            "Header": "ircategory",
            "accessor": "ircategory"
        },
        {
            "Header": "isassessed",
            "accessor": "isassessed"
        },
        {
            "Header": "isimpaired",
            "accessor": "isimpaired"
        },
        {
            "Header": "isthreatened",
            "accessor": "isthreatened"
        },
        {
            "Header": "mercury",
            "accessor": "mercury"
        },
        {
            "Header": "metals_other_than_mercury",
            "accessor": "metals_other_than_mercury"
        },
        {
            "Header": "nuisance_exotic_species",
            "accessor": "nuisance_exotic_species"
        },
        {
            "Header": "nutrients",
            "accessor": "nutrients"
        },
        {
            "Header": "on303dlist",
            "accessor": "on303dlist"
        },
        {
            "Header": "organizationid",
            "accessor": "organizationid"
        },
        {
            "Header": "organizationname",
            "accessor": "organizationname"
        },
        {
            "Header": "orgtype",
            "accessor": "orgtype"
        },
        {
            "Header": "orig_fid",
            "accessor": "orig_fid"
        },
        {
            "Header": "other_cause",
            "accessor": "other_cause"
        },
        {
            "Header": "other_use",
            "accessor": "other_use"
        },
        {
            "Header": "overallstatus",
            "accessor": "overallstatus"
        },
        {
            "Header": "oxygen_depletion",
            "accessor": "oxygen_depletion"
        },
        {
            "Header": "pathogens",
            "accessor": "pathogens"
        },
        {
            "Header": "permid_joinkey",
            "accessor": "permid_joinkey"
        },
        {
            "Header": "pesticides",
            "accessor": "pesticides"
        },
        {
            "Header": "ph_acidity_caustic_conditions",
            "accessor": "ph_acidity_caustic_conditions"
        },
        {
            "Header": "polychlorinated_biphenyls_pcbs",
            "accessor": "polychlorinated_biphenyls_pcbs"
        },
        {
            "Header": "radiation",
            "accessor": "radiation"
        },
        {
            "Header": "region",
            "accessor": "region"
        },
        {
            "Header": "reportingcycle",
            "accessor": "reportingcycle"
        },
        {
            "Header": "sediment",
            "accessor": "sediment"
        },
        {
            "Header": "state",
            "accessor": "state"
        },
        {
            "Header": "submissionid",
            "accessor": "submissionid"
        },
        {
            "Header": "tas303d",
            "accessor": "tas303d"
        },
        {
            "Header": "taste_color_and_odor",
            "accessor": "taste_color_and_odor"
        },
        {
            "Header": "temperature",
            "accessor": "temperature"
        },
        {
            "Header": "total_toxics",
            "accessor": "total_toxics"
        },
        {
            "Header": "toxic_inorganics",
            "accessor": "toxic_inorganics"
        },
        {
            "Header": "toxic_organics",
            "accessor": "toxic_organics"
        },
        {
            "Header": "turbidity",
            "accessor": "turbidity"
        },
        {
            "Header": "visionpriority303d",
            "accessor": "visionpriority303d"
        },
        {
            "Header": "waterbodyreportlink",
            "accessor": "waterbodyreportlink"
        }
    ],
    "sourceLink": "https://catalog.data.gov/dataset/epa-office-of-water-ow-303d-listed-impaired-waters-nhdplus-indexed-dataset1",
    "sourceLabel": "National 303D Impaired Waters Layer"
};

export default RenderPopup