// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#popover-settings {
  color: white;
  background-color: #12234f;
  border-radius: 3px;
  box-shadow: 0px 0px 5px #12234f;
  padding: 5px;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 500px;
  font-size: 1.3rem;
  border: 1px solid #257ee0;
  cursor: pointer; }
  #popover-settings .form-floating > .form-control:focus ~ label, #popover-settings .form-floating > .form-control:not(:placeholder-shown) ~ label, #popover-settings .form-floating > .form-control-plaintext ~ label, #popover-settings .form-floating > .form-select ~ label {
    color: white;
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }
  #popover-settings .form-floating > .form-control:focus ~ label::after, #popover-settings .form-floating > .form-control:not(:placeholder-shown) ~ label::after, #popover-settings .form-floating > .form-control-plaintext ~ label::after, #popover-settings .form-floating > .form-select ~ label::after {
    background-color: unset; }
  #popover-settings .form-floating .form-select {
    min-height: 70px;
    background-color: #12234f;
    color: white; }
  #popover-settings .popover-header {
    color: white;
    background-color: #12234f;
    font-size: 1.5rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding-bottom: 14px; }
  #popover-settings .popover-body {
    color: white;
    background-color: #12234f; }
`, "",{"version":3,"sources":["webpack://./src/features/reports/shared/MCToolset.scss"],"names":[],"mappings":"AAAA;EACI,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;EAClB,+BAA+B;EAC/B,YAAY;EACZ,uBAAkB;EAAlB,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,yBAAyB;EACzB,eAAe,EAAA;EAVnB;IAaU,YAAY;IACZ,aAAa;IACb,8DAA8D,EAAA;EAfxE;IAmBU,uBAAuB,EAAA;EAnBjC;IAuBQ,gBAAgB;IAChB,yBAAyB;IACzB,YAAY,EAAA;EAzBpB;IA6BQ,YAAY;IACZ,yBAAyB;IACzB,iBAAiB;IACjB,iDAAgD;IAChD,oBAAoB,EAAA;EAjC5B;IAqCQ,YAAY;IACZ,yBAAyB,EAAA","sourcesContent":["#popover-settings {\n    color: white;\n    background-color: #12234f;\n    border-radius: 3px;\n    box-shadow: 0px 0px 5px #12234f;\n    padding: 5px;\n    width: fit-content;\n    max-width: 500px;\n    font-size: 1.3rem;\n    border: 1px solid #257ee0;\n    cursor: pointer;\n\n      .form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-control-plaintext ~ label, .form-floating > .form-select ~ label {\n          color: white;   \n          opacity: 0.65;\n          transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);\n      }\n\n      .form-floating > .form-control:focus ~ label::after, .form-floating > .form-control:not(:placeholder-shown) ~ label::after, .form-floating > .form-control-plaintext ~ label::after, .form-floating > .form-select ~ label::after {\n          background-color: unset; \n      }\n      \n    .form-floating .form-select {\n        min-height: 70px;\n        background-color: #12234f;\n        color: white;\n    }\n\n    .popover-header {\n        color: white;\n        background-color: #12234f;\n        font-size: 1.5rem;\n        border-bottom: 1px solid rgba(255, 255, 255, .2);\n        padding-bottom: 14px;\n    }\n\n    .popover-body {\n        color: white;\n        background-color: #12234f;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
