import React, { useState, useEffect, useContext, useRef, useCallback, memo } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import {
  Map,
  FullscreenControl,
  NavigationControl,
  GeolocateControl
} from 'react-map-gl';
import settings from '../../../settings.json';
import { PointContext } from "../../../PointContext";
import { RefContext } from '../../../RefContext';
import { Row, Col, Container, Tabs, Tab } from 'react-bootstrap';
import { putViewportIntoStorage } from '../../utils';
import MapAddition from '../shared/MapAddition';
import ReportHeader from '../shared/ReportHeader';
import ReportFooter from '../shared/ReportFooter';
import ReportTable from "../shared/ReportTable";
import MapLegend from '../shared/MapLegend';
import agent from '../../../api/agent';
import { SpinnerCircular } from 'spinners-react';
import 'chartjs-plugin-zoom';
import './nsg-report.scss';

const NSGReport = ({ global }: any) => {
  const FullScreenComp = FullScreen as any;
  const fullscreenhandle = useFullScreenHandle() as any;

  const ref = useRef<any>();
  const { currentPoint } = useContext<any>(PointContext);
  const { setCurrentRef } = useContext(RefContext);

  const [tableData, setTableData] = useState<any>({});
  const [datasets, setDatasets] = useState<string[]>([]);
  const [selectedDataset, setSelectedDataset] = useState<string>('');
  const [id, setId] = useState<string>('');
  const [error, setError] = useState(false);
  const notificationCounter = useRef(0);

  useEffect(() => {
    if (error && notificationCounter.current === 0) {
      global.notify('No data at this location');
      setError(true)
      notificationCounter.current++;
    }
  }, [error, global]);

  useEffect(() => {
    setCurrentRef?.(ref);
  }, [ref]);

  const getData = async (dataset: string) => {
    if (tableData[dataset]) {
      return;
    }
    global.setLoading(true);
    try {
      const { body } = await agent.Reports.NSG(id, dataset);
      setTableData((prevData: any) => ({
        ...prevData,
        [dataset]: body
      }));
    } catch (error) {
      setError(true);
    } finally {
      setError(false);
      global.setLoading(false);
    }
  };

  const getMetaData = async (id: string) => {
    global.setLoading(true);
    try {
      const { body } = await agent.Reports.NSGMeta(id);
      const datasetKeys = Object.keys(body[0]);
      setDatasets(datasetKeys);
      if (datasetKeys.length > 0) {
        setSelectedDataset(datasetKeys[0]);
        getData(datasetKeys[0]);
      }
    } catch (error) {
      if (notificationCounter.current === 0) {
        global.notify('No data at this location');
        notificationCounter.current++;
      }
    } finally {
      global.setLoading(false);
    }
  };

  const flyToPoint = (lng: any, lat: any) => {
    if (ref.current) {
      ref.current.flyTo({
        center: [lng, lat],
        essential: true,
        zoom: 10,
      });
    }
  };

  useEffect(() => {
    if (currentPoint) {
      const { lng, lat } = currentPoint;
      setTimeout(() => flyToPoint(lng, lat), 100);
      setId(currentPoint['field_site_id']);
      notificationCounter.current = 0; // Reset notification counter when point changes
    }
  }, [currentPoint]);

  useEffect(() => {
    if (id) {
      getMetaData(id);
    }
  }, [id]);

  useEffect(() => {
    return () => {
      global.setLoading(false);
    };
  }, [global]);

  const renderReportTables = useCallback((data: any) => {
    if (data.length === 0) {
      if (notificationCounter.current === 0) {
        global.notify('No data at this location');
        notificationCounter.current++;
      }
      return null;
    }

    const handleHeaders = (input: any[]) => {
      let headers: any = [];
      input.forEach((header: any) => headers.push({ Header: header, accessor: header }));
      return headers.length > 0 ? headers : [];
    };

    const keys = Object.keys(data[0]);
    console.log(data)
    return <ReportTable data={data} columns={handleHeaders(keys)} />;
  }, [global]);

  const spinnerStyle = {
    color: '#3861AD',
    overflow: 'visible',
    width: '75px',
    marginTop: '10%'
  };

  const noData: boolean = (Object.values(tableData)[0] as any[])?.length === 0 
  || (Object.values(tableData)[1] as any[])?.length === 0 
  || (Object.values(tableData)[2] as any[])?.length === 0 
  || (Object.values(tableData)[3] as any[])?.length === 0 
  || (Object.values(tableData)[4] as any[])?.length === 0 
  || (Object.values(tableData)[5] as any[])?.length === 0 
  || (Object.values(tableData)[6] as any[])?.length === 0;

  return (
    <FullScreenComp handle={fullscreenhandle}>
      <Container className='nsg-report-container'>
        <ReportHeader
          global={global}
          data={[]}
          reportID={"NSGReport"}
          fullScreenClickHandle={fullscreenhandle.enter}
        />
        <Row>
          <Col className='col-12 top d-flex justify-content-center align-items-center'>
            <Col className="nsg-map-container w-100">
              <Map
                mapboxAccessToken={settings.maboxKey}
                mapStyle={global.mapStyle}
                ref={ref}
                preserveDrawingBuffer={true}
                onClick={(e:any) => global.onMapClick(e)}
                onMove={(e) => {
                  putViewportIntoStorage({
                    longitude: e.viewState.longitude,
                    latitude: e.viewState.latitude,
                    zoom: e.viewState.zoom,
                  });
                  global.setViewport({
                    longitude: e.viewState.longitude,
                    latitude: e.viewState.latitude,
                    zoom: e.viewState.zoom,
                  });
                }}
              >
                <MapAddition 
                  global={global} 
                  mapRef={ref}
                  position={'low'}
                  zipOff={true}
                  MapSliderAdd={true}
                />
                <MapLegend global={global} legendWidth={280} />
                <FullscreenControl />
                <NavigationControl />
                <GeolocateControl />
              </Map>
            </Col>
          </Col>
        </Row>
        <Row>
          <Col className='col-12 bottom'>
            <Col className={`line-chart`}>
              {datasets.length > 0 && (
                <Tabs
                  activeKey={selectedDataset}
                  onSelect={(k: any) => {
                    setSelectedDataset(k);
                    getData(k);
                  }}
                  id="dataset-tabs"
                >
                  {datasets.map((dataset: string, index: number) => (
                    <Tab eventKey={dataset} title={dataset} key={index}>
                      <div className='table-report-table'>
                        {noData && <p className='no-data'>No data at this location</p>}
                        {tableData[dataset] ? renderReportTables(tableData[dataset]) : <SpinnerCircular style={spinnerStyle}/>}
                      </div>
                    </Tab>
                  ))}
                </Tabs>
              )}
            </Col>
          </Col>
        </Row>
      </Container>
      <ReportFooter />
    </FullScreenComp>
  );
}

export default memo(NSGReport);
