import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import './time-range-picker.scss';

const TimeRangePicker = ({ onSubmit, initialStartTime, maxTime }: any) => {
  const [startHour, setStartHour] = useState(0);
  const [endHour, setEndHour] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [maxDate, setMaxDate] = useState('');
  const [initialSetupDone, setInitialSetupDone] = useState(false);

  useEffect(() => {
    if (initialStartTime) {
      const initialStart = new Date(initialStartTime);
      const initialEnd = new Date(initialStartTime + 2592000000);
      const maxDate = new Date(maxTime);
  
      const formattedStartDate = initialStart.toISOString().split('T')[0];
      const formattedEndDate = initialEnd.toISOString().split('T')[0];
      const formattedMaxDate = maxDate.toISOString().split('T')[0];

      setStartDate(formattedStartDate);
      setEndDate(formattedEndDate);
      setStartHour(initialStart.getHours());
      setEndHour(initialEnd.getHours());
      setMaxDate(formattedMaxDate);

      setInitialSetupDone(true);  
    }
  }, [initialStartTime]);

  useEffect(() => {
    if (initialSetupDone) {
      handleSubmit();
    }
  }, [initialSetupDone]);

  const parseToUnix = (dateString: string) => {
    if (!/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
      throw new Error('Invalid date format. Expected format: YYYY-MM-DD');
    }
  
    const date = new Date(dateString);
    const unixTimestamp = Math.floor(date.getTime() / 1000);
  
    return unixTimestamp;
  }

 
  const handleSubmit = (e?: any) => {
    if (e) e.preventDefault();

    const startDateTime = parseToUnix(startDate)  
    const endDateTime = parseToUnix(endDate)

    onSubmit({
      startTime: startDateTime,
      endTime: endDateTime,
    });
  };

  const renderHoursOptions = () => {
    const options = [];
    for (let i = 0; i < 24; i++) {
      options.push(
        <option key={i} value={i}>
          {i < 10 ? `0${i}:00` : `${i}:00`}
        </option>
      );
    }
    return options;
  };

  const disabledDatesBeforeInitial = (date: string) => {
    return initialStartTime ? date < startDate : false;
  };

  const disabledDatesAfterMax = (date: string) => {
    return maxTime ? date > endDate : false;
  };

  return (
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col className="col-8 date-col">
            <Form.Group controlId="formStartDate">
              <Form.Label className="small">Start Date</Form.Label>
              <Form.Control
                className="form-control-sm"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                min={initialStartTime && startDate}
                max={maxTime && endDate}
                required
                disabled={disabledDatesBeforeInitial(startDate) || disabledDatesAfterMax(startDate)}
              />
            </Form.Group>
          </Col>
          <Col className="col-4">
            <Form.Group controlId="formStartTime">
              <Form.Label className="small">Start Time</Form.Label>
              <Form.Control
                className="form-control-sm"
                as="select"
                value={startHour}
                onChange={(e) => setStartHour(parseInt(e.target.value))}
                required
              >
                {renderHoursOptions()}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col className="col-8 date-col">
            <Form.Group controlId="formEndDate">
              <Form.Label className="small">End Date</Form.Label>
              <Form.Control
                className="form-control-sm"
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                min={initialStartTime && startDate}
                max={maxTime && maxDate}
                required
                disabled={disabledDatesBeforeInitial(endDate) || disabledDatesAfterMax(endDate)}
              />
            </Form.Group>
          </Col>
          <Col className="col-4">
            <Form.Group controlId="formEndTime">
              <Form.Label className="small">End Time</Form.Label>
              <Form.Control
                className="form-control-sm"
                as="select"
                value={endHour}
                onChange={(e) => setEndHour(parseInt(e.target.value))}
                required
                disabled={disabledDatesBeforeInitial(endDate) || disabledDatesAfterMax(endDate)}
              >
                {renderHoursOptions()}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col className="col-12 d-flex justify-content-center">
            <Button
              className="mt-2 submit-btn"
              variant="primary"
              type="submit"
              size="sm"
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
  );
};

export default TimeRangePicker;