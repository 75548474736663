import agent from "../../../api/agent";

const getHUC = (lat: any, lng: any, dataItem: any) => {
  if(lat && lng){
      return agent.Utilities.LatLngToHuc8(lat.replaceAll(' ', ''), lng.replaceAll(' ', ''))
        .then((res: any) => {
          const responseBody = res.body;
          if(res.errorMessage){
            console.log(res.errorMessage)
          }
          if(responseBody && responseBody.length>0 && responseBody[0].Huc8){
              const responseHUC8 = responseBody[0].Huc8;
              dataItem.HUC8 = responseHUC8;
          } else {
            console.log('no HUC8 found')
          }
        })
  }
}

  const updateCalculator = (zip:any,  selectedCategory:any, calcYearFacilityDesign: any, calcYearInterest: any, data1960Array:any, dataNOAAArray:any) => {
    let CalculatorResults = {} as any;
    //console.log(zip, selectedCategory, calcYearFacilityDesign, calcYearInterest, data1960Array, dataNOAAArray)
    //const county = selectedCounty;
    //+++
    let cDesign = 0;
    let cDesignLatest = 0;
    let c1960 = [] as any;

    let calculatorResult = [] as any;
    calculatorResult.zip = zip;
    //calculatorResult.county = selectedCounty;
    calculatorResult.category = selectedCategory;

    calculatorResult.calcYearFirst = 1961;
    calculatorResult.calcYearToday = (new Date().getFullYear());
    calculatorResult.calcYearFacilityDesign = calcYearFacilityDesign;
    calculatorResult.calcYearInterest = calcYearInterest;
 

    if (data1960Array && data1960Array.length > 0 && zip) {
      //+++ leave out zip leading zero for 1960
      let d1960 = data1960Array.filter((o: any) => o["zip_code"] === zip.toString());
      if (d1960 && d1960.length > 0) {
        //CalculatorResults.CalcDesign(d1960[0][selectedCategory])
        calculatorResult.CalcDesign = d1960[0][selectedCategory];
        cDesign = +d1960[0][selectedCategory];
        c1960 = d1960;
      } else {
        console.log('zip not found: ' + zip)
        return null;
      }
    }

    if (dataNOAAArray && dataNOAAArray.length > 0 && zip) {
      let zip_ = zip;
      if (zip.length < 5) {
        zip_ = '0' + zip;
      }

      let dNOAA = dataNOAAArray.filter((o: any) => o["zip_code"] === zip_.toString());
  
      const yearLastData = dNOAA[0]['NOAA Year'];

  
      calculatorResult.CalcYearLatest = yearLastData;

      cDesignLatest = +dNOAA[0][selectedCategory];
 
      calculatorResult.CalcLatestIntensity = cDesignLatest.toString();

      const rateDesign = (cDesignLatest - cDesign) / (+yearLastData - +calculatorResult.calcYearFirst);
      const intensityDesign = cDesign + (+calcYearFacilityDesign - +calculatorResult.calcYearFirst) * rateDesign;
  
      calculatorResult.CalcDesignIntensity = intensityDesign.toString();

      const rateToday = (cDesignLatest - cDesign) / (+yearLastData - +calculatorResult.calcYearFirst);
      const intensityToday = cDesign + (+calculatorResult.calcYearToday - +calculatorResult.calcYearFirst) * rateToday;
   
      calculatorResult.CalcTodayIntensity = intensityToday.toString();

      const rateInterest = (cDesignLatest - cDesign) / (+yearLastData - +calculatorResult.calcYearFirst);
      const intensityInterest = cDesign + (+calcYearInterest - +calculatorResult.calcYearFirst) * rateInterest;

      calculatorResult.CalcInterestIntensity = intensityInterest.toString();

      const latestRecur = calculateRecurrence(cDesignLatest, +calculatorResult.CalcYearLatest, c1960[0], dNOAA[0]);
      const todayRecur = calculateRecurrence(intensityToday, +calculatorResult.calcYearToday, c1960[0], dNOAA[0]);
      const interestRecur = calculateRecurrence(intensityInterest, +calcYearInterest, c1960[0], dNOAA[0]);

      calculatorResult.CalcLatestRecur = latestRecur.toString() + '-yr';
      calculatorResult.CalcTodayRecur = todayRecur.toString() + '-yr';
      calculatorResult.CalcInterestRecur = interestRecur.toString() + '-yr';
    }
    
    return calculatorResult;
  }


export const processClimateSites = ( data: any,
    dataNOAAArray: any,
    data1960Array:any, selectedCategory:any, calcYearFacilityDesign: any, calcYearInterest: any ) => { 

    let i = 0;

    for (let dataItem of data) {
      dataItem.index = i;
      i++;

      let flagContinue = true;

      //++ Geocode
      /* const input = `${dataItem["Location_Address"]} ${dataItem["City"]} ${dataItem["State"]} ${dataItem["Zip_Code"]}`
       let URL = `https://api.mapbox.com/geocoding/v5/mapbox.places/${input}.json?&&country=US&limit=3&access_token=${settings.maboxKey}`;
        axios.get(URL)
      .then((response) => {
       if(response.status === 200 && response.data && response.data.features && response.data.features[0]&& response.data.features[0].center){
         console.log(response.data.features[0].center)
         dataItem["center"] = response.data.features[0].center;
       }
       });*/


      //++ Data Cleaning
      if(!dataItem["Location_Name [character varying]"] && !dataItem["Location_Name"]){
        console.log(dataItem)
      }
      dataItem.name = dataItem["Location_Name [character varying]"];
      if (!dataItem["Location_Name [character varying]"]) {
        dataItem.name = dataItem["Location_Name"];
      }

      if (dataItem["Latitude"] && typeof dataItem["Latitude"]==='string') {
        //console.log(typeof dataItem["Latitude"],  typeof dataItem["Latitude"]==='string')
        dataItem["Latitude"] = dataItem["Latitude"].replace(/\s/g, '');
      }  

      if (dataItem["Longitude"] && typeof dataItem["Longitude"]==='string') {
        dataItem["Longitude"] = dataItem["Longitude"].replace(/\s/g, '');
      }  

      if (!dataItem["HUC8"]) {
        getHUC(dataItem["Latitude"], dataItem["Longitude"], dataItem);
      }

      if (dataItem["HUC8"] && dataItem["HUC8"].length === 7) {
        dataItem["HUC8"] = '0' + dataItem["HUC8"]
      }

      if (dataItem["Year_Built"] && !dataItem["Year Built"]) {
        dataItem["Year Built"] = dataItem["Year_Built"];
      } else if (!dataItem["Year_Built"] && dataItem["Year Built"]) {
        dataItem["Year_Built"] = dataItem["Year Built"];
      }

      if (dataItem["atrisk"]) {
        dataItem["atrisk"] = +dataItem["atrisk"];
      }

      if (!dataItem["Latest NOAA Data Year"] && dataItem["Zip_Code"]) {
        const zipData = dataNOAAArray.filter((o: any) => +o["zip_code"] === +dataItem["Zip_Code"]); //calcYearLatest
        if (zipData && zipData.length > 0 && zipData[0]["NOAA Year"]) {
          const NOAAyear = zipData[0]["NOAA Year"];
          dataItem["Latest NOAA Data Year"] = zipData[0]["NOAA Year"];

          //setCalcYearLatest(NOAAyear);

          //const _2011Data = newdataset.filter((o: any) => +o["year"] === 2011);
          /*           for (let dataItem of _2011Data) {
                      dataItem["year"] = NOAAyear;
                    } */
        } else {
          flagContinue = false;
          console.log('CC: No Latest NOAA Data Year', dataItem["Zip_Code"], dataItem["HUC8"]);
        }
      }

     
      if(flagContinue){
         

            //++ Calculate
           let calculatorResults = updateCalculator(dataItem["Zip_Code"], selectedCategory, calcYearFacilityDesign, calcYearInterest, data1960Array, dataNOAAArray);
            //console.log(calculatorResults)
            if (!calculatorResults || !calculatorResults.CalcYearLatest) {
              //console.log(calculatorResults.length, calculatorResults.county, calculatorResults, dataItem["Zip_Code"],  dataItem, dataItem["State"])
              flagContinue = false;
              dataItem["ERROR"] = 'possibly Zip Code not supported. ' + dataItem["Zip_Code"] + ' ' + calculatorResults;  //AK, MD, CT, VT, MA
            } else {
              dataItem["CalcDesignIntensity"] = calculatorResults["CalcDesignIntensity"];
              dataItem["CalcYearInterestIntensity"] = calculatorResults["CalcInterestIntensity"];
              dataItem["CalcYearInterestRecurrence"] = calculatorResults["CalcInterestRecur"];
              dataItem["CalcNOAAYearLatest"] = calculatorResults["CalcYearLatest"];
              dataItem["CalcLatestNOAAYearIntensity"] = calculatorResults["CalcLatestIntensity"];
              dataItem["CalcLatestNOAAYearRecur"] = calculatorResults["CalcLatestRecur"];
              dataItem["CalcTodayIntensity"] = calculatorResults["CalcTodayIntensity"];
              dataItem["CalcTodayRecurrence"] = calculatorResults["CalcTodayRecur"];
      
              dataItem["atrisk"] = +dataItem["CalcLatestNOAAYearIntensity"] / +dataItem["CalcYearInterestIntensity"];
      
              if ((+dataItem["CalcNOAAYearLatest"] - +dataItem["Year Built"]) === 0) {
                dataItem["ratechange"] = 0;
              } else {
                dataItem["ratechange"] = (+dataItem["CalcLatestNOAAYearIntensity"] - +dataItem["CalcDesignIntensity"]) / (+dataItem["CalcNOAAYearLatest"] - +dataItem["Year Built"]);
              }
      
              dataItem["Current Intensity"] = +dataItem["Current Intensity"];
              dataItem["Intensity in 2022"] = +dataItem["Intensity in 2022"];
              dataItem["Intensity in 2030"] = +dataItem["Intensity in 2030"];
              dataItem["Intensity in 2040"] = +dataItem["Intensity in 2040"];
              dataItem["Intensity in NOAA Year"] = +dataItem["Intensity in NOAA Year"];
              dataItem["Intensity in Year Built"] = +dataItem["Intensity in Year Built"];
              if (dataItem["Intensity in Year Built"] && dataItem["Intensity in 2022"]) {
                dataItem["atrisk"] = +dataItem["Intensity in 2022"] / +dataItem["Intensity in Year Built"];
              }
              if (dataItem["Intensity in Year Built"]) {
                if ((+dataItem["Latest NOAA Data Year"] - +dataItem["Year Built"]) === 0) {
                  dataItem["ratechange"] = 0;
                } else {
                  dataItem["ratechange"] = (+dataItem["Intensity in NOAA Year"] - +dataItem["Intensity in Year Built"]) / (+dataItem["Latest NOAA Data Year"] - +dataItem["Year Built"]);
                }
              }
            }
            
          }


          if(dataItem["atrisk"] === ''){
            delete dataItem["atrisk"];
          }
    
      }
    return data;  
}


export const calculateIntensity = ( yearOfInterest: any,
    latestDataYear: any,
    intensity61: any,
    intensityLatest: any) => { 

    var oldY = 1961;
    var rate = (intensityLatest - intensity61) / (latestDataYear - oldY);
    return intensity61 + (yearOfInterest - oldY) * rate;
}

export const calculateRecurrence = ( intensity: number,
    yr: number,
    countyDataRow61: any,
    countyDataRowNew: any) => { 

    var recurrences = [2, 5, 10, 25, 50, 100];
    var rec2col = {
      1: 2,
      2: 3,
      5: 4,
      10: 5,
      25: 6,
      50: 7,
      100: 8,
    } as any;
    //intensities for each recurrence at that year

    var intAtRec = {} as any;
    recurrences.forEach((rec) => {
      var latestDataYear = +countyDataRowNew['NOAA Year'];
      var intensity61 = +countyDataRow61[rec + 'yr'];
      var intensityLatest = +countyDataRowNew[rec + 'yr'];

      intAtRec[rec] = calculateIntensity(
        yr,
        latestDataYear,
        intensity61,
        intensityLatest
      );
    });
    //iterate table of intensities

    var i;
    for (var k in intAtRec) {
      var last = undefined;
      var d = intAtRec[k] - intensity;
      //randam tarp kuriu mes metu
      if (d >= 0) {
        //(50-25) / (4.6-2.5)
        //paskaiciuojam intensity kinta tame tarpe

        //we are out of range in bottom
        //lets interpolate by next closest recurrence.
        //ie, now we are at undefined..2
        //so instead we try to calc 2..5 ratio and use that instead
        if (typeof last === 'undefined') {
          var ratio =
            (recurrences[1] - recurrences[0]) /
            (intAtRec[recurrences[1]] - intAtRec[recurrences[0]]);
          //invert ratio
          ratio = 1 - ratio;
          var answer =
            recurrences[0] - (intensity - intAtRec[recurrences[0]]) * ratio;
          if (answer < 0) {
            answer = 0;
          }
          return Math.round(answer);
          //return answer
        } else {
          //normal case,...
          var ratio = (+k - +last) / (intAtRec[k] - intAtRec[last]);
          var answer = +last + (intensity - intAtRec[last]) * ratio;
          return Math.round(answer);
          //return answer
        }
      }
      last = k;
    }
    //we iterated to the end, and are out of TOP range, ie >100
    //lets interpolate by next closest recurrence, ie last 2 elements

    var end = recurrences[recurrences.length - 1];
    var b4end = recurrences[recurrences.length - 2];

    var ratio = (end - b4end) / (intAtRec[end] - intAtRec[b4end]);
    var answer = +end + (intensity - intAtRec[end]) * ratio;

    return Math.round(answer);
};
