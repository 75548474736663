import { useState, useMemo, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Source, Layer, Popup, CircleLayer } from 'react-map-gl';
import { RefContext } from "../../../RefContext";
import { ActivePopupContext } from '../../../ActivePopupContext';
import { PointContext } from "../../../PointContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import './circle-data-layer.scss';

const CircleDataLayer = ({
  id,
  action,
  global,
  circleColor,
  tileSet,
  sourceLayer,
  zoom,
  opacity,
  objString = 'Point',
  reportRoute = '/',
  featurePoint,
  includedLayerData = true,
  reportAnchor = true,
  circleStrokeColor = "white",
  excludedIDs = [''],
  radius,
  popupTitle = true,
  pointLabel = null
}: any) => {

  const navigate = useNavigate();
  const [features, setFeatures] = useState<any>(null);
  const { currentRef } = useContext(RefContext);
  const { setCurrentPoint } = useContext<any>(PointContext);
  const { activePopup, setActivePopup } = useContext<any>(ActivePopupContext);

  // @ts-ignore
  const mapInstance = currentRef && currentRef?.current;
  const lngLat = action && action.lngLat;

  const ensureCoordinates = (obj: any, defaultCoordinates: any) => {
    const coordinateRegex = /^(lat|lng|long|lon|latitude|longitude)$/i;
    let hasLat = false;
    let hasLng = false;
    let tempLat: any, tempLng: any;

    Object.keys(obj).forEach(key => {
      if (coordinateRegex.test(key)) {
        const lowerCaseKey = key.toLowerCase();
        if (lowerCaseKey === 'lat' || lowerCaseKey === 'latitude') {
          tempLat = obj[key];
          hasLat = true;
          delete obj[key];
        }
        if (lowerCaseKey === 'lng' || lowerCaseKey === 'long' || lowerCaseKey === 'lon' || lowerCaseKey === 'longitude') {
          tempLng = obj[key];
          hasLng = true;
          delete obj[key];
        }
      }
    });

    const parseToNumber = (input: any) => {
      const parsedValue = parseFloat(input);
      return isNaN(parsedValue) ? null : parsedValue;
    };

    obj.lat = hasLat ? parseToNumber(tempLat) : parseToNumber(defaultCoordinates.lat);
    obj.lng = hasLng ? parseToNumber(tempLng) : parseToNumber(defaultCoordinates.lng);

    return obj;
  };

  useMemo(() => {
    if (mapInstance) {
      return setTimeout(() => {
        mapInstance.on("click", (event: any) => {
          const layerObject = mapInstance.queryRenderedFeatures(event.point, { layers: [`${id}-layer`] });
          if (layerObject.length > 0) {
            setFeatures(layerObject[0].properties);
          }
        });
      }, 300);
    }
  }, [mapInstance]);

  const popupContent: any = document.querySelector(".mapboxgl-popup-content");
  useEffect(() => {
    if (popupContent) {
      if (!popupTitle) {
        popupContent.style.height = '50px';
        return;
      }
      popupContent.style.height = reportAnchor ? '75px' : '50px';
    }
  }, [popupTitle, reportAnchor]);

  useEffect(() => {
    const popupContent: any = document.querySelector(".mapboxgl-popup-content");
    return () => {
      if (popupContent) {
        popupContent.style.height = 'unset';
      }
    };
  }, []);

  const goTo = (input: any) => {
    let pointData = ensureCoordinates(input, lngLat);
    if (includedLayerData) {
      pointData = { ...pointData, sourceLayer, tileSet };
    }
    setCurrentPoint(pointData);
    setTimeout(() => navigate(reportRoute), 300);
  };

  useEffect(() => {
    if (features) {
      const handleClose = () => {
        setFeatures(null);
        setActivePopup({ id: null, content: null });
      };

      const newPopup = (
        <Popup
          longitude={lngLat.lng}
          latitude={lngLat.lat}
          onClose={handleClose}
          className={'circledata-popup'}
        >
          <FontAwesomeIcon
            className="close-btn"
            icon={faXmark}
            onClick={handleClose}
          />
          {popupTitle && <strong>{objString}: {features[pointLabel ? pointLabel : featurePoint]}</strong>}
          {reportAnchor && (<a className="goTo" onClick={() => goTo(features)}>Go to report</a>)}
        </Popup>
      );

      setActivePopup({
        id,
        content: newPopup
      });
    }
  }, [features, lngLat, popupTitle, reportAnchor, pointLabel, featurePoint, setActivePopup, id]);



  const CircleDataLayerConfig: CircleLayer = {
    id: `${id}-layer`,
    type: "circle",
    "source-layer": sourceLayer,
    paint: {
      "circle-stroke-color": circleStrokeColor,
      "circle-stroke-width": 2,
      "circle-radius": radius,
      "circle-color": circleColor,
      "circle-opacity": opacity,
      "circle-stroke-opacity": opacity,
    },
    filter: ["!in", "ID", ...excludedIDs]
  };

  return (
    <>
      <Source id={`${id}-layer`} type="vector" url={tileSet}>
        <Layer {...CircleDataLayerConfig} />
      </Source>
      {activePopup && activePopup.id === id && activePopup.content}
    </>
  );
};

export default CircleDataLayer;
