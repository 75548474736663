import MCGlobe from  './MCGlobe';
import MCClearLayers from  './MCClearLayers';
import MCOpacity from  './MCOpacity';
import MCCenter from  './MCCenter';
import MCSettings from  './MCSettings';
import MCLayerItem from  './MCLayerItem';
import LayerConfig from '../../../LayerConfig';
import './MCToolset.scss';

function MCToolset({
    global,
    mapRef,
    position,
    initialLayers
}: any) {

    let top = 58;
    let spaceBetween = 38;
    if(position === 'low'){
        top = 186;
        spaceBetween = 39;
    } else if (position === 'low-adjusted') {
        top = 194;
        spaceBetween = 39;
    }

    let layersAvail = [] as any;
    if(initialLayers && initialLayers.length>0){
      layersAvail = LayerConfig.filter((item) => initialLayers.indexOf(item.id) > -1);
    }

    return (
          <div>
              <MCGlobe global={global} mapRef={mapRef} top={top} />
              <MCOpacity global={global} mapRef={mapRef} top={top + spaceBetween} />
              <MCClearLayers global={global} mapRef={mapRef} top={top + (spaceBetween*2)} />
              <MCCenter global={global} mapRef={mapRef} top={top + (spaceBetween*3)} />
              <MCSettings global={global} mapRef={mapRef} top={(position === 'low-adjusted') ? 110 : 80} reverse={(position === 'low-adjusted') ? true : false}/>
              {layersAvail && layersAvail.map((layer:any, index:any) => (<MCLayerItem global={global} mapRef={mapRef} top={top + (spaceBetween*(4 + index))} layer={layer} />))}
          </div>
    );
}

export default MCToolset