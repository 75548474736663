export const OrganizationConfig: any[] = [
  { 
    org_id: 1,
    org_label: 'NASDAQ',
    ccds: 'nasdaq',
    org_access: true,
    csv: '/climatesasb/nasdaq_geo_enc.csv'
  },
  {
    org_id: 9,
    org_label: 'RES',
    ccds: 'rer',
    org_access: true,
    csv: '/climatesasb/sasb_rer.csv'
  },
  {
    org_id: 10,
    org_label: 'Apple',
    ccds: 'apple',
    org_access: true,
    csv: '/climatesasb/sasb_apple_enc.csv'
  },
  {
    org_id: 11,
    org_label: 'Amane Advisors',
    ccds: 'amane',
    org_access: true,
    csv: '/climatesasb/sasb_amane.csv'
  },
  {
    org_id: 14,
    org_label: 'WI Waterkeepers',
    ccds: 'wiwqx',
    org_access: true,
    csv: '/climatesasb/sasb_WIDNR_WQX.csv'
  },
  {
    org_id: 30,
    org_label: 'Pepsi',
    ccds: 'pepsi',
    org_access: true,
    csv: '/climatesasb/sasb_pepsi_enc.csv'
  },
  {
    org_id: 27,
    org_label: 'Apple',
    ccds: 'apple',
    org_access: true,
    csv: '/climatesasb/sasb_apple_enc.csv'
  },
  {
    org_id: 28,
    org_label: 'Apple',
    ccds: 'apple',
    org_access: true,
    csv: '/climatesasb/sasb_apple_enc.csv'
  }
];
