import { useEffect, useState, useRef, useCallback, useContext, useMemo, Ref } from "react";
import * as React from 'react';
import {
  Map, MapboxStyle, useMap, MapRef, MapLayerMouseEvent, Popup,
  FullscreenControl,
  NavigationControl,
  GeolocateControl,
  useControl,
  Layer,
  Source,
  FillLayer,
} from "react-map-gl";
import { Expression, StyleFunction } from 'mapbox-gl';
import settings from "../../../settings.json";
import { Form, Container, Button, Row, Col, Table, Card, Overlay, OverlayTrigger, Accordion, Dropdown } from "react-bootstrap";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import agent from "../../../api/agent";

import { HUC8GeoPoint } from "../../../types/HUC8GeoPoint";
import { HUC12GeoPoint } from "../../../types/HUC12GeoPoint";
import { HUC12InfoPoint } from "../../../types/HUC12InfoPoint";

import LoadingDataAnimation from "../../../components/LoadingDataAnimation";
import { Feature, GeoJsonProperties, Point, Polygon, GeometryCollection, FeatureCollection, Geometry, BBox } from 'geojson';
import type { LayerProps } from 'react-map-gl';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { parse } from "papaparse";
import * as d3Array from 'd3-array';
import * as d3Format from 'd3-format';
import * as d3Scale from 'd3-scale';
import * as d3ScaleChromatic from 'd3-scale-chromatic';
import bbox from '@turf/bbox';

import { AppContext } from '../../../AppContext';
import { CatalogsContext } from '../../../CatalogsProvider';
import { Location, useLocation } from 'react-router-dom';
import MapLegend from '../shared/MapLegend';
import MapSlider from'../shared/MapSlider';
import FilterState from '../shared/FilterState';
import ReportHeader from '../shared/ReportHeader';
import ReportFooter from '../shared/ReportFooter';
import ReportMap from '../shared/ReportMap';
import MapAddition from  '../shared/MapAddition';

import { RefContext } from '../../../RefContext'
import { flyToHUC12sLocation, flyToInitialLocationHandler, getCurrentHuc12LongLat, getHuc12MembersOfHUC8, getCurrentHuc8LongLat } from '../../utils';
import Render from "../../../components/render";
import { createFeatureCollection, processStatusMessage, uniqueValues, updateChartData, fitBoundsMapData, groupByKey } from '../shared/report-data';
import { calculateIntensity, calculateRecurrence } from './ClimateCalculations';
import { marks } from './ClimateMarks';
import { setChartConfig, setChartConfigStorm } from './ClimateConfigs';
import { OrganizationConfig } from '../../../utils/OrganizationConfig';

import queryString from 'query-string';
import {
  QueryParamProvider,
  useQueryParam,
  BooleanParam,
  StringParam
} from 'use-query-params';

import './styles.scss'
import fullscreenIcon from "../../../assets/fullscreen-icon.svg";
import ellipsisIcon from "../../../assets/ellipsis-icon.svg";

import { unparse } from 'papaparse';
import { DateTime } from 'luxon';

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import { Scrollbars } from 'react-custom-scrollbars-2';
import { features } from "process";
import { Line, Bar, getElementAtEvent, getElementsAtEvent } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataset, ChartType, TooltipItem
} from "chart.js";
import zoomPlugin from 'chartjs-plugin-zoom';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

interface updateHuc {
  index?: any;
  HUC8?: any;
}

export default function CountyClimate(props: any) {
  const { global } = props;
  const appContext = useContext(AppContext);
  const { updateContext } = useContext(AppContext)
  const catalogsContext = useContext(CatalogsContext);
  const selectedHUC8Value = appContext.selectedHUC8;
  let selectedCategory = (global.filter1Selected || '100yr');


  const [selectedPointCategory, setSelectedPointCategory] = useState<string>('atrisk');
  const selectedFilterLocations = global.filterLocationsSelected;

  const [selectedYear, setSelectedYear] = useState<string>('2019');
  const [selectedState, setSelectedState] = useState<string>('Colorado');
  const [selectedCounty, setSelectedCounty] = useState<string>('');
  const [selectedZip, setSelectedZip] = useState<string>('');

  const location: Location = useLocation();
  //@ts-ignore
  const routeData = location.state?.data;

  const { current: map } = useMap();
  const [popupInfo, setPopupInfo] = useState<any>(null);
  const [cursor, setCursor] = useState<string>('auto');
  const mapRef = useRef<MapRef>();
  const [mapLoaded, setMapLoaded] = useState(false);
  const locationField = 'County';
  const [mapHoverFilter, setMapHoverFilter] = useState<any>(['in', ['get', locationField], '']);
  const [mapLegendData, setMapLegendData] = useState<any[]>([]);
  const [mapExpression, setMapExpression] = useState<Expression>(['match', ['get', 'name'], 'WASHINGTON', '#cccccc', '#ffffff']);
  const [mapFilterExpression, setMapFilterExpression] = useState<Expression>(['in', 'isCounty', 'true']);
  const [fipsFilter, setFipsFilter] = useState<any[]>([]);

  const [mapExpressionState, setMapExpressionState] = useState<Expression>(['match', ['get', 'name'], 'WASHINGTON', '#cccccc', '#ffffff']);
  const [mapFilterExpressionState, setMapFilterExpressionState] = useState<Expression>(['in', 'isState', true]); //['!=', 'name', 'Colorado']
  const [layerStyleState, setLayerStyleState] = useState<any>([]);

  const [circleColorStyle, setCircleColorStyle] = useState<any>([]);
  const [mapStyleData, setMapStyleData] = useState<any>([]);
  const [huc8LonLatCatalogArray, setHuc8LonLatCatalogDataArray] = useState<any[]>([]);
  //const [fipsDataArray, setFipsDataArray] = useState<any[]>([]);
  const [stateCentroidsDataArray, setStateCentroidsDataArray] = useState<any[]>([]);

  const [data1960Array, setData1960Array] = useState<any[]>([]);
  const [dataNOAAArray, setDataNOAAArray] = useState<any[]>([]);
  const [calcLatestIntensity, setCalcLatestIntensity] = useState<string>('17.05');
  const [calcDesignIntensity, setCalcDesignIntensity] = useState<string>('17.05');
  const [calcTodayIntensity, setCalcTodayIntensity] = useState<string>('17.05');
  const [calcInterestIntensity, setCalcInterestIntensity] = useState<string>('17.05');
  const [calcLatestRecur, setCalcLatestRecur] = useState<string>('29-yr');
  const [calcTodayRecur, setCalcTodayRecur] = useState<string>('20-yr');
  const [calcInterestRecur, setCalcInterestRecur] = useState<string>('19-yr');
  const [calcYearInterest, setCalcYearInterest] = useState<string>('2023');
  const [calcYearFirst, setCalcYearFirst] = useState<string>('1961');
  const [calcYearLatest, setCalcYearLatest] = useState<string>('2013');
  const [calcYearToday, setCalcYearToday] = useState<string>('2023');
  const [calcYearFacilityDesign, setCalcYearFacilityDesign] = useState<string>('1961');
  const [calcDesign, setCalcDesign] = useState<string>('12.00');
  const [calcReInterval, setCalcReInterval] = useState<string>('29-yr');
  const [calcDesignYearRe, setCalcDesignYearRe] = useState<string>('12.00');

  const [userSelectedLocation, setUserSelectedLocation] = useState<any>(null);
  const [userSelectedLocations, setUserSelectedLocations] = useState<any[]>([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [features, setFeatures] = useState({});
  const [featureData, setFeatureData] = useState<FeatureCollection>();
  const [reportData, setReportData] = useState<any[]>([]);
  const [reportStormData, setReportStormData] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [statusMessage, setStatusMessage] = useState<any>(null);
  const [layerStyle, setLayerStyle] = useState<any>([]);

  const [layerStylePoint, setLayerStylePoint] = useState<any>([]);
  const [mapExpressionPoint, setMapExpressionPoint] = useState<Expression>(['interpolate', ['linear'], ['get', selectedPointCategory], 0.849,
    "rgb(0, 104, 55)",
    0.935,
    "rgb(133, 203, 103)",
    0.97,
    "rgb(249, 247, 174)",
    1.01,
    "rgb(248, 141, 82)",
    1.19,
    "rgb(165, 0, 38)"]);

  const [qent, setqent] = useQueryParam('ent', BooleanParam);  //Risk report/Points switch
  const [qccds, setqccds] = useQueryParam('ccds', StringParam); //Dataset of points
  
  const [dataNASDAQArray, setDataNASDAQArray] = useState<any[]>([]);
  const [dataNASDAQArray_unfiltered, setDataNASDAQArray_unfiltered] = useState<any[]>([]);
  const [fillMap, setFillMap] = useState(qent ? false : true); //+++
  const [pointMap, setPointMap] = useState(qent ? true : false);
  const [interactiveLayers, setInteractiveLayers] = useState<any[]>(['pointlayer']);
  const [mapLegendPointData, setMapLegendPointData] = useState<any[]>([]);
  const [pointAccess, setPointAccess] = useState(qent);
  const [processWhenReadyFlag, setProcessWhenReadyFlag] = useState(false);

  const [updateHuc, setUpdateHuc] = useState({} as updateHuc);
  const chartRef = useRef<any>();
  const chartRefStorm = useRef<any>();
  const [locationsLoaded, setLocationsLoaded] = useState(false);
  const [currentDataset, setCurrentDataset] = useState("");
  const [currentDatasetLabel, setCurrentDatasetLabel] = useState("");
  const { setCurrentRef } = useContext(RefContext);
  const reportBounds = mapRef.current ? mapRef.current.getMap().getBounds().toArray().flat() : null;

  useEffect(() => {
    setCurrentRef?.(mapRef)
  }, [mapRef])

  const [data, setData] = useState({
    labels: [] as any,
    datasets: [] as any,
  } as const);

  const [dataStorm, setDataStorm] = useState({
    labels: [] as any,
    datasets: [] as any,
  } as const);

  const [config, setConfig] = useState({} as const);
  const [configStorm, setConfigStorm] = useState({} as const);

  const [isDataReportLoaded, setIsDataReportLoaded] = useState(false);
  const [isTableSort, setTableSort] = useState(true);
  const FullScreenComp = FullScreen as any;
  const fullscreenhandle = useFullScreenHandle() as any;
  const [isFullScreenActive, setFullScreenActive] = useState(false);
  const [isFullScreenChartActive, setFullScreenChartActive] = useState(false);
  const [isFullScreenChartStormActive, setFullScreenChartStormActive] = useState(false);
  const fullscreenContainerHandler = () => { setFullScreenActive(!isFullScreenActive); };
  const fullscreenChartContainerHandler = () => { setFullScreenChartActive(!isFullScreenChartActive); };
  const fullscreenChartStormContainerHandler = () => { setFullScreenChartStormActive(!isFullScreenChartStormActive); };

  const yearFilter = ['Current', '2022', '2030', '2040', 'NOAA Year', 'Year Built'];

  const reportTypes = ['2yr', '5yr', '10yr', '25yr', '50yr', '100yr'];
  const recurTypes = [1930, 1940, 1950, 1960, 1970, 1980, 1990, 2000, 2010];
  const chartFields = ['Extreme Precipitation Events'];
  const yearsDesign = [] as any;
  for (var i = 1961; i <= 2021; i++) {
    yearsDesign.push(i);
  }
  const yearsInterest = [] as any;
  for (var i = 2022; i <= 2040; i++) {
    yearsInterest.push(i);
  }

  useEffect(() => {
    global.setfilter1Selected('100yr'); //+++
    //global.setfilterDaysSelected(0);
    global.setfilterLocationsSelected([]);
    if (global.trackerOn) global.setTrackerOn(false)       
  }, []);

  let rainfall1960 = [] as any;
  useEffect(() => {
    const statesFileURL = "/climatesasb/1960S RAINFALL ZIP.csv";
    fetch(statesFileURL)
      .then((response) => response.text())
      .then((responseText) => {
        const data: any[] = parse(responseText, {
          header: true,
        }).data;
        rainfall1960.push(data)
        setData1960Array(data)
      });
  }, []);

  useEffect(() => {
    const statesFileURL = "/climatesasb/NOAA_Zips.csv";
    fetch(statesFileURL)
      .then((response) => response.text())
      .then((responseText) => {
        const data: any[] = parse(responseText, {
          header: true,
        }).data;
        setDataNOAAArray(data)
      });
  }, []);

/*  useEffect(() => {
    const fipsFileURL = "/county_fips_master.csv";
    fetch(fipsFileURL)
      .then((response) => response.text())
      .then((responseText) => {
        const data: any[] = parse(responseText, {
          header: true,
        }).data;
        setFipsDataArray(data);
      });
  }, []);

*/

  useEffect(() => {
    if (global.userOrg !== 2 || global.userRole === 'admin' || global.userRole === 'te-admin') {
      //setPointAccess(false); //+++
    }
  }, [global.userOrg]);

  useEffect(() => {
    if(!qent){
      setFillMap(true);
      setPointAccess(true);
      
    } else if(qent){
      setPointAccess(true);
      setFillMap(false);
    }
  }, [qent]);


  //++ Datasets
  useEffect(() => {
    if (pointAccess) {
      let pointsFileURL = ""; ///nasdaq_geo_enc.csv
      const qOrganizationConfig = OrganizationConfig.find(x => x.ccds === qccds);
      if(qOrganizationConfig){
          pointsFileURL = qOrganizationConfig.csv;
        setCurrentDatasetLabel(qOrganizationConfig.org_label);
      } else {
        setCurrentDatasetLabel(''); 
      }

      if (currentDataset !== pointsFileURL) {
        setCurrentDataset(pointsFileURL);
        fetch(pointsFileURL)
          .then((response) => response.text())
          .then((responseText) => {
            const data: any[] = parse(responseText, {
              header: true,
            }).data;
            
            global.setCustomLocations(data); //processLocations(data);
          });
      }
    }
  }, [pointAccess, qccds]);

  useEffect(() => {
    if (pointAccess && global.customLocations && global.customLocations.length > 0) {
      if (!dataNOAAArray || !data1960Array || dataNOAAArray.length === 0 || data1960Array.length === 0) {
        //setProcessWhenReadyFlag(true)   
        processLocations(global.customLocations);
        setLocationsLoaded(true)
      } else {
        processLocations(global.customLocations);
        setLocationsLoaded(true)
      }
    } else if (pointAccess && global.customLocations && global.customLocations.length === 0) {
      processLocations(global.customLocations);
      setLocationsLoaded(false)
    }
    if (!global.customLocations || global.customLocations.length === 0) {
      setCurrentDatasetLabel('');
    }
  }, [global.customLocations]);

  useEffect(() => {
    if (processWhenReadyFlag) {
      setLocationsLoaded(true)
      setProcessWhenReadyFlag(false)
      processLocations(global.customLocations);
    }
   
    if(global.customLocations && global.customLocations.length>0){
       processLocations(global.customLocations);     
    }

    updateCalculator();
    const stateData = dataNOAAArray;//.filter((o:any) => o["State"] === selectedState);
    //const extentLevel = d3Array.extent<any, any>(returnedData, d => +d["Meanmrl"]);

    createMapStops(stateData, selectedState, selectedCategory, 'gKey');//+++
  }, [dataNOAAArray]);

  useEffect(() => {
    updateCalculator();
  }, [data1960Array]);

  useEffect(() => {
    if (updateHuc) {
      if (updateHuc["index"]) {
        const dnu = dataNASDAQArray_unfiltered;
        const dn = dataNASDAQArray;
        const hucMatchu = dataNASDAQArray_unfiltered.filter((o: any) => o["index"] === updateHuc["index"]);
        if (hucMatchu && hucMatchu.length > 0) {
          hucMatchu[0].HUC8 = updateHuc["HUC8"];
        }
        const hucMatch = dataNASDAQArray_unfiltered.filter((o: any) => o["index"] === updateHuc["index"]);
        if (hucMatch && hucMatch.length > 0) {
          hucMatch[0].HUC8 = updateHuc["HUC8"];
        }
        setDataNASDAQArray(dn)
        setDataNASDAQArray_unfiltered(dnu)
      }
    }
  }, [updateHuc]);


  const colorScalePoints = (evalValue: number) => {
    const colorValue = d3ScaleChromatic.interpolateRdYlGn(evalValue);
    return colorValue;
  }

  const getHUC = (lat: any, lng: any, dataItem: any) => {
    if(lat && lng){
        return agent.Utilities.LatLngToHuc8(lat.replaceAll(' ', ''), lng.replaceAll(' ', ''))
          .then((res: any) => {
            const responseBody = res.body;
            if(res.errorMessage){
              console.log(res.errorMessage)
            }
            if(responseBody && responseBody.length>0 && responseBody[0].Huc8){
                const responseHUC8 = responseBody[0].Huc8;
                dataItem.HUC8 = responseHUC8;
            } else {
              console.log('no HUC8 found')
            }
          })
    }
  }

  const processLocations = (data: any) => {
    i = 0;
    console.log(data)
    for (let dataItem of data) {
      dataItem.index = i;
      i++;

      let flagContinue = true;

      //++ Geocode
      /* const input = `${dataItem["Location_Address"]} ${dataItem["City"]} ${dataItem["State"]} ${dataItem["Zip_Code"]}`
       let URL = `https://api.mapbox.com/geocoding/v5/mapbox.places/${input}.json?&&country=US&limit=3&access_token=${settings.maboxKey}`;
        axios.get(URL)
      .then((response) => {
       if(response.status === 200 && response.data && response.data.features && response.data.features[0]&& response.data.features[0].center){
         console.log(response.data.features[0].center)
         dataItem["center"] = response.data.features[0].center;
       }
       });*/


      //++ Data Cleaning
      if(!dataItem["Location_Name [character varying]"] && !dataItem["Location_Name"]){
        console.log(dataItem)
      }
      dataItem.name = dataItem["Location_Name [character varying]"];
      if (!dataItem["Location_Name [character varying]"]) {
        dataItem.name = dataItem["Location_Name"];
      }

      if (dataItem["Latitude"]) {
        dataItem["Latitude"] = dataItem["Latitude"].replace(/\s/g, '');
      }  

      if (dataItem["Longitude"]) {
        dataItem["Longitude"] = dataItem["Longitude"].replace(/\s/g, '');
      }  

      if (!dataItem["HUC8"]) {
        getHUC(dataItem["Latitude"], dataItem["Longitude"], dataItem);
      }

      if (dataItem["HUC8"] && dataItem["HUC8"].length === 7) {
        dataItem["HUC8"] = '0' + dataItem["HUC8"]
      }

      if (dataItem["Year_Built"] && !dataItem["Year Built"]) {
        dataItem["Year Built"] = dataItem["Year_Built"];
      } else if (!dataItem["Year_Built"] && dataItem["Year Built"]) {
        dataItem["Year_Built"] = dataItem["Year Built"];
      }

      if (dataItem["atrisk"]) {
        dataItem["atrisk"] = +dataItem["atrisk"];
      }

      if (!dataItem["Latest NOAA Data Year"] && dataItem["Zip_Code"]) {
        const zipData = dataNOAAArray.filter((o: any) => +o["zip_code"] === +dataItem["Zip_Code"]); //calcYearLatest
        if (zipData && zipData.length > 0 && zipData[0]["NOAA Year"]) {
          const NOAAyear = zipData[0]["NOAA Year"];
          dataItem["Latest NOAA Data Year"] = zipData[0]["NOAA Year"];
          setCalcYearLatest(NOAAyear);
          //const _2011Data = newdataset.filter((o: any) => +o["year"] === 2011);
          /*           for (let dataItem of _2011Data) {
                      dataItem["year"] = NOAAyear;
                    } */
        } else {
          flagContinue = false;
          console.log('CC: No Latest NOAA Data Year', dataItem["Zip_Code"], dataItem["HUC8"]);
        }
      }

      if(flagContinue){
            //++ Calculate
            let calculatorResults = updateCalculator(dataItem["Zip_Code"]);
            //console.log(calculatorResults)
            if (!calculatorResults || !calculatorResults.county || calculatorResults.county === "" || !calculatorResults.CalcYearLatest) {
              //console.log(calculatorResults.length, calculatorResults.county, calculatorResults, dataItem["Zip_Code"],  dataItem, dataItem["State"])
              flagContinue = false;
              dataItem["ERROR"] = 'possibly Zip Code not supported. ' + dataItem["Zip_Code"] + ' ' + calculatorResults;  //AK, MD, CT, VT, MA
            } else {
              dataItem["CalcDesignIntensity"] = calculatorResults["CalcDesignIntensity"];
              dataItem["CalcYearInterestIntensity"] = calculatorResults["CalcInterestIntensity"];
              dataItem["CalcYearInterestRecurrence"] = calculatorResults["CalcInterestRecur"];
              dataItem["CalcNOAAYearLatest"] = calculatorResults["CalcYearLatest"];
              dataItem["CalcLatestNOAAYearIntensity"] = calculatorResults["CalcLatestIntensity"];
              dataItem["CalcLatestNOAAYearRecur"] = calculatorResults["CalcLatestRecur"];
              dataItem["CalcTodayIntensity"] = calculatorResults["CalcTodayIntensity"];
              dataItem["CalcTodayRecurrence"] = calculatorResults["CalcTodayRecur"];
      
              dataItem["atrisk"] = +dataItem["CalcLatestNOAAYearIntensity"] / +dataItem["CalcYearInterestIntensity"];
      
              if ((+dataItem["CalcNOAAYearLatest"] - +dataItem["Year Built"]) === 0) {
                dataItem["ratechange"] = 0;
              } else {
                dataItem["ratechange"] = (+dataItem["CalcLatestNOAAYearIntensity"] - +dataItem["CalcDesignIntensity"]) / (+dataItem["CalcNOAAYearLatest"] - +dataItem["Year Built"]);
              }
      
              dataItem["Current Intensity"] = +dataItem["Current Intensity"];
              dataItem["Intensity in 2022"] = +dataItem["Intensity in 2022"];
              dataItem["Intensity in 2030"] = +dataItem["Intensity in 2030"];
              dataItem["Intensity in 2040"] = +dataItem["Intensity in 2040"];
              dataItem["Intensity in NOAA Year"] = +dataItem["Intensity in NOAA Year"];
              dataItem["Intensity in Year Built"] = +dataItem["Intensity in Year Built"];
              if (dataItem["Intensity in Year Built"] && dataItem["Intensity in 2022"]) {
                dataItem["atrisk"] = +dataItem["Intensity in 2022"] / +dataItem["Intensity in Year Built"];
              }
              if (dataItem["Intensity in Year Built"]) {
                if ((+dataItem["Latest NOAA Data Year"] - +dataItem["Year Built"]) === 0) {
                  dataItem["ratechange"] = 0;
                } else {
                  dataItem["ratechange"] = (+dataItem["Intensity in NOAA Year"] - +dataItem["Intensity in Year Built"]) / (+dataItem["Latest NOAA Data Year"] - +dataItem["Year Built"]);
                }
              }
            }
          }
          if(dataItem["atrisk"] === ''){
            delete dataItem["atrisk"];
          }
    }


    //++ Legend, Scales, Map expression
    const newLocations = uniqueValues(data, 'name');
    const siteFilter = newLocations.map((x: any, index: number) => ({ huc12: x, hucname: x }));
    global.setfilterLocationsPopulated([{ huc12: 'All', hucname: 'All' }, ...siteFilter]);

    const yearExtent1 = d3Array.extent<any, any>(data, d => +d['atrisk']);
    let yearExtent = [yearExtent1[0], yearExtent1[1]];
    const pointsExtent = yearExtent;
    const legendNormalizedScale = d3Scale.scaleLinear()
      .domain([0, 1])
      .range([pointsExtent[0], pointsExtent[1]]);
    const legendScale = d3Scale.scaleLinear()
      .domain([pointsExtent[0], pointsExtent[1]])
      .range([0, 1]);
  
    const legenddata: any[] = [];
    legenddata.push({ typecode: d3Format.format(".3r")(legendNormalizedScale(1)), color: colorScalePoints(0) })
    legenddata.push({ typecode: d3Format.format(".3r")(legendNormalizedScale(.75)), color: colorScalePoints(.25) })
    legenddata.push({ typecode: d3Format.format(".3r")(legendNormalizedScale(.5)), color: colorScalePoints(.5) })
    legenddata.push({ typecode: d3Format.format(".3r")(legendNormalizedScale(.25)), color: colorScalePoints(.75) })
    legenddata.push({ typecode: d3Format.format(".3r")(legendNormalizedScale(0)), color: colorScalePoints(1) })

    let matchExpressionZip = ['interpolate', ['linear'], ['get', selectedPointCategory]] as Expression;
    let sortset = [...legenddata];
    sortset = sortset.sort((x: any, y: any) => x['typecode'] - (y['typecode']));

    let previousValue = 0;
    for (let dataItem of sortset) {
      if (+dataItem.typecode !== previousValue) {
        matchExpressionZip.push(+dataItem.typecode);
        matchExpressionZip.push(dataItem.color);
      }
      previousValue = +dataItem.typecode;
    }

    if(yearExtent && yearExtent[1]){
      setMapLegendPointData(legenddata);
      setMapExpressionPoint(matchExpressionZip);
      updateLayerPoints(matchExpressionZip);
    }


    //++ update data
    setDataNASDAQArray([...data])
    setDataNASDAQArray_unfiltered([...data])


    //++ download processed csv file
/*         const csv = unparse(data);
        let hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
        hiddenElement.target = '_blank';
        hiddenElement.download = 'aaa' +'.csv';
        hiddenElement.click(); 
        if (hiddenElement.parentNode) {  
          hiddenElement.parentNode.removeChild(hiddenElement);
        }
        hiddenElement.remove();*/

    /* 


    "STATEFP":"72","COUNTYFP":"119","COUNTYNS":"01804540","AFFGEOID":"0500000US72119","GEOID":"72119",
    "NAME":"Río Grande","NAMELSAD":"Río Grande Municipio","STUSPS":"PR","STATE_NAME":"Puerto Rico",
    "LSAD":"13","ALAND":"157008569","AWATER":"74876349"
                console.log(data)
                let noMatchCounter = 0;
                let noMatchCounter_1960 = 0;
    
                dataCounties.features.forEach(function(d, i) {
                  d.properties = {"county" : d.properties["COUNTYFP"],
                  "name" : d.properties["name"],
                   "County FIPS" : d.properties["COUNTYFP"],
                    "State FIPS" : d.properties["STATEFP"], 
                  "NAMELSAD" : d.properties["NAMELSAD"], 
                   "State" : d.properties["STATE_NAME"]}
    
                        d.properties["1yr" + "_NOAA"] = -1;
                        d.properties["2yr" + "_NOAA"] = -1;
                        d.properties["5yr" + "_NOAA"] = -1;
                        d.properties["10yr" + "_NOAA"] = -1;
                        d.properties["25yr" + "_NOAA"] = -1;
                        d.properties["50yr" + "_NOAA"] = -1;
                        d.properties["100yr" + "_NOAA"] = -1;
                        d.properties["200yr" + "_NOAA"] = -1;
                        d.properties["500yr" + "_NOAA"] = -1;
                        d.properties["1000yr" + "_NOAA"] = -1;
    
                      let fData = dataNOAA.filter(function(f) {
                        return (f["County FIPS"] === d.properties["COUNTYFP"]);
                      })
    
                      if(fData && fData.length > 0){
                        d.properties["CountyState"] = fData[0]["County"];
                        d.properties["NOAA Year"] = +fData[0]["NOAA Year"];                
                        d.properties["1yr" + "_NOAA"] = +fData[0]["1yr"];
                        d.properties["2yr" + "_NOAA"] = +fData[0]["2yr"];
                        d.properties["5yr" + "_NOAA"] = +fData[0]["5yr"];
                        d.properties["10yr" + "_NOAA"] = +fData[0]["10yr"];
                        d.properties["25yr" + "_NOAA"] = +fData[0]["2yr"];
                        d.properties["50yr" + "_NOAA"] = +fData[0]["50yr"];
                        d.properties["100yr" + "_NOAA"] = +fData[0]["100yr"];
                        d.properties["200yr" + "_NOAA"] = +fData[0]["200yr"];
                        d.properties["500yr" + "_NOAA"] = +fData[0]["500yr"];
                        d.properties["1000yr" + "_NOAA"] = +fData[0]["1000yr"];
                      } else {
                        noMatchCounter = noMatchCounter + 1;
                      }
    
                })  
     */
  }

/*  const updateLegend = (rData: any, groupKey: string) => {
    const slCategory = '';
    const groupedresultsRaw = groupByKey<any, any>(rData, i => i[groupKey]);
    const site_data: any[] = [];
    for (const key in groupedresultsRaw) {
      const sumVal = d3Array.rollups(
        groupedresultsRaw[key],
        xs => d3Array.sum(xs, x => x[slCategory]),
        d => key
      )
      site_data.push(sumVal[0][1]);
    }
    const pointsExtent = d3Array.extent<any, any>(site_data, d => d);
    const legendScale = d3Scale.scaleLinear()
      .domain([0, 1])
      .range([pointsExtent[0], 88]);

    const legenddata: any[] = [];
    legenddata.push({ typecode: d3Format.format(".1r")(pointsExtent[0]), color: colorScale(0) })
    legenddata.push({ typecode: d3Format.format(".1r")(legendScale(.25)), color: colorScale(.25) })
    legenddata.push({ typecode: d3Format.format(".1r")(legendScale(.5)), color: colorScale(.5) })
    legenddata.push({ typecode: d3Format.format(".1r")(legendScale(.75)), color: colorScale(.75) })
    legenddata.push({ typecode: d3Format.format(".1r")(pointsExtent[1]), color: colorScale(1) })
  
    setMapLegendData(legenddata);
  }*/

  useEffect(() => {
    global.setUserLogged(true);
    initializeMapStops();
  }, []);

  useEffect(() => {
    let matchExpressionFilter = ['in', 'county'] as Expression;
    updateLayerS(matchExpressionFilter, mapExpression);
    updateLayerPoints(mapExpressionPoint);

    /*     if(pointMap){ 
          global.setSelectedYearFilterOptions(yearFilter);
        } */

        //console.log(fillMap, pointMap, reportData, mapExpression)
  }, [fillMap, pointMap]);

  useEffect(() => {
    let filteredData = [...dataNASDAQArray_unfiltered];
    if (
      selectedFilterLocations &&
      selectedFilterLocations.length > 0 &&
      selectedFilterLocations.indexOf('All') < 0
    ) {
      filteredData = filteredData.filter(
        (o: any) => selectedFilterLocations.indexOf(o['Location_Name [character varying]']) > -1
      );
    } else {
      setDataNASDAQArray([...dataNASDAQArray_unfiltered]);
    }
    if (filteredData.length > 0) {
      setDataNASDAQArray(filteredData);
    }
  }, [selectedFilterLocations]);



  useEffect(() => {
    if (dataNASDAQArray) {
      //console.log(dataNASDAQArray)
      const newFeatureSet = newFeatureCollection([...dataNASDAQArray]);
      setFeatureData(newFeatureSet);
      if (newFeatureSet.features.length > 0) {
        if (mapRef && mapRef.current) {
          const mapRef_ = mapRef;
          setTimeout(() => {
            fitBoundsMapData(newFeatureSet, mapRef_);
          }, 400);
        }
      }
    }
  }, [dataNASDAQArray]);

  const initializeMapStops = () => {
    let matchExpressionZip = ['match', ['get', 'county']] as Expression;
    let matchExpressionFilter = ['in', 'county'] as Expression;
    matchExpressionZip.push(0);
    matchExpressionZip.push('rgba(0,0,0,0)');
    matchExpressionZip.push('rgba(0,0,0,0)');
    setMapExpression(matchExpressionZip);
    updateLayerS(matchExpressionFilter, matchExpressionZip);
  }

  const colorScale = (evalValue: number) => {
    const colorValue = d3ScaleChromatic.interpolateBlues(evalValue);
    return colorValue;
  }

  const createMapStops = (rData: any, selState: string, selCategory: string, groupKey: string) => {
    let matchExpressionZip = ['match', ['get', 'county']] as Expression;
    let matchExpressionFilter = ['in', 'county'] as Expression;

    const yearExtent1 = d3Array.extent<any, any>(rData, d => +d['1yr']);
    const yearExtent100 = d3Array.extent<any, any>(rData, d => +d['100yr']);
    //const yearExtent1000 = d3Array.extent<any, any>(rData, d => +d['1000yr']);

    const yearExtent = [yearExtent1[0], yearExtent100[1]];//d3Array.extent<any, any>(rData, d => +d[selectedCategory]);
    //[yearExtent1[0], yearExtent1000[1]]; //d3Array.extent<any, any>(rData, d => +d[selectedCategory]);

    let cfips = [] as any;
    if (rData.length > 0) {
      let gKey = 'County FIPS';
      const pointsExtent = yearExtent;
      const legendNormalizedScale = d3Scale.scaleLinear()
        .domain([0, 1])
        .range([pointsExtent[0], pointsExtent[1]]);

      const legendScale = d3Scale.scaleLinear()
        .domain([pointsExtent[0], pointsExtent[1]])
        .range([0, 1]);

      for (let dataItem of rData) {
        //matchExpressionFilter.push(+dataItem.fips);
        if (cfips.indexOf(dataItem["County FIPS"]) < 0) {
          const colorValue = colorScale(legendScale(dataItem[selectedCategory]));
          if (!isNaN(+dataItem["County FIPS"])) {
            matchExpressionZip.push(+dataItem["County FIPS"]);
            matchExpressionZip.push(colorValue);
          }
        }
        cfips.push(dataItem["County FIPS"]);
      }

      matchExpressionZip.push('rgba(0,0,0,0)');
      setMapExpression(matchExpressionZip);

      const legenddata: any[] = [];
      legenddata.push({ typecode: d3Format.format(".3r")(pointsExtent[0]), color: colorScale(0) })
      legenddata.push({ typecode: d3Format.format(".3r")(legendNormalizedScale(.25)), color: colorScale(.25) })
      legenddata.push({ typecode: d3Format.format(".3r")(legendNormalizedScale(.5)), color: colorScale(.5) })
      legenddata.push({ typecode: d3Format.format(".3r")(legendNormalizedScale(.75)), color: colorScale(.75) })
      legenddata.push({ typecode: d3Format.format(".3r")(pointsExtent[1]), color: colorScale(1) })
      setMapLegendData(legenddata);

      setTimeout(() => {
        updateLayerS(matchExpressionFilter, matchExpressionZip);
      }
        , 100);
    }
  }

  useEffect(() => {
    const chartdata = updateBarChartCamelData(reportData, 'county', 'interval');
    setData(chartdata);
    const chartdataStorm = updateBarChartStormData(reportStormData, 'county', 'interval');
    setDataStorm(chartdataStorm);
    const stateData = dataNOAAArray;
    if (stateData && stateData.length > 0) {
      setTimeout(() => {
        createMapStops(stateData, selectedState, selectedCategory, 'gKey');
      }
        , 100);
    }
  }, [selectedCategory]);


  useEffect(() => {
    setIsDataReportLoaded(false);
    let huc8geo = getCurrentHuc8LongLat(appContext.selectedHUC8, catalogsContext.huc8Catalog);
    //console.log(selectedHUC8Value, huc8geo, appContext, global.searchCenter, appContext.selectedHUC8, catalogsContext.huc8Catalog)
    const pointsExtent = [0.00, 12.00];
    const legendScale = d3Scale.scaleLinear()
      .domain([0, 1])
      .range([pointsExtent[0], pointsExtent[1]]);
    const legenddata: any[] = [];
    legenddata.push({ typecode: d3Format.format(".3r")(pointsExtent[0]), color: colorScale(0) })
    legenddata.push({ typecode: d3Format.format(".3r")(legendScale(.25)), color: colorScale(.25) })
    legenddata.push({ typecode: d3Format.format(".3r")(legendScale(.5)), color: colorScale(.5) })
    legenddata.push({ typecode: d3Format.format(".3r")(legendScale(.75)), color: colorScale(.75) })
    legenddata.push({ typecode: d3Format.format(".3r")(pointsExtent[1]), color: colorScale(1) })
    setMapLegendData(legenddata);

    if (!huc8geo) {
      huc8geo = { huc8: '11111111', centroid_latitude: '29.749907', centroid_longitude: '-95.358421' };
    }

    if(global.searchCenter && global.searchCenter.length===2){
      huc8geo.centroid_latitude = global.searchCenter[0];
      huc8geo.centroid_longitude = global.searchCenter[1];     
    }

    if (huc8geo && huc8geo.centroid_latitude) {
      agent.Reports.ClimatePrecipitationCalculator(+huc8geo.centroid_latitude, +huc8geo.centroid_longitude, "camel_data").then((res: any) => {
        if (!qent) { flyToLocation(huc8geo); }
        const returnedData = res.body;

        if (res && res.body && res.body.length > 0 && res.body!=='Unauthorized') {
          setSelectedCounty(returnedData[0].county)
          setReportData(res.body);

          const chartdata = updateBarChartCamelData(returnedData, 'county', 'interval');
          setData(chartdata);
        } 

      });

      agent.Reports.ClimatePrecipitationCalculator(+huc8geo.centroid_latitude, +huc8geo.centroid_longitude, "storm_data").then((res: any) => {
        const returnedData = res.body;
        setIsDataReportLoaded(true);

        if (reportTypes.indexOf(global.filter1Selected) < 0) {
          global.setfilter1Populated(reportTypes);
          global.setfilter1Selected(reportTypes[0]);
          selectedCategory = reportTypes[0];
        }

        if (res && res.body && res.body.length > 0 && res.body!=='Unauthorized') {
          setSelectedCounty(returnedData[0].county)
          setReportStormData(res.body);
          setSelectedZip(returnedData[0].zip_code);

          const chartdataStorm = updateBarChartStormData(returnedData, 'county', 'interval');
          setDataStorm(chartdataStorm);
        } 


      });
    }
  }, [selectedHUC8Value]);


  const updateBarChartCamelData = (filterData: any, groupKey: string, selCategory: string) => {
    if (!filterData || filterData.length < 1) {
      return {
        labels: [],
        datasets: [],
        borderColor: '#ff6384'
      };
    }

    let gKey = groupKey;
    let fDataset = [...filterData];
    const sortField = 'intervalint';
    const keyField = 'month';
    const dataField = 'year';

    const chartdata_data: any[] = [];
    let chartdata_labels: any[] = [];
    const site_data: any[] = [];
    const newdataset: any[] = [];

    let county = selectedCounty;
    if (fDataset && fDataset.length > 0 && fDataset[0].county) {
      county = fDataset[0].county;
    }
    //setChartConfig(county);
    setChartConfig(county, selectedCategory, setConfig);
    setChartConfigStorm(setConfigStorm);

    for (let dataItem of fDataset) {
      for (const key in dataItem) {
        if ((key !== 'county') && (key !== 'zip_code')) {
          const keySplit = key.split('_');
          const monthCap = keySplit[5].charAt(0).toUpperCase() + keySplit[5].slice(1);
          newdataset.push({ yearStart: +keySplit[1], yearEnd: +keySplit[2], intervalint: +(keySplit[3].split('yr')[0]), interval: keySplit[3], month: monthCap, chartvalue: dataItem[key] });
        }
      }
    };

    const dataset = newdataset.sort((x: any, y: any) => x[sortField] - (y[sortField])).filter((o: any) => o["interval"] === selectedCategory);
    const groupedresults = groupByKey<any, any>(dataset, i => i['yearStart']);
    const monthLabels = newdataset.map((item: any) => item['month'])
      .filter((value: any, index: any, self: any) => self.indexOf(value) === index)
      .filter((value: any, index: any, self: any) => value);

    for (  const key in groupedresults) {
      const chartValueExtent1 = d3Array.extent<any, any>(groupedresults[key], d => +d['chartvalue']);
      if (chartValueExtent1 && chartValueExtent1.length > 1 && chartValueExtent1[1] !== -1) {
        const colorMatch = d3ScaleChromatic.schemePaired[recurTypes.indexOf(+key)];
        let labelGroup = groupedresults[key][0]["yearStart"] + ' - ' + groupedresults[key][0]["yearEnd"];
        let colorLine = colorMatch;
        let dataObject = {
          label: labelGroup,
          data: groupedresults[key].map((x: any) => x.chartvalue),
          borderColor: colorLine,
          backgroundColor: colorLine,
          tension: .3,
        }
        chartdata_data.push(dataObject);
        chartdata_labels.push(key);
      }

    }

    const chartdata = {
      labels: monthLabels,
      datasets: chartdata_data,
      borderColor: '#ff6384'
    }

    return chartdata;
  }



  const updateBarChartStormData = (filterData: any, groupKey: string, selCategory: string) => {
    if (!filterData || filterData.length < 1) {
      return {
        labels: [],
        datasets: [],
        borderColor: '#ff6384'
      };
    }

    let gKey = groupKey;
    const sortField = 'intervalint';
    const keyField = 'interval';
    const dataField = 'year';
    let fDataset = [...filterData];

    const chartdata_data: any[] = [];
    let chartdata_labels: any[] = [];
    const site_data: any[] = [];
    const newdataset: any[] = [];

    let zip = selectedCounty;
    if (fDataset && fDataset.length > 0 && fDataset[0].zip_code) {
      zip = fDataset[0].zip_code;
      setSelectedZip(zip);
    }

    updateCalculator();
    for (let dataItem of fDataset) {
      for (const key in dataItem) {
        if ((key !== 'county') && (key !== 'zip_code')) {
          const keySplit = key.split('_');
          newdataset.push({ year: +keySplit[2], intervalint: +(keySplit[1].split('yr')[0]), interval: keySplit[1], chartvalue: dataItem[key] });
        }
      }
    };

    const zipData = dataNOAAArray.filter((o: any) => +o["zip_code"] === +zip); //calcYearLatest


    if (zipData && zipData.length > 0 && zipData[0]["NOAA Year"]) {
      const NOAAyear = zipData[0]["NOAA Year"];
      const _2011Data = newdataset.filter((o: any) => +o["year"] === 2011);
      for (let dataItem of _2011Data) {
        dataItem["year"] = NOAAyear;
      }
    }

    const dataset = newdataset.sort((x: any, y: any) => x[sortField] - (y[sortField]));
    const groupedresults = groupByKey<any, any>(dataset, i => i[keyField]);
    const intervalLabels = newdataset.map((item: any) => item[dataField])
      .filter((value: any, index: any, self: any) => self.indexOf(value) === index)
      .filter((value: any, index: any, self: any) => value);

    for (const key in groupedresults) {
      let labelGroup = key;
      const colorMatch = d3ScaleChromatic.schemePaired[reportTypes.indexOf(key)];
      let colorLine = colorMatch;
      let dataObject = {
        label: labelGroup,
        data: groupedresults[key].map((x: any) => x.chartvalue),
        borderColor: colorLine,
        backgroundColor: colorLine,

      }
      chartdata_data.push(dataObject);
      chartdata_labels.push(key);
    }

    const chartdata = {
      labels: intervalLabels,
      datasets: chartdata_data,
      borderColor: '#ff6384'
    }

    return chartdata;
  }

  const updateCalculator = (zip = selectedZip) => {
    const county = selectedCounty;
    //+++
    let cDesign = 0;
    let cDesignLatest = 0;
    let c1960 = [] as any;

    let calculatorResult = [] as any;
    calculatorResult.zip = zip;
    calculatorResult.county = selectedCounty;

    if (data1960Array && data1960Array.length > 0 && zip) {
      //+++ leave out zip leading zero for 1960
      let d1960 = data1960Array.filter((o: any) => o["zip_code"] === zip.toString());
      if (d1960 && d1960.length > 0) {
        setCalcDesign(d1960[0][selectedCategory])
        calculatorResult.CalcDesign = d1960[0][selectedCategory];
        cDesign = +d1960[0][selectedCategory];
        c1960 = d1960;
      } else {
        console.log('zip not found: ' + zip)
        return null;
      }
    }

    if (dataNOAAArray && dataNOAAArray.length > 0 && zip) {
      let zip_ = zip;
      if (zip.length < 5) {
        zip_ = '0' + zip;
      }

      let dNOAA = dataNOAAArray.filter((o: any) => o["zip_code"] === zip_.toString());
      //console.log(dataNOAAArray, zip, zip_, dNOAA )
      const yearLastData = dNOAA[0]['NOAA Year'];

      setCalcYearLatest(yearLastData)
      calculatorResult.CalcYearLatest = yearLastData;

      cDesignLatest = +dNOAA[0][selectedCategory];
      setCalcLatestIntensity(cDesignLatest.toString());
      calculatorResult.CalcLatestIntensity = cDesignLatest.toString();

      const rateDesign = (cDesignLatest - cDesign) / (+yearLastData - +calcYearFirst);
      const intensityDesign = cDesign + (+calcYearFacilityDesign - +calcYearFirst) * rateDesign;
      setCalcDesignIntensity(intensityDesign.toString());
      calculatorResult.CalcDesignIntensity = intensityDesign.toString();

      const rateToday = (cDesignLatest - cDesign) / (+yearLastData - +calcYearFirst);
      const intensityToday = cDesign + (+calcYearToday - +calcYearFirst) * rateToday;
      setCalcTodayIntensity(intensityToday.toString());
      calculatorResult.CalcTodayIntensity = intensityToday.toString();

      const rateInterest = (cDesignLatest - cDesign) / (+yearLastData - +calcYearFirst);
      const intensityInterest = cDesign + (+calcYearInterest - +calcYearFirst) * rateInterest;
      setCalcInterestIntensity(intensityInterest.toString());
      calculatorResult.CalcInterestIntensity = intensityInterest.toString();

      const latestRecur = calculateRecurrence(cDesignLatest, +calcYearLatest, c1960[0], dNOAA[0]);
      const todayRecur = calculateRecurrence(intensityToday, +calcYearToday, c1960[0], dNOAA[0]);
      const interestRecur = calculateRecurrence(intensityInterest, +calcYearInterest, c1960[0], dNOAA[0]);

      setCalcLatestRecur(latestRecur.toString() + '-yr');
      setCalcTodayRecur(todayRecur.toString() + '-yr');
      setCalcInterestRecur(interestRecur.toString() + '-yr');
      calculatorResult.CalcLatestRecur = latestRecur.toString() + '-yr';
      calculatorResult.CalcTodayRecur = todayRecur.toString() + '-yr';
      calculatorResult.CalcInterestRecur = interestRecur.toString() + '-yr';
    }

    return calculatorResult;
  }

  const updateLegend = (rData: any, groupKey: string) => {
    const slCategory = '';
    const groupedresultsRaw = groupByKey<any, any>(rData, i => i[groupKey]);
    const site_data: any[] = [];
    for (const key in groupedresultsRaw) {
      const sumVal = d3Array.rollups(
        groupedresultsRaw[key],
        xs => d3Array.sum(xs, x => x[slCategory]),
        d => key
      )
      site_data.push(sumVal[0][1]);
    }
    const pointsExtent = d3Array.extent<any, any>(site_data, d => d);
    const legendScale = d3Scale.scaleLinear()
      .domain([0, 1])
      .range([pointsExtent[0], 88]);

    const legenddata: any[] = [];
    legenddata.push({ typecode: d3Format.format(".1r")(pointsExtent[0]), color: colorScale(0) })
    legenddata.push({ typecode: d3Format.format(".1r")(legendScale(.25)), color: colorScale(.25) })
    legenddata.push({ typecode: d3Format.format(".1r")(legendScale(.5)), color: colorScale(.5) })
    legenddata.push({ typecode: d3Format.format(".1r")(legendScale(.75)), color: colorScale(.75) })
    legenddata.push({ typecode: d3Format.format(".1r")(pointsExtent[1]), color: colorScale(1) })
  
    setMapLegendData(legenddata);
  }

  const flyToLocation = (currentGeoPoint: any) => {
    if (mapRef.current) {
      mapRef.current?.flyTo({
        center: [
          parseFloat(currentGeoPoint.centroid_longitude),
          parseFloat(currentGeoPoint.centroid_latitude),
        ],
        essential: true,
        zoom: 8,
      });
    }
  }

  

  const onChartClickHandler = (event: any) => {
    const currentClick = getElementAtEvent(chartRef.current, event)[0];
    if (currentClick) {

    }
  }


  const newFeatureCollection = (fData: any) => {
    const features: Array<Feature<Point, GeoJsonProperties>> = [];
    for (let dataItem of fData) {
      if (dataItem) {
        let huc12Item = dataItem;
        if (huc12Item) {
          const featureWithPoint: Feature<Point> = {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [+huc12Item.Longitude, +huc12Item.Latitude],
            },
            properties: dataItem,
          };
          if (featureWithPoint && featureWithPoint.properties) {
            featureWithPoint.properties.lat = huc12Item.Latitude;
            featureWithPoint.properties.lng = huc12Item.Longitude;
          }
          features.push(featureWithPoint);
        }
      }
    }

    const featureCollectionFromReportData: FeatureCollection = {
      type: 'FeatureCollection',
      features: features,
    };
    return featureCollectionFromReportData;
  };

  const updateLayerPoints = (mapExp: any) => {
    const newLayerStyle: LayerProps = {
      id: 'pointlayer',
      type: 'circle' as const,
      layout: { 'visibility': (pointMap) ? 'visible' : 'none' },
      paint: {
        'circle-radius': 10,
        'circle-color': mapExp,
        'circle-stroke-color': 'white',
        'circle-stroke-width': 1,
        'circle-opacity': 0.8,
      },
    }
    setLayerStylePoint(newLayerStyle);
  }

  const hoverLayerStyle: LayerProps = {
    id: 'hoverlayer',
    type: 'circle' as const,
    paint: {
      'circle-radius': 12,
      'circle-color': '#faebd7',
      'circle-stroke-color': 'white',
      'circle-stroke-width': 2,
      'circle-opacity': 0.7,
    },
  };

  const updateLayerS = (mapFilterExp: any, mapExp: any) => {
    //console.log(mapExp)
    const newLayerStyle: LayerProps = {
      id: 'filllayer',
      'source-layer': 'state_county_population_2014_cen',
      type: 'fill' as const,
      beforeId: 'aerialway',
      layout: { 'visibility': (fillMap) ? 'visible' : 'none' },
      paint: {
        'fill-color': mapExp,
        'fill-opacity': 0.85
      }
    };
    setLayerStyle(newLayerStyle);
  }

  const populatePopup = (event: any | null) => {
    let newPopup = event.features[0].properties;
    let newLocation = event.features[0].properties[locationField];
    let newHover = event.features[0].properties[locationField];
    setCursor('pointer');
    newPopup.centroid_longitude = event.lngLat.lng;
    newPopup.centroid_latitude = event.lngLat.lat;

    /*     if (event.features[0].properties.isState) {
          if (newPopup.isState) {
            newPopup.type = 'state';
            newPopup.fips = newPopup['state'];
          }
        } else if (event.features[0].properties.isCounty) {
          const cName = newPopup["name"];
          const fData = dataNOAAArray.filter((o: any) => o["County"] === cName);
       
          if (fData.length > 0) {
            if (newPopup.isState) {
              newPopup.type = 'state';
              newPopup.fips = newPopup['state'];
            } else if (newPopup.isCounty) {
              newPopup.type = 'county';
              newPopup.fips = newPopup['county'];
            } else {
              newPopup.type = 'n/a';
            }
    
          }  
                            
        } */
    setPopupInfo(newPopup);
  }


  const setLocation = (feature: any, huc8: any) => {
    agent.Utilities.LatLngToCity(
      feature.properties.Latitude,
      feature.properties.Longitude
    ).then((data: any) => {
      const location = data.features[0].place_name
      updateContext?.(
        huc8,
        location,
        global.currentReport,
        'United States'
      )
      setTimeout(() => {
        updateCalculator();
      }
        , 555);
    })
  }

  const onHandleClick = useCallback((event: any | null) => {
    const feature = event.features && event.features[0];
    if (feature) {

      if (feature.properties && feature.properties["Year Built"]) {
        setCalcYearFacilityDesign(feature.properties["Year Built"]);
      }
      if (feature.properties && feature.properties["Year_Built"]) {
        setCalcYearFacilityDesign(feature.properties["Year_Built"]);
      }
      if (feature.properties && feature.properties["Zip_Code"]) {
        setSelectedZip(feature.properties["Zip_Code"]);
      }

      updateCalculator();

      if (!feature.properties.HUC8) {
        if(feature && feature.properties && feature.properties["Latitude"] && feature.properties["Longitude"]){
                return agent.Utilities.LatLngToHuc8(feature.properties["Latitude"], feature.properties["Longitude"])
                  .then((res: any) => {
                    const responseBody = res.body;
                    const responseHUC8 = responseBody[0].Huc8;
                    setLocation(feature, responseHUC8);
                  })
        }
      } else {
        setLocation(feature, feature.properties.HUC8);
      }
    }
  }, []);

  const onMouseEnter = useCallback((event: any | null) => {
    // if( pointMap ){
    const feature = event.features && event.features[0];
    if (feature) {
      populatePopup(event)
    }

    if (event.features && event.features[0] && event.features[0].properties) {
      setCursor('pointer');
      setPopupInfo(event.features[0].properties);
      setUserSelectedLocation(event.features[0].properties.name);
      setMapHoverFilter(['in', ['get', 'name'], event.features[0].properties.name]);
    }

    // }
  }, []);

  const onMouseLeave = useCallback((event: any | null) => {
    setCursor('auto');
    setPopupInfo(null);
    setMapHoverFilter(['in', ['get', 'name'], '']);
    if (userSelectedLocation) {
      setUserSelectedLocation(null);
    }

  }, []);

  const onMapLoad = useCallback(() => {
    if (mapRef && mapRef.current) {
      setMapLoaded(true);
      mapRef.current.resize();
    }
  }, []);

  const renderPopup = () => {
    return (
      <Popup
        longitude={Number(popupInfo.centroid_longitude)}
        latitude={Number(popupInfo.centroid_latitude)}
        onClose={() => setPopupInfo(null)}
      >
        <div className="popup-container">
          <h2><p style={{ maxWidth: 300, padding: 2 }}>{popupInfo["name"]}</p></h2>

          <div className="popup-content-row">
            <span className="popup-content-label">Address</span>
            <span className="popup-content-value">{popupInfo["Location_Address"]}</span>
          </div>

          <div className="popup-content-row">
            <span className="popup-content-label">Type</span>
            <span className="popup-content-value">{popupInfo["Type"]}</span>
          </div>

          <div className="popup-content-row">
            <span className="popup-content-label">Asset Class</span>
            <span className="popup-content-value">{popupInfo["Asset Class"]}</span>
          </div>

          <div className="popup-content-row">
            <span className="popup-content-label">Year Built</span>
            <span className="popup-content-value">{popupInfo["Year Built"]}</span>
          </div>

          <div className="popup-content-row">
            <span className="popup-content-label">Rooms</span>
            <span className="popup-content-value">{popupInfo["Rooms"]}</span>
          </div>

        </div>
      </Popup>
    )
  }

  const handleSelectDesignYear = (event: any) => {
    if (event) {
      setCalcYearFacilityDesign(event);
      updateCalculator();
    }
  }

  const handleSelectRecurrenceInterval = (event: any) => {
    if (event) {
      global.setfilter1Selected(event);
      updateCalculator();
    }
  }

  const handleSelectInterestYear = (event: any) => {
    if (event) {
      setCalcYearInterest(event);
      updateCalculator();
    }
  }

  const handleSliderChange = (event: any) => {
    if (event || event === 0) {
      const ev = +event;
      global.setfilter1Selected(reportTypes[ev])
    }
  }

  return (
    <>
      <FullScreenComp handle={fullscreenhandle}>
        <Container fluid>
          <div className="county-climate" id='county-climate-report'>

            <ReportHeader global={global} 
              data={dataNASDAQArray_unfiltered} 
              filterMode={(qent) ? 'upload risk' : 'upload'}
              headerTitle={qent ? "TrueQI Site Assessment Reporter" : "Climate Precipitation Calculator"} 
              subHeader={(currentDatasetLabel) ? `(${currentDatasetLabel})` : ``}
              reportID={"county-climate-report"} fullScreenClickHandle={fullscreenhandle.enter} />
            <Row className="container-row-top">
              <Col m={1} className="column-container">
                <div className="map-report-container">
                  <LoadingDataAnimation dataLoading={!isDataReportLoaded} />
                  <Map
                    id="CountyClimate"
                    mapboxAccessToken={settings.maboxKey}
                    mapStyle={global.mapStyle}
                    ref={mapRef as Ref<MapRef>}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    onClick={(e) => {
                      global.onMapClick(e)
                      onHandleClick(e)
                    }}
                    cursor={cursor}
                    preserveDrawingBuffer={true}
                    interactiveLayerIds={interactiveLayers}
                    projection={global.globeView ? 'globe' : 'mercator' as any}
                  >

                    {featureData && (
                      <Source id='pointsource' type='geojson' data={featureData}>
                        <Layer {...layerStylePoint} />
                        <Layer {...hoverLayerStyle} filter={mapHoverFilter} />
                      </Source>
                    )}


                    {reportData && (
                      <Source id="circlesource" type="vector" url="mapbox://mapbox.660ui7x6">
                        <Layer {...layerStyle} />
                      </Source>
                    )}

                    {popupInfo && renderPopup()}
                    
                    <MapAddition global={global} 
                                mapRef={mapRef}
                                position={'low'}
                                zipOff={true}
                                MapSliderAdd={true}
                                statusMessage={statusMessage}/>

                    <div className="climate-slider-container" style={(fillMap) ? { display: 'inline-flex' } : { display: 'none' }}>
                      <span className="climate-slider-container-span">Recurrence</span>
                      <div className="climate-slider">
                        <Slider
                          min={0} max={6} marks={marks} step={1}
                          onAfterChange={handleSliderChange}
                          handleStyle={{
                            height: 22,
                            width: 22,
                            borderColor: '#4fc2d9',
                            marginTop: -10,
                          }}
                          dotStyle={{ borderColor: '#4fc2d9', height: 14, width: 14, transform: 'translate(-50%, 20%)' }}
                          railStyle={{ backgroundColor: '#838487' }}
                          trackStyle={{ backgroundColor: '#4fc2d9', height: 5 }} />
                      </div>
                    </div>
                    <div className="map-title" >
                      <p></p>
                    </div>
                    <div className='map-legend-container'>
                      {true && (<MapLegend
                        mapLegendData={mapLegendData}
                        title={'Intensity'}
                        subtitle={'(inches)'}
                        layerSwitch={true}
                        layerCallback={setFillMap}
                        layerValue={fillMap}
                        layerCheckbox={'Show Intensity Layer'}
                        pointSwitch={true}
                        pointCallback={setPointMap}
                        pointValue={pointMap}
                        pointTitle={'Facilities At Risk'}
                        pointCheckbox={'Show Facilities'}
                        pointSubtitle={'(most at risk)'}
                        pointLegendData={mapLegendPointData}
                        defaultActiveKey={'1'}
                        legendWidth={199}
                        global={global}
                      />
                      )}
                    </div>
                  </Map>
       
                </div>
              </Col>

              <Col m={1} className="column-chart column-container">
                <div className={isFullScreenChartActive ? 'expand-chart-container chart-container' : 'chart-container'}>
                  <div className="report-chart-options-camel">
                    <Button onClick={fullscreenChartContainerHandler}>
                      <img src={fullscreenIcon} />
                    </Button>
                  </div>
                  {data && (
                    <div className="chart-parent-container">
                      <div className="line-chart">
                        <Line options={config}
                          data={data}
                          ref={chartRef}
                          onClick={onChartClickHandler}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
            <Row className="container-row-bottom">
              <Col m={1} className="column-container">
                <div className={isFullScreenChartStormActive ? 'expand-chart-container chart-container' : 'chart-container'}>
                  <div className="report-chart-options-storm">
                    <Button onClick={fullscreenChartStormContainerHandler}>
                      <img src={fullscreenIcon} />
                    </Button>
                  </div>
                  {dataStorm && (
                    <div className="chart-parent-container">
                      <div className="line-chart container-q3">
                        <Line 
                          className="line-q3"
                          options={configStorm}
                          data={dataStorm}
                          ref={chartRefStorm}
                          onClick={onChartClickHandler}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </Col>
              <Col m={1} className="column-container">
                <div className={isFullScreenActive ? 'expand-summary-container summary-container' : 'summary-container'}>
                  <div className="report-chart-options-summary">
                    <Button onClick={fullscreenContainerHandler}>
                      <img src={fullscreenIcon} />
                    </Button>
                  </div>

                  <div className="widgetTitle">Risk Analysis Calculator</div>
                  <div className="resultBox">
                    <div className="selectBox">
                      <label htmlFor="county" className="left">Current zip code: </label>
                      <span className="resultBox-span"> {' ' + selectedZip}</span>
                    </div>
                  </div>

                  <div className="resultBox resultBoxRow">
                    <Dropdown onSelect={handleSelectDesignYear}>
                      <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                        Facility Design Year
                      </Dropdown.Toggle>
                      <Dropdown.Menu variant="dark">
                        {yearsDesign.map((item: any) => {
                          return (
                            // @ts-ignore
                            <Dropdown.Item eventKey={item} key={'cc-DesignYear' + item} value={item}>{item}</Dropdown.Item>
                          )
                        })}
                      </Dropdown.Menu>
                    </Dropdown><span className="output">{calcYearFacilityDesign}</span>
                  </div>

                  <div className="resultBox resultBoxRow">
                    <Dropdown onSelect={handleSelectRecurrenceInterval}>
                      <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                        What recurrence interval controlled the design?
                      </Dropdown.Toggle>
                      <Dropdown.Menu variant="dark">
                        {reportTypes.map((item: any) => {
                          return (
                            // @ts-ignore
                            <Dropdown.Item eventKey={item} key={'cc-RecurrenceInterval' + item} value={item}>{item}</Dropdown.Item>
                          )
                        })}
                      </Dropdown.Menu>
                    </Dropdown><span className="output">{selectedCategory}</span>
                  </div>

                  <div className="resultBox resultBoxRow">
                    <Dropdown onSelect={handleSelectInterestYear}>
                      <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                        Year of Interest
                      </Dropdown.Toggle>
                      <Dropdown.Menu variant="dark">
                        {yearsInterest.map((item: any) => {
                          return (
                            // @ts-ignore
                            <Dropdown.Item eventKey={item} key={'cc-InterestYear' + item} value={item}>{item}</Dropdown.Item>
                          )
                        })}
                      </Dropdown.Menu>
                    </Dropdown><span className="output">{calcYearInterest}</span>
                  </div>

                  <div className="resultBox">
                    <div className="resultBoxDescriptor">
                      24-hour storm Design Standard for your recurrence interval
                    </div>
                    <div className="resultsDetail">
                      <div>  <span className="outputLabel">Year:</span><span className="output">{calcYearFirst}</span> </div>
                      <div>  <span className="outputLabel">Intensity:</span><span className="output">{d3Format.format(".3r")(+calcDesign) + ' inches'}</span> </div>
                    </div>
                  </div>

                  <div className="resultBox">
                    <div className="resultBoxDescriptor">
                      24-hour storm in your Design Year and recurrence interval
                    </div>
                    <div className="resultsDetail">
                      <div>  <span className="outputLabel">Intensity:</span><span className="output">{d3Format.format(".3r")(+calcDesignIntensity) + ' inches'}</span> </div>
                    </div>
                  </div>

                  <div className="resultBox">
                    <div className="resultBoxDescriptor">
                      24-hour storm at your recurrence interval according to latest NOAA Data
                    </div>
                    <div className="resultsDetail">
                      <div>  <span className="outputLabel">Year:</span><span className="output">{calcYearLatest}</span> </div>
                      <div>  <span className="outputLabel">Intensity:</span><span className="output">{d3Format.format(".3r")(+calcLatestIntensity) + ' inches'}</span> </div>
                    </div>

                    <div className="resultsDetail">
                      <div>  <span className="outputLabel">Your storm in NOAA Year Recurrence Interval</span>
                        <span className="output">{calcLatestRecur}</span> </div>
                    </div>

                  </div>

                  <div className="resultBox">
                    <div className="resultBoxDescriptor">
                      24-hour storm at your recurrence interval Today ({calcYearToday})
                    </div>
                    <div className="resultsDetail">
                      <div>  <span className="outputLabel">Intensity:</span><span className="output">{d3Format.format(".3r")(+calcTodayIntensity) + ' inches'}</span> </div>
                    </div>

                    <div className="resultsDetail">
                      <div>  <span className="outputLabel">Your storm in {calcYearToday} Recurrence Interval</span>
                        <span className="output">{calcTodayRecur}</span> </div>
                    </div>
                  </div>

                  <div className="resultBox">
                    <div className="resultBoxDescriptor">
                      24-hour storm at your recurrence interval in Year of Interest
                    </div>
                    <div className="resultsDetail">
                      <div>  <span className="outputLabel">Year:</span><span className="output">{calcYearInterest}</span> </div>
                      <div>  <span className="outputLabel">Intensity:</span><span className="output">{d3Format.format(".3r")(+calcInterestIntensity) + ' inches'}</span> </div>
                    </div>

                    <div className="resultsDetail">
                      <div>  <span className="outputLabel">Recurrence Interval</span>
                        <span className="output">{calcInterestRecur}</span> </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              {/* https://www.epa.gov/national-aquatic-resource-surveys/streamcat-dataset */}
              <div className="source-text">
                <h3>Source: <a target="_blank" href="https://www.weather.gov/owp/hdsc">NOAA Atlas 14 Point Precipitation Frequency Estimates</a>, <a target="_blank" href="https://www.ncei.noaa.gov/products/land-based-station/global-historical-climatology-network-daily">NOAA GHCND Daily Summaries</a>{' '}& <a target="_blank" href="https://www.epa.gov/national-aquatic-resource-surveys/streamcat-dataset">Streamcat Dataset</a></h3>
              </div>
            </Row>
          </div>
        </Container>

      </FullScreenComp>
    </>
  );
}

type CountyClimateModel = {
  state: string;
};