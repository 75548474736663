import React, { useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'
import { AppContext } from '../../../AppContext';
import { RefContext } from '../../../RefContext';
import { faGreaterThan, faCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  getReportFromStorage,
  getCountryFromStorage,
  putCountryIntoStorage
} from '../../utils';

import { Form } from 'react-bootstrap'
import axios from 'axios'
import agent from '../../../api/agent';
import settings from '../../../settings.json'
import ISOs from '../../../ISO.json'
import './breadcrumb-styles.scss'


const Breadcrumb = (props: any) => {
  const { param = null, global } = props
  const { latitude, longitude } = global.viewport
  const [country, setCountry] = useState<any>()

  const { pathname } = useLocation()
  const { currentRef } = useContext<any>(RefContext);
  const {
    selectedHUC8,
    currentLocation,
    setCurrentLocation,
    updateContext
  } = useContext(AppContext);

  const [report, setReport] = useState<any>()
  const [locationInterval, setLocationInterval] = useState<any>()
  const [query, setQuery] = useState()

  useEffect(() => {
    const reportFromStorage = getReportFromStorage();
    setReport(reportFromStorage)
    setCountry(getCountryFromStorage())   
  }, [getCountryFromStorage()])

  const moving = currentRef.current?.isMoving()

  useEffect(() => {
    if (global.trackerOn) {
      if (moving) {
        setLocationInterval(
          window.setInterval(async () => {
            if(latitude && longitude){
              await agent.Utilities.LatLngToCity(latitude, longitude)
                .then(({ features, query }: any) => {
                  const featureCountry = features[0].place_name.split(',')[2].trim()
                  global.setCurrentCountry(featureCountry)
                  features.length > 0 && setCurrentLocation?.(features[0].place_name)
                  putCountryIntoStorage(featureCountry)
                  let huc = featureCountry === 'United States' || featureCountry === 'Mexico' ? selectedHUC8 : ''
                  query && setQuery(query)
                  updateContext?.(
                    huc = !huc ? '' : huc,
                    features[0].place_name,
                    global.currentReport,
                    country
                  )
                })
            }
          }, 500)
        )
      } else {
        clearInterval(locationInterval)
      }
    }

  }, [
    global.trackerOn,
    moving
  ])

  useEffect(() => {
    if (query && query[1] && query[0]) {
      if (country === 'United States') {
        agent.Utilities.LatLngToHuc8(query[1], query[0])
          .then(({ body }: any) => {
            console.log(body)
            const huc = body[0].Huc8
            updateContext?.(
              huc,
              currentLocation,
              global.currentReport,
              'United States'
            );
          })
      }
      if (country === 'Mexico') {
        agent.Utilities.LatLngToHUCMexico(query[1], query[0])
          .then(({ body }: any) => {
            const basin = body[0]['Basin Id']
            updateContext?.(
              basin,
              currentLocation,
              global.currentReport,
              'Mexico'
            );
          })
      }
    }
  }, [query])

  const flyToLocation = async (location: string) => {
    const huc12Find = location.substr(location.indexOf('('), location.indexOf(')'))
    if(huc12Find && huc12Find.length === 14){ 
        const location_huc12 = localStorage.getItem('location_huc12');
        if(location_huc12){
          let location = JSON.parse(location_huc12)
          global.setResultsDataset([location]); 
            global.setViewport({
              zoom: 10,
              latitude: +location.centroid_latitude,
              longitude: +location.centroid_longitude
            }) 
          return;          
        }        
    } 

    global.setLoading(true)

    const getISOCode = (input: string) => {
      const countries = Object.values(ISOs)
      const isos = Object.keys(ISOs)
      const countryIndex = countries.findIndex(country => country === input)

      return isos[countryIndex]
    }

    try {
      const { data } = await axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${location}.json?&country=${getISOCode(global.currentCountry)}&limit=3&access_token=${settings.maboxKey}`)

      const [lng, lat] = data.features[0].center

      global.setViewport({
        zoom: 12,
        latitude: lat,
        longitude: lng
      })

    } catch (e) {
      console.log(e)
    }
    global.setTrackerOn(false)
    global.setLoading(false)
  }

  // global.setCurrentCountry(e.target.value)

  return (
    <div className="breadcrumb-container">
      <span className='breadcrumb-subtitle'>
        <span className='breadcrumb-subtitle--parent-section'>
          {report && report.parentReportName}
          &nbsp;&nbsp;
          <FontAwesomeIcon icon={faGreaterThan} size='1x' /> &nbsp;&nbsp;
        </span>
        {report && report.name}
        &nbsp;&nbsp;
        <FontAwesomeIcon icon={faGreaterThan} size='1x' /> &nbsp;&nbsp;
        <span onClick={() => flyToLocation(currentLocation)} className="location">{currentLocation}</span>
      </span>
      {pathname === '/' && (
        <div className={`breadcrumb-switch-container ${(props.absolute) ? "breadcrumb-switch-container-absolute" : ""}`} >
          <span><Form.Check type="switch" checked={global.trackerOn} onChange={() => global.setTrackerOn(!global.trackerOn)} /></span>
          <span className="breadcrumb-switch-title">Search when I move map</span>
          {/* @ts-ignore */}
          {global.trackerOn ? <FontAwesomeIcon className="on-light" icon={faCircle} size="2xs" style={{ color: 'green' }} /> : <div className="spacer"></div>}
        </div>
      )}
    </div>
  );
};

export default React.memo(Breadcrumb);