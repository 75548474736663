// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container .map-legend {
  width: 159px;
  position: absolute;
  border-radius: 5px;
  padding: 5px;
  right: 42px;
  bottom: 10px;
  background-color: #18244c;
  z-index: 90; }

.container .map-legend p {
  margin-left: 5px;
  height: 30px; }

.container .map-legend span {
  width: 100px;
  margin-left: 5px; }

.container .map-legend-dot {
  background-color: #4b5ea6;
  height: 14px;
  width: 14px;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 50%;
  display: inline-flex; }

.container .report-options {
  margin-top: 20px;
  text-align: right; }
  .container .report-options button {
    background-color: #fff;
    border: unset;
    margin-left: 10px; }

.container .container--expand-container .table-container.table-container--scrollable {
  max-height: 80vh; }

.container #mapLegendAccordion {
  display: block;
  right: 10%; }
`, "",{"version":3,"sources":["webpack://./src/features/reports/FacilityRegistrySystem/styles.scss"],"names":[],"mappings":"AAAA;EAEI,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,YAAY;EACZ,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,WAAW,EAAA;;AATf;EAYI,gBAAgB;EAChB,YAAY,EAAA;;AAbhB;EAiBI,YAAY;EACZ,gBAAgB,EAAA;;AAlBpB;EAsBI,yBAAyB;EACzB,YAAY;EACZ,WAAW;EACX,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,oBAAoB,EAAA;;AA5BxB;EAgCI,gBAAgB;EAChB,iBAAiB,EAAA;EAjCrB;IAoCM,sBAAsB;IACtB,aAAa;IACb,iBAAiB,EAAA;;AAtCvB;EA2CI,gBAAgB,EAAA;;AA3CpB;EA+CI,cAAc;EACd,UAAU,EAAA","sourcesContent":[".container {\n  .map-legend {\n    width: 159px;\n    position: absolute;\n    border-radius: 5px;\n    padding: 5px;\n    right: 42px;\n    bottom: 10px;\n    background-color: #18244c;\n    z-index: 90;\n  }\n  .map-legend p {\n    margin-left: 5px;\n    height: 30px;\n  }\n\n  .map-legend span {\n    width: 100px;\n    margin-left: 5px;\n  }\n\n  .map-legend-dot {\n    background-color: #4b5ea6;\n    height: 14px;\n    width: 14px;\n    margin-left: 5px;\n    margin-right: 5px;\n    border-radius: 50%;\n    display: inline-flex;\n  }\n\n  .report-options {\n    margin-top: 20px;\n    text-align: right;\n\n    button {\n      background-color: #fff;\n      border: unset;\n      margin-left: 10px;\n    }\n  }\n\n  .container--expand-container .table-container.table-container--scrollable {\n    max-height: 80vh;\n  }\n\n  #mapLegendAccordion {\n    display: block;\n    right: 10%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
